



























import { Vue, Component, Prop } from "vue-property-decorator";
import AutocompleteInput from "./AutocompleteInput.vue";
import NumericInput from "./NumericInput.vue";
import TextInput from "./TextInput.vue";
import AutocompleteChipsInput from "./AutocompleteChipsInput.vue";
import DateInput from "./DateInput.vue";
import SelectInput from "./SelectInput.vue";
import { LookupObject } from "@/models/document-entry/lookup-object";
import { lineSchemaObject } from "@/models/document-entry/line";

@Component({
  components: {
    "text-input": TextInput,
    "numeric-input": NumericInput,
    "autocomplete-input": AutocompleteInput,
    "autocomplete-chips-input": AutocompleteChipsInput,
    "date-input": DateInput,
    "select-input": SelectInput
  }
})
export default class FormGenerator extends Vue {
  @Prop() isReadOnly!: boolean;
  @Prop() id!: number; // line sequence number
  @Prop() schema!: any;
  @Prop() value!: any;
  @Prop() focusElementRefName!: string;

  private formData: any = this.value || {}; // to avoid mutating props

  handleTabToNextField(index: number) {
    let nextIndex = index;
    // skip over fields that can't be edited
    if (!this.schema[index]?.isEditable && nextIndex >= 0) {
      nextIndex += 1;
    }
    // change input focus
    if (nextIndex >= this.$children.length) {
      this.$emit("moveFocusToNextLine");
    } else if (nextIndex < 0) {
      this.$emit("moveFocusToPrevLine");
    } else {
      this.$emit(
        "moveFocusOnSameLine",
        this.id + "-" + this.$children[nextIndex].$props.name
      );
    }
  }

  handleClickFocus(refName: string) {
    this.$emit("handleInvoiceLineClickFocus", refName);
  }

  updateForm(fieldName: string, value: any) {
    this.$set(this.formData, fieldName, value);
  }

  updateFormOptions(fieldName: string, value: any) {
    if (!this.isReadOnly) {
      // emit to parent component to perform lookup and load options
      this.$emit("lookup", {
        field: fieldName,
        seqNumber: this.id,
        value: value
      });
    }
  }

  loadMoreLookupOptions(fieldName: string, value: { [key: string]: any }) {
    if (!this.isReadOnly) {
      this.$emit("continueLoadingLookupOptions", {
        field: fieldName,
        seqNumber: this.id,
        value: value.searchValue,
        page: value.currentPage + 1
      });
    }
  }

  validate(fieldName: string, value?: any) {
    if (!this.isReadOnly) {
      // only autocompletes send value in validation event
      if (value || value === "") {
        this.$set(this.formData, fieldName, value);
        this.schema.find(
          (obj: lineSchemaObject) => obj.name === fieldName
        ).value = value;
      }
      // emit to parent component to perform validation and update schema
      this.$emit("validate", {
        field: fieldName,
        seqNum: this.id,
        value: this.formData[fieldName]
      });
    }
  }

  addChip(fieldName: string, value: any) {
    if (!this.isReadOnly) {
      // only one worktag of each organization type is allowed
      // if a worktag is selected with the same organization type as another selected worktag, the API upserts the new worktag
      // so that a line does not contain worktags with duplicate organization types
      const organizationType = value.id.substring(0, value.id.indexOf("|"));
      this.$set(this.formData, fieldName, [
        ...this.formData[fieldName].filter(
          (x: LookupObject) => x.id.indexOf(organizationType) < 0
        ),
        value
      ]);
      this.$emit("addautocompletechip", {
        field: fieldName,
        seqNum: this.id,
        value: value.id
      });
    }
  }

  removeChip(fieldName: string, value: any) {
    if (!this.isReadOnly) {
      this.$emit("removeautocompletechip", {
        field: fieldName,
        seqNum: this.id,
        value: value.id
      });
    }
  }
}
