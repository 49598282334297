

































































































































import { Component, Watch } from "vue-property-decorator";
import DocumentRuleMixin from "@/mixins/DocumentRuleMixin.vue";
import { RADIO_OPTIONS } from "@/shared/constants/document-rule";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import {
  DocumentRuleAutocompleteInput,
  DocumentRuleTextInput
} from "@/models/document-entry/document-rule-inputs";
import {
  DocumentRuleRequest,
  DocumentRuleModalMode
} from "@/models/document-entry/document-rules";
import { documentRulesService } from "@/services/document-rules.service";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";

@Component({
  components: {
    "primary-button": PrimaryButton
  }
})
export default class DocumentRuleDetailsStep extends DocumentRuleMixin {
  // reactive properties
  private documentRuleDetailsKey = randomNumberGenerator();
  private readonly radioOptions = RADIO_OPTIONS;

  private valid = true;
  private customerAccountNumberRules = [
    (value: string) => !!value || "Customer Account Number is required"
  ];
  private companyRules = [(value: string) => !!value || "Company is required"];

  private readonly createModalMode = DocumentRuleModalMode.CREATE;

  // computed property getters and setters
  get company(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields?.company;
  }
  set company(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "company",
      value: this.company
    });
  }
  get customeracct(): DocumentRuleTextInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields?.customeracct;
  }
  set customeracct(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "customeracct",
      value: this.customeracct
    });
  }
  get companytype(): string {
    return this.documentRulesStore.getDocumentRuleHeaderFields?.companytype;
  }
  set companytype(value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "companytype",
      value: value
    });
  }
  get customeraccttype(): string {
    return this.documentRulesStore.getDocumentRuleHeaderFields
      ?.customeraccttype;
  }
  set customeraccttype(value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "customeraccttype",
      value: value
    });
  }

  get shouldUseHeaderAndLinesFromDocumentEntry(): boolean {
    return this.documentRulesStore.getShouldUseHeaderAndLinesFromDocumentEntry;
  }

  set shouldUseHeaderAndLinesFromDocumentEntry(value: boolean) {
    this.documentRulesStore.setShouldUseHeaderAndLinesFromDocumentEntry(value);
  }

  // additional computed properties
  private get isNewDocumentRule(): boolean {
    return this.documentRulesStore.getIsNewDocumentRule;
  }

  private get isDocumentRuleComplete(): { [key: string]: boolean } {
    return this.documentRulesStore.getIsDocumentRuleComplete;
  }

  private get supplierDisplayName(): string {
    const supplier: DocumentRuleAutocompleteInput = this.documentRulesStore
      .getDocumentRuleHeaderFields?.supplier;
    return (
      supplier?.options?.find(option => option.id === supplier?.value)
        ?.displayValue ?? ""
    );
  }

  // methods

  private clearAutocompleteField(
    event: any,
    field: string,
    fieldValue: any,
    _validate: boolean
  ) {
    if (event.key == "Delete" || event.key == "Backspace") {
      if (fieldValue?.trim().length === 0) {
        const refName = "document-rule-" + field;
        const fieldRef: any = this.$refs[refName];
        fieldRef.reset();
      }
    }
  }

  async createNewDocumentRule() {
    const fromInvID = this.documentRulesStore
      .getShouldUseHeaderAndLinesFromDocumentEntry
      ? this.$route.params.id ?? ""
      : undefined;
    const documentRuleCreateRequest: DocumentRuleRequest = {
      FromInvID: fromInvID,
      SupplierID:
        (this.documentRulesStore.getDocumentRuleHeaderFields?.supplier
          .value as string) ?? "",
      CompanyID:
        (this.documentRulesStore.getDocumentRuleHeaderFields?.company
          .value as string) ?? "!all",
      CustomerAccountNumber:
        (this.documentRulesStore.getDocumentRuleHeaderFields?.customeracct
          .value as string) ?? "!all"
    };
    const createRuleResponse = await documentRulesService.createDocumentRuleAsync(
      documentRuleCreateRequest
    );

    const isNewDocumentRule =
      createRuleResponse.message !==
      "A document rule for this combination exists."
        ? true
        : false;

    this.documentRulesStore.setIsNewDocumentRule(isNewDocumentRule);

    // request document rule header fields and lines - even when a new rule is created
    // without using the current document entry page details, we need to populate select
    // inputs such as prepaid type
    const documentDetails: any = await documentRulesService.getDocumentRule(
      createRuleResponse.invID
    );
    this.documentRulesStore.setHeaderFieldsForEditing({
      fields: documentDetails.fields ?? [],
      shouldSetInitialSettings: false
    });
    // if the rules exists or if the rules is just created with current invoice
    // header fields and lines
    if (
      this.documentRulesStore.getShouldUseHeaderAndLinesFromDocumentEntry ||
      !isNewDocumentRule
    ) {
      // set documentRuleID to the prototype-temp instead of prototype record
      this.documentRulesStore.setDocumentRuleID(documentDetails.invID);
      this.documentRulesStore.setPrototypeDocumentRuleID(
        createRuleResponse.invID
      );
    } else {
      this.documentRulesStore.setDocumentRuleID(createRuleResponse.invID);
    }

    if (isNewDocumentRule) {
      await this.proceedToNextStep();
    }
  }

  async proceedToNextStep() {
    if (!this.isNewDocumentRule) {
      this.documentRulesStore.setDocumentRuleModalMode(
        this.isNewDocumentRule
          ? DocumentRuleModalMode.CREATE
          : DocumentRuleModalMode.UPDATE
      );
    }
    const isComplete = !this.isNewDocumentRule
      ? true
      : (this.documentRulesStore.getDocumentRuleID?.toString().length ?? 0) > 0;

    this.documentRulesStore.setDocumentRuleStepAsCompleted({
      stepNumber: "one",
      isComplete
    });
    // open next panel in document rules modal
    this.documentRulesStore.openPanelInDocumentRuleModal(1);
  }

  @Watch("company.search", { deep: true })
  async onCompanySearchChange() {
    if (this.company?.search && this.mustPerformLookup(this.company)) {
      this.resetLookupPageOptions("company");
      await this.lookupOptions("company", false);
    } else {
      this.company.options = [];
    }
  }

  @Watch("customeraccttype")
  onCustomerAccountTypeChange() {
    // remove duplicate flag if user changes customeraccttype before proceeding to step 2
    this.documentRulesStore.setIsNewDocumentRule(true);
    // change customeracct value
    if (this.customeraccttype === RADIO_OPTIONS.ONE) {
      this.documentRulesStore.revertInitialSettingToDocumentEntryValue(
        "customeracct"
      );
    } else {
      this.documentRulesStore.setHeaderFieldsComplexState({
        property: "customeracct",
        value: {
          ...this.customeracct,
          value: RADIO_OPTIONS.ALL
        }
      });
    }
  }
  @Watch("companytype")
  onCompanyTypeChange() {
    // remove duplicate flag if user changes company type before proceeding to step 2
    this.documentRulesStore.setIsNewDocumentRule(true);
    // change company value
    if (this.companytype === RADIO_OPTIONS.ONE) {
      this.documentRulesStore.revertInitialSettingToDocumentEntryValue(
        "company"
      );
    } else {
      this.documentRulesStore.setHeaderFieldsComplexState({
        property: "company",
        value: {
          ...this.documentRulesStore.getDocumentRuleHeaderFields.company,
          value: RADIO_OPTIONS.ALL
        }
      });
    }
  }
}
