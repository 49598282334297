import { app } from "@/main";
import { AxiosResponse } from "axios";
class UpdateTaxService {
  public async get(
    InvID: string,
    TaxOption: string | number
  ): Promise<AxiosResponse<any>> {
    const apiResponse = await app.$ravenapi.get(
      `/api/tax/gettax?InvID=${InvID}&TaxOption=${TaxOption}`
    );
    return apiResponse;
  }
  public async update(
    InvID: string,
    TaxOption: string,
    InvoiceDate: string
  ): Promise<AxiosResponse<any>> {
    const apiResponse = await app.$ravenapi.post(
      `/api/tax/updatetax?InvID=${InvID}&TaxOption=${TaxOption}&InvoiceDate=${InvoiceDate}`,
      {}
    );
    return apiResponse;
  }
  public async save(updatedTaxes: {
    invID: number;
    taxRates: {
      rateID: number;
      amount: number;
    }[];
    taxOption: number;
  }) {
    const apiResponse = await app.$ravenapi.post(
      "/api/tax/savetax",
      updatedTaxes
    );
    return apiResponse;
  }
  public async savewithholding(updatedTaxes: {
    invID: number;
    withholdingTaxRates: {
      rateID: number;
      amount: number;
    }[];
    taxOption: number;
  }) {
    const apiResponse = await app.$ravenapi.post(
      "/api/tax/savetax",
      updatedTaxes
    );
    return apiResponse;
  }
}

export const updateTaxService = new UpdateTaxService();
