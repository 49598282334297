<template>
  <v-container fill-height class="mt-4 mx-0 px-0 skeleton-container">
    <v-row class="d-flex flex-row justify-space-between" fill-height>
      <v-col class="d-flex flex-column justify-space-between flex-grow-1">
        <v-skeleton-loader class="d-flex mb-14" type="heading" />
        <v-skeleton-loader class="d-flex mb-8 three" type="heading@3" />
        <v-row>
          <v-col>
            <v-skeleton-loader height="80px" type="image" />
          </v-col>
          <v-col>
            <v-skeleton-loader class="d-flex mb-8 single" type="heading" />
            <v-skeleton-loader class="d-flex mb-8 single" type="heading" />
          </v-col>
        </v-row>
        <v-skeleton-loader class="d-flex mb-8 three" type="heading@3" />
        <v-skeleton-loader class="d-flex mb-8 three" type="heading@3" />
        <v-skeleton-loader
          class="d-flex mb-8 single"
          min-width="100%"
          type="heading"
        />
        <v-skeleton-loader class="d-flex mb-8 three" type="heading@3" />
        <v-skeleton-loader class="d-flex mb-8 three" type="heading@3" />
        <v-row>
          <v-col>
            <v-skeleton-loader class="mr-6 mt-8" type="text@3" />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex flex-column justify-space-between">
        <v-skeleton-loader
          tile
          class="d-flex mb-8 mr-0 ml-auto actions"
          type="avatar@4"
        />
        <v-skeleton-loader height="100%" class="img pb-8" type="image" />
      </v-col>
    </v-row>
    <v-row min-height="20%" max-height="20%" class="flex-shrink-1">
      <v-col class="d-flex flex-column justify-end">
        <v-skeleton-loader class="mx-0 footer" type="image" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";

@Component
export default class DocEntrySkeleton extends Vue {}
</script>

<style scoped lang="scss">
.skeleton-container {
  min-width: 100%;
}
::v-deep .three div {
  margin-right: 1em;
}
::v-deep .single div {
  width: 100% !important;
  margin-right: 1em;
}
::v-deep .actions div {
  margin-left: 1em;
}
::v-deep .img div {
  height: 100%;
}

::v-deep .footer div {
  height: 80px;
}
</style>
