














































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import { focusField } from "@/helpers/input-helpers";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class AutocompleteInput extends Vue {
  @Prop() lineSequenceNumber!: number;
  @Prop() focusElementRefName!: string;
  @Prop() isReadOnly!: boolean;
  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() name!: string;
  @Prop() value!: string;
  @Prop() isRequired!: boolean;
  @Prop() isValid!: boolean;
  @Prop() isError!: boolean;
  @Prop() errorMessage?: string;
  @Prop() isVisible!: boolean;
  @Prop() isEditable!: boolean;
  @Prop() options!: any[];
  @Prop() height?: number;
  @Prop() lookupResultsPageOptions!: { [key: string]: number };

  private search: string | null = null;
  private selectedValue: string = this.value || "";
  private listOptions: any = this.options || [];

  private get normalizedSelectedValue(): string {
    if (this.selectedValue) {
      return this.selectedValue;
    }
    return "";
  }

  private autocompleteFilter(item: any, queryText: string, _itemText: string) {
    return (
      (item.description ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1 ||
      (item.id ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  handleTabKeypress(event: any) {
    if (event.shiftKey) {
      this.$emit("tabToPreviousField");
    } else {
      this.$emit("tabToNextField");
    }
  }

  shouldLazyLoadMoreLookupOption() {
    const currentPage = this.lookupResultsPageOptions?.page ?? 0;
    const totalPages = this.lookupResultsPageOptions?.totalPages ?? 0;
    if (totalPages > currentPage) {
      return true;
    }
  }

  // Intersect method will be invoked on DOM mount and when the last item
  // in the v-autocomplete input's options list is intersected
  // arguments are defined by v-intersect directive (Intersection Observer API)
  private endIntersect(
    _entries: IntersectionObserverEntry[],
    _observer: IntersectionObserverEntry,
    _isIntersecting: boolean
  ) {
    const loadMoreLookupOptions = this.shouldLazyLoadMoreLookupOption();
    if (loadMoreLookupOptions) {
      this.$emit("loadMoreLookupOptions", {
        searchValue: this.search,
        currentPage: this.lookupResultsPageOptions.page ?? 1
      });
    }
  }

  clearField(event: any) {
    if (event.key == "Delete" || event.key == "Backspace") {
      this.listOptions = [];
      if (this.search?.trim().length === 0) {
        const fieldRef: any = this.$refs[
          this.lineSequenceNumber + "-" + this.name
        ];
        fieldRef.reset();
        this.$emit("validateautocomplete", this.normalizedSelectedValue);
      }
    }
  }

  @Watch("search")
  searchValueChanged() {
    this.$emit("lookup", this.search);
    if (this.search == null) {
      this.listOptions = [];
    }
  }

  @Watch("value", { deep: true })
  onValuePropChange() {
    this.selectedValue = this.value;
  }

  // necessary to update cachedItems to include all options, otherwise
  // they won't appear in the list
  @Watch("options", { deep: true })
  onOptionsChange() {
    const refName = this.lineSequenceNumber + "-" + this.name;
    const fieldRef: any = this.$refs[refName];
    fieldRef.cachedItems = this.options;
  }

  @Watch("focusElementRefName")
  onFocusElementChange() {
    focusField(this);
  }

  mounted() {
    focusField(this);
  }
}
