export type POLineDetail = {
  id: number;
  priority?: number; // only inclued for lines on the invoice (line sequence number)
  supplierItemID: string;
  businessDocument: string;
  itemName: string;
  itemDescription: string;
  quantity: number;
  unitCost: number;
  extendedAmount: number;
  lineType: string; // this should be an Enum with 2 possible options: goods, service
  isPoLine: boolean; // false for non-po lines
  isExistingLine: boolean; // false for available lines, true for lines on the invoice already
  selected?: boolean; // only used by frontend to add/remove lines

  //non visible but required fields
  companyID: string;
  companyIDText: string;
  spendCategory: string;
  spendCategoryText: string;
  taxApplicability: string;
  taxApplicabilityText: string;
  taxCode: string;
  taxCodeText: string;
  taxRecoverability: string;
  taxRecoverabilityText: string;
  costCenter: string;
  memo: string;
  prepaid: boolean;
  billable: true;
  businessDocumentText: string;
  itemNameText: string;
};

export type POLinePairingDetailsResponse = {
  addedLines: POLineDetail[];
  availableLines: POLineDetail[];
  poNumber: string;
  supplierContractNumber: string;
};

export class UpdateInvoiceLinesRequest {
  public invID: string;
  public addedLines: POLineDetail[];
  public deletedLines: POLineDetail[];

  constructor(
    invID: string,
    addedLines: POLineDetail[],
    deletedLines: POLineDetail[]
  ) {
    this.invID = invID;
    this.addedLines = addedLines;
    this.deletedLines = deletedLines;
  }
}
