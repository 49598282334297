




























































































































































































































































































































































































































































































import { Component, Watch } from "vue-property-decorator";
import DocumentRuleMixin from "@/mixins/DocumentRuleMixin.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import InputWrapper from "@/components/forms/document-entry/invoice-fields/InputWrapper.vue";
import { fieldValidationService } from "@/services/invoice-field-validation.service";
import {
  DocumentRuleAutocompleteInput,
  DocumentRuleSelectInput,
  DocumentRuleTextInput
} from "@/models/document-entry/document-rule-inputs";
import { RavenValidationResponse } from "@/models/external-services/raven-api-validation-response";

@Component({
  components: {
    "input-wrapper": InputWrapper,
    "primary-button": PrimaryButton
  }
})
export default class DocumentRuleHeaderFieldsStep extends DocumentRuleMixin {
  // reactive properties
  private fieldOrder: HTMLInputElement[] = [];

  // computed properties getters/setters
  private get isNewDocumentRule(): boolean {
    return this.documentRulesStore.getIsNewDocumentRule;
  }
  private get isDocumentRuleComplete() {
    return this.documentRulesStore.getIsDocumentRuleComplete;
  }

  get remitto(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.remitto;
  }
  set remitto(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "remitto",
      value: this.remitto
    });
  }
  get currency(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.currency;
  }
  set currency(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "currency",
      value: this.currency
    });
  }
  get paymentterms(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.paymentterms;
  }
  set paymentterms(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "paymentterms",
      value: this.paymentterms
    });
  }
  get paymenttype(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.paymenttype;
  }
  set paymenttype(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "paymenttype",
      value: this.paymenttype
    });
  }
  get shipto(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.shipto;
  }
  set shipto(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "shipto",
      value: this.shipto
    });
  }
  get approver(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.approver;
  }
  set approver(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "approver",
      value: this.approver
    });
  }
  get requester(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.requester;
  }
  set requester(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "requester",
      value: this.requester
    });
  }
  get taxoption(): DocumentRuleSelectInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.taxoption;
  }
  set taxoption(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "taxoption",
      value: this.taxoption
    });
  }
  get prepaidtype(): DocumentRuleSelectInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.prepaidtype;
  }
  set prepaidtype(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "prepaidtype",
      value: this.prepaidtype
    });
  }
  get memo(): DocumentRuleTextInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.memo;
  }
  set memo(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "memo",
      value: this.memo
    });
  }
  get handlingcode(): DocumentRuleAutocompleteInput {
    return this.documentRulesStore.getDocumentRuleHeaderFields.handlingcode;
  }
  set handlingcode(_value) {
    this.documentRulesStore.setHeaderFieldsComplexState({
      property: "handlingcode",
      value: this.handlingcode
    });
  }

  // lifecycle methods
  mounted() {
    this.setFieldOrder();
  }

  // methods

  private moveFocusToNextField(event: KeyboardEvent) {
    const currentFieldIndex = this.fieldOrder.indexOf(
      event.target as HTMLInputElement
    );
    if (!event.shiftKey && currentFieldIndex === this.fieldOrder.length - 1) {
      event.preventDefault();
    } else {
      const nextField = event.shiftKey
        ? this.fieldOrder[currentFieldIndex - 1]
        : this.fieldOrder[currentFieldIndex + 1];
      // if next field is undefined, disabled, or next or current field is a date type, allow default function of tab key to occur
      if (nextField) {
        event.preventDefault();
        nextField.focus();
      }
    }
  }

  async onPrepaidTypeValueChange(newValue: string, oldValue: string) {
    if (newValue !== null) {
      await this.validateFieldValue("prepaidtype");
    } else {
      this.$nextTick(() => (this.prepaidtype.value = oldValue));
    }
  }

  // perform validation with API call
  async validateFieldValue(field: string) {
    let validationResponse: RavenValidationResponse;
    if (field === "taxoption" || field === "prepaidtype") {
      validationResponse = await fieldValidationService.validateFieldAsync({
        invoiceid: this.documentRulesStore.getDocumentRuleID as number,
        field: field,
        value: (this[field].value as any).value ?? "" // entire option obj stored as value
      });
    } else {
      validationResponse = await fieldValidationService.validateFieldAsync({
        invoiceid: this.documentRulesStore.getDocumentRuleID as number,
        field: field,
        value: this[field].value ?? ""
      });
    }
    this.processValidationResponse(validationResponse, field);
  }

  processValidationResponse(
    validationResponse: RavenValidationResponse,
    fieldValidated: string
  ) {
    if (validationResponse.data.isValid === true) {
      this[fieldValidated].isValid = true;
      this[fieldValidated].hasError = false;
      if (validationResponse.data.message !== "") {
        this[fieldValidated].errorMessage = "";
      }
    } else {
      this[fieldValidated].hasError = true;
      this[fieldValidated].isValid = false;
      if (validationResponse.data.message.length > 0) {
        this[
          fieldValidated
        ].errorMessage = `Error: ${validationResponse.data.message}`;
      }
    }
  }

  moveToNextStep() {
    this.documentRulesStore.setDocumentRuleStepAsCompleted({
      stepNumber: "two",
      isComplete: true
    });
    this.documentRulesStore.openPanelInDocumentRuleModal(2);
  }

  setFieldOrder() {
    this.$nextTick(() => {
      // extract array of inputs from Vue components in form
      this.fieldOrder = (this.$refs[
        "document-rule-header-fields-form"
      ] as Vue).$data.inputs
        .map((input: Vue) => input.$refs.input)
        // filter out fields that are not visible or not editable
        .filter(
          (field: HTMLInputElement) =>
            field.clientHeight > 0 && field.tabIndex >= 0
        );
    });
  }

  private clearAutocompleteField(
    event: any,
    field: string,
    fieldValue: any,
    validate: boolean
  ) {
    if (event.key == "Delete" || event.key == "Backspace") {
      if (fieldValue?.trim().length === 0) {
        const refName = "document-rule-" + field;
        const fieldRef: any = this.$refs[refName];
        fieldRef.reset();
        if (validate) {
          this.validateFieldValue(field);
        }
      }
    }
  }

  // watchers for lookups

  @Watch("remitto.search", { deep: true })
  async handleRemitToLookup() {
    await this.lookupOptions("remitto", true);
  }
  @Watch("currency.search", { deep: true })
  async handleCurrencyLookup() {
    await this.lookupOptions("currency", false);
  }
  @Watch("paymentterms.search", { deep: true })
  async handlePaymentTermsLookup() {
    await this.lookupOptions("paymentterms", false);
  }
  @Watch("paymenttype.search", { deep: true })
  async handlePaymentTypeLookup() {
    await this.lookupOptions("paymenttype", true);
  }
  @Watch("shipto.search", { deep: true })
  async handleShipToLookup() {
    await this.lookupOptions("shipto", true);
  }
  @Watch("approver.search", { deep: true })
  async handleApproverLookup() {
    await this.lookupOptions("approver", true);
  }
  @Watch("requester.search", { deep: true })
  async handleRequesterLookup() {
    await this.lookupOptions("requester", false);
  }
  @Watch("handlingcode.search", { deep: true })
  async handleHandlingCodeLookup() {
    await this.lookupOptions("handlingcode", false);
  }
}
