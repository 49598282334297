import { app } from "@/main";

class LineLookupService {
  private async getLineFieldOptionsAsync(
    fieldName: string,
    invID: number,
    lineSequenceNo: number,
    searchValue: string | any,
    page = 1
  ) {
    searchValue = encodeURIComponent(searchValue);

    const requestUrl =
      "api/lookup/line/" +
      fieldName +
      "?searchValue=" +
      searchValue +
      "&invID=" +
      invID +
      "&lineSequenceNumber=" +
      lineSequenceNo +
      "&page=" +
      page;

    const apiResponse = await app.$ravenapi.get(requestUrl);
    return apiResponse;
  }

  public async loadLineLookupOptionsAsync(
    fieldName: string,
    invID: number,
    lineSequenceNo: number,
    searchValue: string | any,
    page?: number
  ) {
    const results = await this.getLineFieldOptionsAsync(
      fieldName,
      invID,
      lineSequenceNo,
      searchValue,
      page
    );

    return results.data;
  }
}

export const lineLookupService = new LineLookupService(); // singleton
