





























































































































































// Custom Components
import FormGenerator from "@/components/forms/document-entry/invoice-lines/FormGenerator.vue";
import IconTextButton from "@/components/design-system/buttons/IconTextButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import POLineDetailsTray from "@/components/forms/document-entry/po-line-details/POLineDetailsTray.vue";

// Helpers
import { addDecimalsToString } from "@/helpers/string-formatting";
import {
  stringToDecimal,
  randomNumberGenerator,
  SafeMath
} from "@/helpers/numbers-helpers";

// Models
import { FieldValidationRequest } from "@/models/document-entry/field-validation";
import { lineSchemaObject, line } from "@/models/document-entry/line";
import { RavenCreateLineRequest } from "@/models/external-services/raven-api-create-line-request";
import { RavenDuplicateLineRequest } from "@/models/external-services/raven-api-duplicate-line-request";
import { UseSupplierWorktagsRequest } from "@/models/external-services/use-supplier-worktags-request";
import { InputObject } from "@/models/document-entry/input-object";

// Shared
import options from "@/shared/constants/toast-options";

// Services
import { documentDetailsService } from "@/services/document-details.service";
import { invoiceLineService } from "@/services/invoice-line.service";
import { lineLookupService } from "@/services/invoice-line-lookup.service";
import { lineValidationService } from "@/services/invoice-line-validation.service";
import { invoiceAutocodingService } from "@/services/invoice-autocoding.service";

// Third Party imports
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    "form-generator": FormGenerator,
    "secondary-button": SecondaryButton,
    "icon-text-button": IconTextButton,
    "po-line-details-tray": POLineDetailsTray
  }
})
export default class InvoiceLines extends Vue {
  @Prop() isReadOnly!: boolean;
  @Prop({ default: true }) isLineWorkbenchButtonDisabled!: boolean;
  @Prop() selectedDocumentInvoiceId!: string;
  @Prop() documentType!: InputObject;
  @Prop() isPOFromHeaderValid!: boolean;
  @Prop() isSupplierFromHeaderValid!: boolean;

  private forceInvLineContainerRerender = randomNumberGenerator();
  private invoiceLines: line[] = [];
  private selectedDocumentId = parseInt(this.$route.params.id);
  private currentLineItemInFocus = "";
  private isPOLineDetailsTrayVisible = false;
  private _timerId!: NodeJS.Timeout;
  private isLineMenuActionInProgress = false;

  private shouldDisplayPOLinePairingButton = this.$launchDarkly.variation(
    "show-po-line-details-tray",
    false
  );
  private shouldShowInvoiceLinesMenu = this.$launchDarkly.variation(
    "show-invoice-lines-menu",
    false
  );

  private fieldToHighlight = "company";

  private get selectedInvoiceLines() {
    if (this.invoiceLines.length === 0) {
      return [];
    } else {
      return this.invoiceLines.filter(
        (invoiceLine: any) => invoiceLine.selected
      );
    }
  }

  private get shouldShowUseSupplierWorktagsButton(): boolean {
    if (
      this.invoiceLines?.length > 0 &&
      !this.isPOFromHeaderValid &&
      this.isSupplierFromHeaderValid
    ) {
      return true;
    } else {
      return false;
    }
  }

  async created() {
    await this.handleUpdatingInvoiceLines();
  }

  truncateDecimals() {
    this.invoiceLines.forEach(invoiceLine => {
      invoiceLine.data.quantity =
        invoiceLine.data.quantity === ""
          ? ""
          : stringToDecimal(invoiceLine.data.quantity).toString();
      invoiceLine.data.unitCost =
        invoiceLine.data.unitCost === ""
          ? ""
          : addDecimalsToString(invoiceLine.data.unitCost, 6);
      invoiceLine.data.extendedAmount =
        invoiceLine.data.extendedAmount === ""
          ? ""
          : addDecimalsToString(invoiceLine.data.extendedAmount);
    });
  }

  async handleUpdatingInvoiceLines() {
    this.invoiceLines = await documentDetailsService.loadDocumentLinesAsync(
      parseInt(this.$route.params.id)
    );
    this.truncateDecimals();
  }

  handleFocusChangeToNextLine(index: number) {
    if (index >= this.invoiceLines.length) {
      (this.$refs.addline as any).$el.focus();
    } else if (index < 0) {
      this.currentLineItemInFocus = "";
    } else {
      this.currentLineItemInFocus =
        this.invoiceLines[index].seqNum +
        "-" +
        this.invoiceLines[index].schema[0].name;
    }
  }

  handleFocusChangeToPrevLine(index: number) {
    if (index >= this.invoiceLines.length) {
      this.currentLineItemInFocus = "";
    } else if (index < 0) {
      this.currentLineItemInFocus = "";
      this.$emit("moveFocusToInvoiceFields");
    } else {
      this.currentLineItemInFocus =
        this.invoiceLines[index].seqNum +
        "-" +
        this.invoiceLines[index].schema[
          this.invoiceLines[index].schema.length - 1
        ].name;
    }
  }

  handleFocusChangeOnSameLine(refName: string) {
    this.currentLineItemInFocus = refName;
  }

  handleClickFocus(refName: string) {
    this.currentLineItemInFocus = refName;
  }

  async performNewValLineFieldLookup(
    schema: lineSchemaObject[],
    lookupRequest: {
      field: string;
      seqNumber: number;
      value: string | any;
    }
  ) {
    //clear old options until new lookup returns
    const tmpSchema: lineSchemaObject | undefined = schema.find(
      (field: lineSchemaObject) => field.name === lookupRequest.field
    );
    if (tmpSchema) {
      tmpSchema.options = [];
    }
    // do lookup to get options
    clearTimeout(this._timerId);
    this._timerId = setTimeout(async () => {
      const lookupResponse = await lineLookupService.loadLineLookupOptionsAsync(
        lookupRequest.field,
        this.selectedDocumentId,
        lookupRequest.seqNumber,
        lookupRequest.value
      );

      if (lookupResponse) {
        const fieldSchema: lineSchemaObject | undefined = schema.find(
          (field: lineSchemaObject) => field.name === lookupRequest.field
        );

        if (fieldSchema) {
          fieldSchema.lookupResultsPageOptions = lookupResponse.metadata;
          if (lookupRequest.field === "additionalWorktags") {
            fieldSchema.options = [
              ...fieldSchema.options.filter((option: any) => {
                return (fieldSchema.value as any[]).some(selectedValue => {
                  return selectedValue.id === option.id;
                });
              }),
              ...lookupResponse.data
            ];
          } else {
            fieldSchema.options = [
              ...fieldSchema.options.filter((option: any) => {
                return option.id === fieldSchema.value;
              }),
              ...lookupResponse.data
            ];
          }
        }
      }
    }, 700);
  }

  async continueLoadingLookupOptions(
    schema: lineSchemaObject[],
    lookupRequest: {
      field: string;
      seqNumber: number;
      value: string | any;
      page: number;
    }
  ) {
    const fieldSchema: lineSchemaObject | undefined = schema.find(
      (field: lineSchemaObject) => field.name === lookupRequest.field
    );
    const currentPage = fieldSchema?.lookupResultsPageOptions?.page ?? 1;
    const totalPages = fieldSchema?.lookupResultsPageOptions?.totalPages ?? 1;

    if (totalPages > currentPage) {
      // do lookup to get options
      const lookupResponse = await lineLookupService.loadLineLookupOptionsAsync(
        lookupRequest.field,
        this.selectedDocumentId,
        lookupRequest.seqNumber,
        lookupRequest.value,
        lookupRequest.page
      );

      if (lookupResponse && fieldSchema) {
        fieldSchema.lookupResultsPageOptions = lookupResponse.metadata;
        fieldSchema.options = [...fieldSchema.options, ...lookupResponse.data];
      }
    }
  }

  liveFormatAmountInput(validationRequest: {
    field: string;
    seqNum: number;
    value: string | any;
  }) {
    if (
      validationRequest.field &&
      ["unitCost", "quantity", "extendedAmount"].includes(
        validationRequest.field
      )
    ) {
      const modifiedLine = this.invoiceLines.filter(
        invoiceLine => invoiceLine.seqNum == validationRequest.seqNum
      );
      const decimals = validationRequest.field == "unitCost" ? 6 : 2;
      const value = addDecimalsToString(validationRequest.value, decimals);
      validationRequest.value = value;
      modifiedLine[0].data[validationRequest.field] = value;
    }
  }

  updateAssociatedFieldsAfterValidation(
    input: FieldValidationRequest,
    fields: any[]
  ) {
    fields.forEach(async (field: any) => {
      const indexOfDataField = input.schema.findIndex(
        (matchingField: lineSchemaObject) =>
          matchingField.name.toLowerCase() === field.name.toLowerCase()
      );
      if (indexOfDataField >= 0) {
        if (field.name != "billable" && field.name != "prepaid") {
          // update schema - except for billable and prepaid, which have fixed lists of options
          input.schema[indexOfDataField].options?.push({
            id: field.value ?? null,
            description: field.text ?? field.value ?? "",
            displayValue: field.text ?? field.value ?? ""
          });
        }
        // Only update the value if its not undefined
        if (field.value != undefined) {
          if (field.name == "billable" || field.name == "prepaid") {
            input.schema[indexOfDataField].value =
              field.value?.toLowerCase() === "true";
          } else {
            input.schema[indexOfDataField].value = field.value;
          }
        }
        // update value/data
        const dataObjField = this.invoiceLines.find(
          (obj: line) => obj.seqNum === input.request.seqNum
        );
        // Only update the value if its not null
        if (dataObjField && field.value != undefined) {
          const decimals = field.name == "unitCost" ? 6 : 2;
          switch (field.name) {
            /** Intended Fallthrough */
            case "extendedAmount":
            case "unitCost":
              dataObjField.data[field.name] = addDecimalsToString(
                field.value,
                decimals
              );
              break;
            case "prepaid":
            case "billable":
              dataObjField.data[field.name] =
                field.value?.toLowerCase() === "true";
              break;

            default:
              dataObjField.data[field.name] = field.value ?? null;
              break;
          }
        }
      } else if (field.value && field.name === "TaxAmount") {
        this.updateTaxAmountTotal(field.value);
      } else if (field.name === "WithholdingTax") {
        this.updateWithholdingTaxTotal(field.value);
      }
      if (indexOfDataField >= 0 && field.triggerValidation) {
        return await this.performLineFieldValidation({
          schema: input.schema,
          request: {
            field: input.schema[indexOfDataField].name,
            seqNum: input.request.seqNum,
            value: input.schema[indexOfDataField].value
          }
        });
      }
    });
  }

  updateValueAfterValidation(
    input: FieldValidationRequest,
    validationResponse: any
  ) {
    switch (input.request.field) {
      /** Was the request make for a specific field */
      case "extendedAmount": {
        const line = this.invoiceLines.find(
          x => x.seqNum === input.request.seqNum
        );
        if (!line) {
          break;
        }
        const requestValue = addDecimalsToString(input.request.value);
        const responseValue = addDecimalsToString(validationResponse.text);
        // Only make a change if the value is different
        if (requestValue != responseValue) {
          // Update the schema, only update the value if its not null
          const schemaIndex = input.schema.findIndex(
            (matchingField: lineSchemaObject) =>
              matchingField.name.toLowerCase() ===
              "extendedAmount".toLowerCase()
          );
          if (schemaIndex >= 0) {
            input.schema[schemaIndex].value = responseValue;
          }
          // Update the line
          line.data.extendedAmount = responseValue;
        }
        break;
      }

      /** If not one of the above fields do nothing */
      default:
        break;
    }
  }

  updateWorktagsAfterValidation(
    input: FieldValidationRequest,
    worktags: any[]
  ) {
    const indexOfDataField = input.schema.findIndex(
      (matchingField: lineSchemaObject) =>
        matchingField.name.toLowerCase() === "additionalworktags"
    );
    // update schema
    input.schema[indexOfDataField].options = worktags;
    input.schema[indexOfDataField].value = worktags;
    // update data object
    const dataObjField = this.invoiceLines.find(
      (obj: line) => obj.seqNum === input.request.seqNum
    );
    if (dataObjField) {
      dataObjField.data["additionalWorktags"] = worktags;
    }
  }

  async performLineFieldValidation(input: FieldValidationRequest) {
    this.liveFormatAmountInput(input.request);
    const validationResponse = await lineValidationService.loadLineFieldValidationAsync(
      input.request.field,
      this.selectedDocumentId,
      input.request.seqNum,
      input.request.value
    );
    this.processValidationResponse(input, validationResponse);
  }

  private processValidationResponse = (
    input: FieldValidationRequest,
    validationResponse: any
  ) => {
    // This "if" emits a request validation to parent component
    if (validationResponse.fields?.length > 0) {
      validationResponse.fields.forEach(field => {
        if (field.triggerValidation) {
          this.$emit("emitValidateInvoiceField", field.name);
        }
      });
    }

    if (validationResponse) {
      const fieldSchema: lineSchemaObject | undefined = input.schema.find(
        (field: lineSchemaObject) => field.name === input.request.field
      );

      if (fieldSchema) {
        fieldSchema.isValid = validationResponse.isValid;
        fieldSchema.isError = !validationResponse.isValid;
        fieldSchema.value = validationResponse.id;
        if (
          !validationResponse.isValid &&
          validationResponse.message?.length > 0
        ) {
          fieldSchema.errorMessage = `Error: ${validationResponse.message}`;
        }
      }

      if (validationResponse.fields) {
        // Note: Where the values are updated,
        this.updateAssociatedFieldsAfterValidation(
          input,
          validationResponse.fields
        );
        this.updateValueAfterValidation(input, validationResponse);
      }
      if (validationResponse.worktags) {
        this.updateWorktagsAfterValidation(input, validationResponse.worktags);
      }
    }
  };

  async performNewWorktagValidation(input: FieldValidationRequest) {
    if (!input.request.value) {
      return await this.removeWorktag(input.schema, input.request);
    }
    const validationResponse = await lineValidationService.loadLineFieldValidationAsync(
      input.request.field,
      this.selectedDocumentId,
      input.request.seqNum,
      input.request.value
    );

    if (validationResponse) {
      if (
        !validationResponse.isValid &&
        validationResponse.message?.length > 0
      ) {
        this.$toasted.show(
          `<p><strong>Validation Error for ${input.request.field}:</strong> ${validationResponse.message}</p>`,
          options.ERROR_OPTIONS
        );
      }
      const fieldSchema: lineSchemaObject | undefined = input.schema.find(
        (field: lineSchemaObject) => field.name === input.request.field
      );
      const dataObj = this.invoiceLines.find(
        x => x.seqNum === input.request.seqNum
      )?.data;

      if (fieldSchema) {
        fieldSchema.isValid = validationResponse.isValid;
        fieldSchema.isError = !validationResponse.isValid;
        fieldSchema.options = validationResponse.id ?? fieldSchema.options;
        fieldSchema.value = validationResponse.id ?? fieldSchema.value;

        if (
          !validationResponse.isValid &&
          validationResponse.message?.length > 0
        ) {
          fieldSchema.errorMessage = `Error: ${validationResponse.message}`;
        }
      }
      if (dataObj) {
        dataObj.additionalWorktags =
          validationResponse.id ?? dataObj.additionalWorktags;
      }
      this.processValidationResponse(input, validationResponse);
    }
  }

  updateTaxAmountTotal(newTaxAmount) {
    this.$emit("lineUpdateTaxValue", newTaxAmount);
  }

  updateWithholdingTaxTotal(newWithholdingTaxAmount) {
    this.$emit("lineUpdateWithholdingTaxValue", newWithholdingTaxAmount);
  }

  async removeWorktag(
    schema: lineSchemaObject[],
    validationRequest: {
      field: string;
      seqNum: number;
      value: string;
    }
  ) {
    const removeWorktagResponse = await lineValidationService.removeLineWorktagAsync(
      this.selectedDocumentId,
      validationRequest.seqNum,
      validationRequest.value.substring(0, validationRequest.value.indexOf("|"))
    );
    const fieldSchema: lineSchemaObject | undefined = schema.find(
      (field: lineSchemaObject) => field.name === validationRequest.field
    );
    if (fieldSchema && removeWorktagResponse.data.isValid) {
      fieldSchema.value = removeWorktagResponse.data.id ?? [];
    } else if (fieldSchema) {
      fieldSchema.errorMessage = `Error: ${removeWorktagResponse.data.description}`;
    }

    const fieldValidationRequest: FieldValidationRequest = {
      schema,
      request: validationRequest
    };
    if (removeWorktagResponse.data.fields) {
      this.updateAssociatedFieldsAfterValidation(
        fieldValidationRequest,
        removeWorktagResponse.data.fields
      );
      this.updateValueAfterValidation(
        fieldValidationRequest,
        removeWorktagResponse.data
      );
    }
  }

  private handleFocusOnNewLineAdded(
    linesApiResponse: any,
    fieldToHighlight: string
  ) {
    const currentLinesLength = this.invoiceLines.length;
    const responseLinesLength = linesApiResponse.lines.length;
    const wasNewLineCreated = currentLinesLength < responseLinesLength;
    if (wasNewLineCreated) {
      const newLine = linesApiResponse.lines[responseLinesLength - 1];
      this.currentLineItemInFocus = `${newLine.seqNum}-${fieldToHighlight}`;
    }
  }

  handleTabAddInvoiceLine(event: KeyboardEvent) {
    if (event.shiftKey) {
      event.preventDefault();
      this.$emit("moveFocusToInvoiceFields");
    }
  }

  handleMoveFocusToInvoiceLines() {
    if (this.invoiceLines.length === 0) {
      (this.$refs.addinvoiceline as any).$el.focus();
    } else {
      this.handleFocusChangeToNextLine(0);
    }
  }

  async handleAddInvoiceLine() {
    // make API call to create new invoice line
    const createLineResponse: any = await invoiceLineService.addNewLineAsync(
      new RavenCreateLineRequest(this.selectedDocumentId)
    );
    this.handleFocusOnNewLineAdded(createLineResponse, this.fieldToHighlight);
    // API returns all lines for invoice after creating new line
    this.handleLineDetailsUpdate(
      createLineResponse.lines,
      createLineResponse.withholdingTax
    );
  }

  async handleRemoveInvoiceLines() {
    if (this.selectedInvoiceLines.length < 1) {
      this.$toasted.show(
        `<p><strong>Warning:</strong> Please select at least one line to delete</p>`,
        options.INFO_OPTIONS
      );
      return;
    }
    // loop through selected lines
    const invLinesToBeDeleted = this.selectedInvoiceLines.map(
      invoiceLine => invoiceLine.seqNum
    );
    const deleteLinesResponse: any = await invoiceLineService.deleteLineAsync(
      this.selectedDocumentId,
      invLinesToBeDeleted
    );
    // API returns all lines for invoice after deleting new line
    this.handleLineDetailsUpdate(
      deleteLinesResponse.lines,
      deleteLinesResponse.withholdingTax
    );
  }

  async handleDuplicateInvoiceLine() {
    if (this.selectedInvoiceLines.length !== 1) {
      this.$toasted.show(
        `<p><strong>Warning:</strong> Please select a single line</p>`,
        options.INFO_OPTIONS
      );
      return;
    }
    const lineToBeDuplicated = this.selectedInvoiceLines[0];
    // make API call to duplicate selected invoice line
    // API returns all lines for invoice after creating duplicate
    const duplicateLineResponse: any = await invoiceLineService.duplicateLineAsync(
      new RavenDuplicateLineRequest(
        this.selectedDocumentId,
        lineToBeDuplicated.seqNum
      )
    );
    this.handleFocusOnNewLineAdded(
      duplicateLineResponse,
      this.fieldToHighlight
    );
    this.handleLineDetailsUpdate(
      duplicateLineResponse.lines,
      duplicateLineResponse.withholdingTax
    );
  }

  handlePOLineDetailsTrayVisibility() {
    this.isPOLineDetailsTrayVisible = !this.isPOLineDetailsTrayVisible;
  }

  handleLineDetailsUpdate(invoiceLines: line[], withholdingTax = "0") {
    invoiceLines.sort((a, b) => a.seqNum - b.seqNum);
    this.invoiceLines = invoiceLines;
    this.truncateDecimals();
    this.forceInvLineContainerRerender = randomNumberGenerator();
    this.updateWithholdingTaxTotal(withholdingTax);
  }

  // clear all Tax Code fields when Enter Tax Due to Supplier is selected
  public clearTaxCodes() {
    this.invoiceLines.forEach(line => {
      line.schema.filter(schema => schema.name === "taxCode")[0].options = [];
      line.data.taxCode = "";
    });
  }

  async handlePreviousLineCoding() {
    this.isLineMenuActionInProgress = true;
    const apiResponse = await invoiceAutocodingService.extractPreviousLines(
      this.selectedDocumentId
    );
    if (apiResponse.data?.errorMessage) {
      this.$toasted.show(
        `<p>${apiResponse.data?.errorMessage ?? "Error occurred"}</p>`,
        options.ERROR_OPTIONS
      );
    } else {
      if ((apiResponse as any).autoCodedLines?.lines?.length > 0) {
        this.handleLineDetailsUpdate(
          (apiResponse as any).autoCodedLines.lines,
          (apiResponse as any).autoCodedLines.withholdingTax
        );
      }
      this.$toasted.show(
        `<p>${(apiResponse as any).message}</p>`,
        options.SUCCESS_OPTIONS
      );
    }
    this.isLineMenuActionInProgress = false;
  }

  async useSupplierWorktags() {
    this.isLineMenuActionInProgress = true;
    const apiResponse = await invoiceLineService.useSupplierWorktagsAsync(
      new UseSupplierWorktagsRequest(this.selectedDocumentId)
    );
    if (apiResponse.data?.errorMessage) {
      this.$toasted.show(
        `<p>${apiResponse.data?.errorMessage ?? "Error occurred"}</p>`,
        options.ERROR_OPTIONS
      );
    } else {
      if ((apiResponse as any).lines?.length > 0) {
        this.handleLineDetailsUpdate(
          (apiResponse as any).lines,
          (apiResponse as any).withholdingTax
        );
      }
      this.$toasted.show(
        `<p>Successfully applied supplier related worktags to all lines.</p>`,
        options.SUCCESS_OPTIONS
      );
    }
    this.isLineMenuActionInProgress = false;
  }

  @Watch("invoiceLines", { deep: true })
  onInvoiceLineValueChanged() {
    let totalExtendedAmountOnLines = 0;
    this.invoiceLines.forEach(currentLine => {
      const schemaObject = currentLine.schema.filter(
        schema => schema.name == "extendedAmount"
      )[0];
      if (
        parseFloat(currentLine.data.unitCost) > 0 ||
        parseInt(currentLine.data.quantity) > 0
      ) {
        schemaObject.isEditable = false;
      } else {
        schemaObject.isEditable = true;
      }
      totalExtendedAmountOnLines = SafeMath.safeAdd(
        totalExtendedAmountOnLines,
        currentLine.data.extendedAmount
      );
    });
    this.$emit("lineTotalChanged", totalExtendedAmountOnLines);

    const hasPrepaidLine = this.invoiceLines.some(
      line => line.data.prepaid === true
    );
    this.$emit("lineHasPrepaidFlag", hasPrepaidLine);
  }

  @Watch("documentType", { deep: true })
  async removeTaxRecoverabilityFromSchema() {
    // do not display Tax Recoverability field if Supplier Invoice Request
    if (this.documentType.value === "SIR") {
      this.invoiceLines.forEach(line => {
        line.schema = line.schema.filter(
          field => field.name !== "taxRecoverability"
        );
      });
    } else {
      this.invoiceLines = await documentDetailsService.loadDocumentLinesAsync(
        parseInt(this.$route.params.id)
      );
    }
    this.handleLineDetailsUpdate(this.invoiceLines);
  }
}
