





































import { Prop, Component } from "vue-property-decorator";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import { comment } from "@/models/document-entry/comment";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import { utils } from "@/utils/okta-utils";
import { getModule } from "vuex-module-decorators";
import DocumentCommentsStore from "@/store/document-comments.store";

@Component({
  components: {
    IconButton
  }
})
export default class CommentCard extends UserRolesMixin {
  // props
  @Prop() commentData!: comment;

  // reactive class properties
  private utils = utils;

  // readonly store modules
  private readonly documentCommentsStore = getModule(
    DocumentCommentsStore,
    this.$store
  );

  // computed properties
  private get isOwner(): boolean {
    return (
      this.commentData.commentOwnerEmail.toLowerCase().trim() ===
      this.userEmailAddress.toLowerCase().trim()
    );
  }
  private get commentOwnerName(): string {
    return (
      this.commentData.commentOwnerFirstName +
      " " +
      this.commentData.commentOwnerLastName
    );
  }

  // methods

  openDeleteConfirmationModal() {
    this.documentCommentsStore.storeCommentToDelete(this.commentData);
    this.documentCommentsStore.changeStateOfDeleteConfirmationModal();
  }
}
