
import { Vue, Component } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import DocumentRulesStore from "@/store/document-rules.store";
import { fieldLookupService } from "@/services/invoice-field-lookup.service";
import { documentRulesService } from "@/services/document-rules.service";

@Component
export default class DocumentRuleMixin extends Vue {
  // reactive properties
  isDeleteDocumentRuleModalDisplayed = false;
  private readonly defaultLookupPageOptions = {
    page: 0,
    totalPages: 0
  };

  // readonly store modules
  readonly documentRulesStore: DocumentRulesStore = getModule(
    DocumentRulesStore,
    this.$store
  );

  // computed properties
  get isSaveDocumentRuleActive(): boolean {
    return (
      this.documentRulesStore.getIsDocumentRuleComplete.one &&
      this.documentRulesStore.getIsDocumentRuleComplete.two &&
      this.documentRulesStore.getIsDocumentRuleComplete.three
    );
  }

  get saveButtonText(): string {
    return this.documentRulesStore.getIsNewDocumentRule
      ? "Save New Rule"
      : "Save Changes";
  }

  get deleteButtonText(): string {
    return this.documentRulesStore.getIsNewDocumentRule ? "" : "Delete Rule";
  }

  // methods
  changeStateOfDeleteDocumentRuleModal() {
    this.isDeleteDocumentRuleModalDisplayed = !this
      .isDeleteDocumentRuleModalDisplayed;
  }

  private focusOnTextFields(event: any) {
    event.target.select();
  }

  // arguments cannot be changed, these are what vuetify's filter function expects
  autocompleteFilter(item: any, queryText: string, _itemText: string) {
    return (
      (item.displayValue ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1 ||
      (item.description ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1 ||
      (item.id ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  mustPerformLookup(field: {
    options?: any[];
    search?: null | string;
  }): boolean {
    if (field.options) {
      return !field.options?.some(
        option => option.displayValue == field.search
      );
    }
    return false;
  }

  shoudLazyLoadMoreLookupOptions(field: string) {
    const currentPage = this[field].lookupResultsPageOptions.page;
    const totalPages = this[field].lookupResultsPageOptions.totalPages;
    if (totalPages > currentPage) {
      return true;
    }
  }

  // Intersect methods will be invoked on DOM mount and when the last item
  // in the corresponding v-autocomplete input's options list is intersected
  // optional arguments are defined by v-intersect directive (Intersection Observer API):
  // entries: IntersectionObserverEntry[], observer: IntersectionObserverEntry, isIntersecting: boolean
  async endLookupIntersect(field: string) {
    const moreOptionsAvailable = this.shoudLazyLoadMoreLookupOptions(field);
    if (moreOptionsAvailable) {
      switch (field) {
        case "company":
        case "currency":
        case "paymentterms":
        case "requester":
        case "handlingcode":
          await this.lookupOptions(field, false);
          break;
        case "remitto":
        case "paymenttype":
        case "shipto":
        case "approver":
          await this.lookupOptions(field, true);
          break;
        default:
          break;
      }
    }
  }

  resetLookupPageOptions(field: string) {
    this[field].lookupResultsPageOptions = this.defaultLookupPageOptions;
  }

  // perform lookup with API call and update field's options property
  // generic method used for some lookups
  async lookupOptions(field: string, includeInvID: boolean) {
    if (!this[field].search) {
      this[field].options = [];
      this.resetLookupPageOptions(field);
    } else {
      this[field].loading = true;
      const matchingOptions = includeInvID
        ? await fieldLookupService.lookupFieldWithInvIDAsync(
            {
              field: field,
              invID: this.documentRulesStore.getDocumentRuleID ?? 0,
              value: this[field].search
            },
            (this[field].lookupResultsPageOptions?.page ?? 0) + 1
          )
        : await fieldLookupService.lookupFieldAsync(
            {
              field: field,
              value: this[field].search
            },
            (this[field].lookupResultsPageOptions?.page ?? 0) + 1
          );
      this.updateHeaderFieldLookupOptions(field, matchingOptions);
      this[field].loading = false;
    }
  }

  updateHeaderFieldLookupOptions(field: string, apiResponseData: any) {
    const allOptions =
      (this[field].lookupResultsPageOptions?.page ?? 0) > 0
        ? this[field].options.concat(apiResponseData?.data)
        : apiResponseData?.data;
    this[field].options = allOptions;

    const refName = "document-rule-" + field;
    (this.$refs[refName] as any).cachedItems = allOptions;
    this[field].lookupResultsPageOptions = {
      page: apiResponseData.metadata.page,
      totalPages: apiResponseData.metadata.totalPages
    };
  }

  async saveDocumentRule() {
    if (this.documentRulesStore.getDocumentRuleID) {
      await documentRulesService.saveDocumentRule(
        this.documentRulesStore.getDocumentRuleID
      );
    }
  }

  async deleteDocumentRule() {
    await Promise.all([
      this.deletePrototypeDocumentRule(),
      this.deleteTempPrototypeDocumentRule()
    ]);
  }

  async deletePrototypeDocumentRule() {
    if (this.documentRulesStore.getPrototypeDocumentRuleID) {
      await documentRulesService.deleteDocumentRule(
        this.documentRulesStore.getPrototypeDocumentRuleID
      );
    }
  }

  async deleteTempPrototypeDocumentRule() {
    if (
      this.documentRulesStore.getDocumentRuleID &&
      this.documentRulesStore.getDocumentRuleID !==
        this.documentRulesStore.getPrototypeDocumentRuleID
    ) {
      await documentRulesService.deleteDocumentRule(
        this.documentRulesStore.getDocumentRuleID
      );
    }
  }
}
