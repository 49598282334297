import { render, staticRenderFns } from "./OnDemandSync.vue?vue&type=template&id=b8be2140&scoped=true&"
import script from "./OnDemandSync.vue?vue&type=script&lang=ts&"
export * from "./OnDemandSync.vue?vue&type=script&lang=ts&"
import style0 from "./OnDemandSync.vue?vue&type=style&index=0&id=b8be2140&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8be2140",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VDataTable,VProgressCircular,VRadio,VRadioGroup})
