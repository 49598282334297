import { app } from "@/main";
import { RavenValidationRequest } from "@/models/external-services/raven-api-validation-request";
import { RavenValidationResponse } from "@/models/external-services/raven-api-validation-response";

class FieldValidationService {
  public async validateFieldAsync(obj: RavenValidationRequest) {
    const apiResponse: RavenValidationResponse = await app.$ravenapi.post(
      `api/validate/${obj.field}?invID=${
        obj.invoiceid
      }&value=${encodeURIComponent(obj.value)}`,
      {}
    );
    return apiResponse;
  }

  // some field validation endpoints are currently GET requests instead of POST
  public async validateFieldGetAsync(obj: RavenValidationRequest) {
    const apiResponse: RavenValidationResponse = await app.$ravenapi.get(
      `api/validate/${obj.field}?invID=${
        obj.invoiceid
      }&value=${encodeURIComponent(obj.value)}`,
      {}
    );
    return apiResponse;
  }
}

export const fieldValidationService = new FieldValidationService(); // singleton
