



















import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class HoldBanner extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: "" }) message!: string;
  @Prop({ default: "" }) strongMessage!: string;
  @Prop({ default: () => {} }) handleClose!: Function;
  @Prop({ default: "" }) customClass?: string;
}
