import { render, staticRenderFns } from "./POLineDetailsTray.vue?vue&type=template&id=0d0b44ba&scoped=true&"
import script from "./POLineDetailsTray.vue?vue&type=script&lang=ts&"
export * from "./POLineDetailsTray.vue?vue&type=script&lang=ts&"
import style0 from "./POLineDetailsTray.vue?vue&type=style&index=0&id=0d0b44ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0b44ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBottomSheet,VContainer,VDataTable,VIcon,VSheet})
