




































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import { focusField } from "@/helpers/input-helpers";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class NumericInput extends Vue {
  @Prop() lineSequenceNumber!: number;
  @Prop() focusElementRefName!: string;
  @Prop() isReadOnly!: boolean;
  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() name!: string;
  @Prop() value!: string | number;
  @Prop() isRequired!: boolean;
  @Prop() isValid!: boolean;
  @Prop() isError!: boolean;
  @Prop() errorMessage?: string;
  @Prop() isVisible!: boolean;
  @Prop() isEditable!: boolean;
  private currentValue: string | number = this.value || "";

  handleTabKeypress(event: any) {
    if (event.shiftKey) {
      this.$emit("tabToPreviousField");
    } else {
      this.$emit("tabToNextField");
    }
  }

  onClickFocusChange() {
    const refName = this.lineSequenceNumber + "-" + this.name;
    this.$emit("focusRefField", refName);
  }

  @Watch("value")
  onValueChange() {
    this.currentValue = this.value;
  }

  @Watch("focusElementRefName")
  onFocusElementChange() {
    focusField(this);
  }

  mounted() {
    focusField(this);
  }
}
