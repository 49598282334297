import { app } from "@/main";

class LineValidationService {
  private async validateLineFieldAsync(
    fieldName: string,
    invID: number,
    lineSequenceNo: number,
    valueToValidate: string | any
  ) {
    valueToValidate = encodeURIComponent(valueToValidate);

    const requestUrl =
      "api/validate/line/" +
      fieldName +
      "?invID=" +
      invID +
      "&lineSequenceNumber=" +
      lineSequenceNo +
      "&valueToValidate=" +
      valueToValidate;

    const apiResponse = await app.$ravenapi.post(requestUrl, {});
    return apiResponse?.data;
  }

  public async loadLineFieldValidationAsync(
    fieldName: string,
    invID: number,
    lineSequenceNo: number,
    valueToValidate: string | any
  ) {
    const results = await this.validateLineFieldAsync(
      fieldName,
      invID,
      lineSequenceNo,
      valueToValidate
    );
    return results;
  }

  private async deleteLineWorktagAsync(
    invID: number,
    seqNum: number,
    orgType: string
  ) {
    const apiResponse = await app.$ravenapi.delete(
      `/api/invoiceline/deleteworktag?invID=${invID}&seqNum=${seqNum}&organizationType=${orgType}`
    );
    return apiResponse;
  }

  public async removeLineWorktagAsync(
    invID: number,
    seqNum: number,
    orgType: string
  ) {
    orgType = encodeURIComponent(orgType);
    const results = await this.deleteLineWorktagAsync(invID, seqNum, orgType);
    return results;
  }
}

export const lineValidationService = new LineValidationService(); // singleton
