import { app } from "@/main";
import { RavenCreateLineRequest } from "@/models/external-services/raven-api-create-line-request";
import { RavenDuplicateLineRequest } from "@/models/external-services/raven-api-duplicate-line-request";
import { UseSupplierWorktagsRequest } from "@/models/external-services/use-supplier-worktags-request";

class InvoiceLineService {
  public async addNewLineAsync(newLineRequest: RavenCreateLineRequest) {
    const apiResponse = await app.$ravenapi.post(
      "api/invoiceline/create",
      newLineRequest
    );
    return apiResponse;
  }

  public async deleteLineAsync(invoiceID: number, sequenceNumbers: number[]) {
    const apiResponse = await app.$ravenapi.delete(
      `api/invoiceline/delete?invID=${invoiceID}&seqNumbers=${sequenceNumbers}`
    );
    return apiResponse;
  }

  public async duplicateLineAsync(dupLineRequest: RavenDuplicateLineRequest) {
    const apiResponse = await app.$ravenapi.post(
      "api/invoiceline/duplicate",
      dupLineRequest
    );
    return apiResponse;
  }

  public async moveUpLineAsync(data: { invID: number; seqNum: number }) {
    const apiResponse = await app.$ravenapi.patch(
      "api/invoiceline/moveup",
      data
    );
    return apiResponse;
  }

  public async useSupplierWorktagsAsync(
    useSupplierWorktagsRequest: UseSupplierWorktagsRequest
  ) {
    const apiResponse = await app.$ravenapi.post(
      "api/invoiceline/usesupplierworktags",
      useSupplierWorktagsRequest
    );
    return apiResponse;
  }
}

export const invoiceLineService = new InvoiceLineService(); // singleton
