





































import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class PreprocessingBanner extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: () => [] }) messageList!: string[];
  @Prop({ default: () => {} }) handleClose!: Function;

  private multipleWarningsExist = false;

  created() {
    if (this.messageList.length > 1) {
      this.multipleWarningsExist = true;
    }
  }
}
