import { app } from "@/main";
import {
  POLinePairingDetailsResponse,
  UpdateInvoiceLinesRequest
} from "@/models/document-entry/po-line-pairing";

class POLineDetailsService {
  public async get(InvID: string): Promise<POLinePairingDetailsResponse> {
    const apiResponse: any = await app.$ravenapi.get(
      `api/poinvoiceline/${InvID}`
    );
    return apiResponse;
  }

  public async updateAllLines(requestBody: UpdateInvoiceLinesRequest) {
    const response = await app.$ravenapi.post(
      "api/validate/poinvoiceline",
      requestBody
    );
    return response;
  }
}

export const poLineDetailsService = new POLineDetailsService();
