























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// Third Party imports
import { Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { cloneDeep } from "lodash";

// Custom Components
import UserRolesMixin from "@/mixins/UserRoles.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import InputWrapper from "@/components/forms/document-entry/invoice-fields/InputWrapper.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import NumericInput from "@/components/design-system/inputs/NumericInput.vue";
import StandardInput from "@/components/design-system/inputs/StandardInput.vue";

// Services
import { updateTaxService } from "@/services/update-tax.service";
import { fieldLookupService } from "@/services/invoice-field-lookup.service";
import { fieldValidationService } from "@/services/invoice-field-validation.service";

// Interfaces and Models
import { ValidatableField } from "@/models/document-entry/validatable-field";
import { RavenValidationResponse } from "@/models/external-services/raven-api-validation-response";
import { DocumentField } from "@/models/document-entry/document-field";
import { InputObject } from "@/models/document-entry/input-object";
import {
  ModalTable,
  TransTaxRateItem,
  WithholdingTaxRateItem
} from "@/models/document-entry/modal-tables";
import { TransTaxResponse } from "@/models/document-entry/trans-tax-response";
import { WithholdingTaxResponse } from "@/models/document-entry/withholding-tax-response";
import { UpdateSyncFieldI } from "@/models/document-entry/on-demand-sync";

// Helpers
import { randomNumberGenerator, getDecimals } from "@/helpers/numbers-helpers";
import { Decimal } from "decimal.js";

// Constants
import options from "@/shared/constants/toast-options";
import { MODAL_TYPE } from "@/shared/constants/on-demand-sync";

// Store
import DocumentRulesStore from "@/store/document-rules.store";
import DocViewerStore from "@/store/doc-viewer.store";

const TAX_OPTION = {
  NONE: 0,
  CALCULATE_SELF_ASSESSED_TAX: 1,
  CALCULATE_TAX_DUE_TO_SUPPLIER: 2,
  ENTER_TAX_DUE_TO_SUPPLIER: 3
};

@Component({
  components: {
    "secondary-button": SecondaryButton,
    "input-wrapper": InputWrapper,
    "simple-confirmation-modal": SimpleConfirmationModal,
    "numeric-input": NumericInput,
    "standard-input": StandardInput
  }
})
export default class InvoiceFieldsForm extends UserRolesMixin {
  // props passed from parent component
  @Prop() isReadOnly!: boolean;
  @Prop() documentType!: string;
  @Prop() initialFieldValues!: DocumentField[];
  @Prop() validateFieldRequest?: UpdateSyncFieldI;
  @Prop() taxAmountValueFromLine!: string;
  @Prop() withholdingTaxValueFromLine!: string;
  @Prop() fieldValidationRequestedFromInvLine?: string;
  @Prop() lineHasPrepaidFlag?: boolean;

  // feature flag evaluations
  private isOnDemandSyncDisplayedForCustomer: boolean = this.$launchDarkly.variation(
    "show-etl-on-demand-syncs",
    false
  );
  private shouldDisplayInvoiceReferenceType: boolean = this.$launchDarkly.variation(
    "should-show-invoice-type-ref",
    false
  );
  private shouldDisplayReferenceType: boolean = this.$launchDarkly.variation(
    "should-show-reference-type",
    false
  );
  // reactive class properties
  private defaultLookupPageOptions = {
    page: 0,
    totalPages: 0
  };
  private company: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private ponum: ValidatableField = {
    value: "",
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private invoiceid: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private supplier: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private remitto: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private supplierinfo: ValidatableField = {
    value: null,
    required: false,
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private invoicedate: ValidatableField = {
    value: null,
    required: true,
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private duedate: ValidatableField = {
    value: null,
    required: true,
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private currency: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private paymentterms: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private paymenttype: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private shipto: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private freightamount: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private otheramount: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private controltotal: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private memo: ValidatableField = {
    value: "",
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private suppliercontract: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private requester: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private customeracct: ValidatableField = {
    value: null,
    required: false,
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private approver: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private taxamount: ValidatableField = {
    isReadOnly: false,
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private withholdingtax: ValidatableField = {
    isReadOnly: true,
    value: null,
    required: false,
    placeholder: "",
    isValid: true,
    hasError: false,
    errorMessage: ""
  };
  private taxcode: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private adjustmentreason: ValidatableField = {
    value: { text: "Select One", value: 0 },
    required: false,
    isValid: false,
    hasError: false,
    options: [],
    loading: false,
    errorMessage: ""
  };
  private referenceinvoice: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private referencenumber: ValidatableField = {
    value: "",
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private prepaidtype: ValidatableField = {
    value: { text: "Select One", value: 0 },
    required: false,
    isValid: false,
    hasError: false,
    options: [
      {
        text: "Select One",
        value: 0
      }
    ],
    errorMessage: ""
  };
  private prepaiddate: ValidatableField = {
    value: null,
    required: true,
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private prepaidinstallments: ValidatableField = {
    isReadOnly: false,
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private frequencybehavior: ValidatableField = {
    value: null,
    required: true,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private overridepo: ValidatableField = {
    isReadOnly: false,
    value: null,
    required: false,
    isVisible: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private accountingdateoverride: ValidatableField = {
    value: null,
    required: true,
    isValid: false,
    hasError: false,
    errorMessage: ""
  };
  private handlingcode: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private referencetype: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private invoicereferencetype: ValidatableField = {
    value: null,
    required: false,
    placeholder: "",
    isValid: false,
    hasError: false,
    options: [],
    search: null,
    loading: false,
    lookupResultsPageOptions: this.defaultLookupPageOptions,
    errorMessage: ""
  };
  private taxoption: ValidatableField = {
    value: { text: "Select One", value: 0 },
    required: false,
    isValid: false,
    hasError: false,
    options: [
      {
        text: "Select One",
        value: 0
      },
      {
        text: "Calculate Self Assessed Tax",
        value: 1
      },
      {
        text: "Calculate Tax Due to Supplier",
        value: 2
      },
      {
        text: "Enter Tax Due to Supplier",
        value: 3
      }
    ],
    errorMessage: ""
  };

  private isTaxModalOpen = false;
  private taxoptionsTable: ModalTable = {
    headers: [
      {
        text: "Tax Applicability",
        align: "start",
        sortable: false,
        value: "taxApplicability"
      },
      {
        text: "Tax Code",
        value: "taxCodeName",
        sortable: false,
        align: "start"
      },
      {
        text: "Tax by Tax Code",
        value: "taxForCode",
        sortable: false,
        align: "start"
      },
      { text: "", value: "empty", sortable: false, align: "start" },
      {
        text: "Tax Rate",
        value: "descriptor",
        sortable: false,
        align: "start"
      },
      {
        text: "Tax by Tax Rate",
        value: "taxAmount",
        sortable: false,
        align: "start"
      }
    ],
    items: []
  };
  private withholdingTaxoptionsTable: ModalTable = {
    headers: [
      {
        text: "Tax Code",
        align: "start",
        sortable: false,
        value: "taxCodeName"
      },
      {
        text: "Tax by Tax Code",
        value: "taxForCode",
        sortable: false,
        align: "start"
      },
      { text: "", value: "empty", sortable: false, align: "start" },
      {
        text: "Taxable Amount",
        value: "taxableAmount",
        sortable: false,
        align: "start"
      },
      {
        text: "Subject to Withholding",
        value: "withholdingAmount",
        sortable: false,
        align: "start"
      },
      {
        text: "Tax Rate",
        value: "descriptor",
        sortable: false,
        align: "start"
      },
      {
        text: "Tax Rate %",
        value: "taxRate",
        sortable: false,
        align: "center"
      },
      {
        text: "Tax by Tax Rate",
        value: "taxAmount",
        sortable: false,
        align: "start"
      }
    ],
    items: []
  };
  private fieldOrder: HTMLInputElement[] = [];
  private transTaxTotal = new Decimal("0");
  private withholdingTaxTotal = new Decimal("0");
  private isFetchingUpdatedTaxes = false;
  private forceUpdateTaxTableReload = randomNumberGenerator();
  private _timerId!: NodeJS.Timeout;

  private POReloadIcon = {
    icon: "mdi-reload",
    handleClick: () => {
      this.$emit("onDemandSyncModal", MODAL_TYPE.PO_NUM);
    },
    customClass: "sync-icon",
    tooltip: "Sync from Workday",
    userHasIconPermission: this.canUserOnDemandSync === false
  };
  private SupplierReloadIcon = {
    icon: "mdi-reload",
    handleClick: () => {
      this.$emit("onDemandSyncModal", MODAL_TYPE.SUPPLIER);
    },
    customClass: "sync-icon",
    tooltip: "Sync from Workday",
    userHasIconPermission: this.canUserOnDemandSync === false
  };
  private SupplierContractReloadIcon = {
    icon: "mdi-reload",
    handleClick: () => {
      this.$emit("onDemandSyncModal", MODAL_TYPE.SUPPLIER_CONTRACT);
    },
    customClass: "sync-icon",
    tooltip: "Sync from Workday",
    userHasIconPermission: this.canUserOnDemandSync === false
  };

  // computed properties
  private get additionalChargesTotal() {
    let tax: Decimal = !this.taxamount.value
      ? new Decimal("0")
      : new Decimal(this.taxamount.value.toString());

    if (this.taxoption.value) {
      tax =
        this.taxoption.value["value"] === TAX_OPTION.CALCULATE_SELF_ASSESSED_TAX
          ? new Decimal("0")
          : tax;
    }

    const freight = !this.freightamount.value
      ? new Decimal("0")
      : new Decimal(this.freightamount.value.toString());
    const other = !this.otheramount.value
      ? new Decimal("0")
      : new Decimal(this.otheramount.value.toString());
    return tax.plus(freight.plus(other));
  }

  private get isTaxButtonActive(): boolean {
    if (this.taxoption.value) {
      return (
        this.taxoption.value["value"] ===
          TAX_OPTION.CALCULATE_SELF_ASSESSED_TAX ||
        this.taxoption.value["value"] ===
          TAX_OPTION.CALCULATE_TAX_DUE_TO_SUPPLIER
      );
    }
    return false;
  }

  // readonly store modules
  private readonly documentRulesStore: DocumentRulesStore = getModule(
    DocumentRulesStore,
    this.$store
  );
  private readonly docViewerStore: DocViewerStore = getModule(
    DocViewerStore,
    this.$store
  );

  // lifecycle methods
  mounted() {
    this.setFieldOrder();
  }

  updated() {
    this.setFieldOrder();
  }

  //methods

  // arguments cannot be changed, these are what vuetify's filter function expects
  private autocompleteFilter(item: any, queryText: string, itemText: string) {
    return (
      (item.displayValue ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1 ||
      (item.description ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1 ||
      (item.id ?? "")
        .toLocaleLowerCase()
        .indexOf(queryText.toLocaleLowerCase()) > -1
    );
  }

  private moveFocusToNextField(event: KeyboardEvent) {
    const currentFieldIndex = this.fieldOrder.indexOf(
      event.target as HTMLInputElement
    );
    if (!event.shiftKey && currentFieldIndex === this.fieldOrder.length - 1) {
      event.preventDefault();
      this.$emit("moveFocusToInvoiceLines");
    } else {
      const nextField = event.shiftKey
        ? this.fieldOrder[currentFieldIndex - 1]
        : this.fieldOrder[currentFieldIndex + 1];
      // if next field is undefined, disabled, or next or current field is a date type, allow default function of tab key to occur
      if (
        nextField &&
        !nextField.disabled &&
        !(event.shiftKey && nextField.type === "date") &&
        (event.target as HTMLInputElement).type !== "date"
      ) {
        event.preventDefault();
        nextField.focus();
      }
    }
  }

  public moveFocusToLastField() {
    this.fieldOrder[this.fieldOrder.length - 1].focus();
  }

  clearField(event: any, field: string, fieldValue: any) {
    if (event.key == "Delete" || event.key == "Backspace") {
      if (fieldValue?.trim().length === 0) {
        const fieldRef: any = this.$refs[field];
        fieldRef.reset();
        this.validateFieldValue(field);
      }
    }
  }

  private focusOnTextFields(event: any, fieldName: string) {
    this.setFocusedField(fieldName);
    event.target.select();
  }

  private setFocusedField(fieldName: string) {
    this.docViewerStore.setFocusedField(fieldName);
  }

  public setTaxTable(newTaxes: TransTaxResponse[]) {
    let taxes: any[] = [];
    newTaxes.forEach((element: TransTaxResponse) => {
      taxes = [...taxes, element, ...element["taxRates"]];
    });
    this.taxoptionsTable.items = taxes;
  }

  public setWithholdingTaxTable(newTaxes: WithholdingTaxResponse[]) {
    let withholdingTaxes: any[] = [];
    newTaxes.forEach((element: WithholdingTaxResponse) => {
      // eslint-disable-next-line prettier/prettier
      withholdingTaxes = [...withholdingTaxes, element, ...element["withholdingTaxRates"]];
    });
    this.withholdingTaxoptionsTable.items = withholdingTaxes;
  }

  private handleTaxModal() {
    this.isTaxModalOpen = !this.isTaxModalOpen;
  }

  private async saveTaxRate(event: any, item: TransTaxRateItem) {
    const input = event.target.value == "" ? "0" : event.target.value;
    const floatValue = new Decimal(input)?.toDecimalPlaces(2)?.toNumber();
    if (floatValue !== null) {
      item.taxAmount = floatValue;
      if (this.taxoption.value !== null) {
        this.isFetchingUpdatedTaxes = true;
        const apiResponse = await updateTaxService.save({
          invID: parseInt(this.$route.params.id),
          taxRates: [
            {
              rateID: item.id,
              amount: floatValue
            }
          ],
          taxOption: this.taxoption.value["value"]
        });
        if (apiResponse["transTaxAmount"] == floatValue) {
          item["prependInnerIcon"] = "mdi-check-circle";
          item["class"] = "success";
          this.forceUpdateTaxTableReload = randomNumberGenerator();
          this.$toasted.show(
            "<p>Tax rate successfully updated.</p>",
            options.SUCCESS_OPTIONS
          );
        }
        this.isFetchingUpdatedTaxes = false;
      }
    }
  }

  private async saveWithholdingTaxRate(
    event: any,
    item: WithholdingTaxRateItem
  ) {
    const floatValue = new Decimal(event.target.value)
      ?.toDecimalPlaces(2)
      ?.toNumber();
    if (floatValue !== null) {
      item.taxAmount = floatValue;
      if (this.taxoption.value !== null) {
        this.isFetchingUpdatedTaxes = true;
        const apiResponse = await updateTaxService.savewithholding({
          invID: parseInt(this.$route.params.id),
          withholdingTaxRates: [
            {
              rateID: item.id,
              amount: floatValue
            }
          ],
          taxOption: this.taxoption.value["value"]
        });
        if (apiResponse["withholdingTaxAmount"] == floatValue) {
          if (apiResponse["withholdingTaxBalance"]) {
            this.$emit(
              "modalUpdateWithholdingTaxValue",
              apiResponse["withholdingTaxBalance"].toString()
            );
          }
          item["prependInnerIcon"] = "mdi-check-circle";
          item["class"] = "success";
          this.forceUpdateTaxTableReload = randomNumberGenerator();
          this.$toasted.show(
            "<p>Withholding Tax rate successfully updated.</p>",
            options.SUCCESS_OPTIONS
          );
        }
        this.isFetchingUpdatedTaxes = false;
      }
    }
  }

  private async updateTaxes() {
    if (this.taxoption.value) {
      this.isFetchingUpdatedTaxes = true;
      const apiResponse = await updateTaxService.update(
        this.$route.params.id,
        this.taxoption.value["value"],
        this.invoicedate.value as string
      );
      this.setTaxTable(apiResponse["taxes"]);
      this.setWithholdingTaxTable(apiResponse["withholdingTaxes"]);
      this.$emit(
        "modalRefreshWithholdingTaxValue",
        apiResponse["withholdingTaxBalance"]
      );
      this.$toasted.show(
        "<p>Tax amounts successfully refreshed.</p>",
        options.SUCCESS_OPTIONS
      );
      this.isFetchingUpdatedTaxes = false;
    }
  }

  private formatDecimalsAfterTabOut(field: string) {
    const shouldUseTwoDecimals =
      field == "controltotal" || field == "taxamount";
    const fieldValue = this.$data[field].value;
    const decimalPlaces = shouldUseTwoDecimals ? 2 : getDecimals(fieldValue);
    this.$data[field].value = fieldValue
      ? new Decimal(fieldValue).toFixed(decimalPlaces)
      : "0.00";
  }

  // perform validation with API call
  async validateFieldValue(field: string, coordinatesFieldName?: string) {
    // if field is cleared remove associated pdf coordinates from database
    if (coordinatesFieldName) {
      if (
        !this.$data[field].value ||
        this.$data[field].value == "" ||
        this.$data[field].value == 0
      ) {
        this.$emit("clearImageCoordinates", coordinatesFieldName);
      }
    }
    const amounts = ["controltotal", "freightamount", "otheramount"];
    // only perform validations if the document is NOT in readonly status
    if (!this.isReadOnly) {
      let validationResponse: RavenValidationResponse;
      if (["invoicedate", "invoiceid", ...amounts].includes(field)) {
        // These are the invoice fields currently using GET instead of POST for validation endpoint
        if (amounts.includes(field)) {
          this.formatDecimalsAfterTabOut(field);
        }
        if (field == "invoiceid") {
          this.$data[field].value =
            this.$data[field].value?.toUpperCase() ?? "";
        }
        validationResponse = await fieldValidationService.validateFieldGetAsync(
          {
            invoiceid: parseInt(this.$route.params.id),
            field: field,
            value: this.$data[field].value ?? ""
          }
        );
      } else if (field === "taxoption") {
        validationResponse = await fieldValidationService.validateFieldAsync({
          invoiceid: parseInt(this.$route.params.id),
          field: field,
          value: (this[field].value as any).value ?? "" // entire option obj stored as value
        });
      } else if (field === "prepaidtype") {
        let newValue = "";
        newValue =
          ((this[field].value as any).value != null
            ? (this[field].value as any).value
            : this.$data[field].value) ?? "";

        validationResponse = await fieldValidationService.validateFieldAsync({
          invoiceid: parseInt(this.$route.params.id),
          field: field,
          value: newValue
        });
      } else if (field === "adjustmentreason") {
        validationResponse = await fieldValidationService.validateFieldAsync({
          invoiceid: parseInt(this.$route.params.id),
          field: field,
          value: (this.adjustmentreason.value as any).id // entire option obj stored as value
        });
      } else {
        if (field === "taxamount") {
          this.formatDecimalsAfterTabOut(field);
        }
        validationResponse = await fieldValidationService.validateFieldAsync({
          invoiceid: parseInt(this.$route.params.id),
          field: field,
          value: this.$data[field].value ?? ""
        });
      }
      this.processValidationResponse(validationResponse, field);
      if (field === "taxamount") {
        this.formatDecimalsAfterTabOut(field);
      }
      if (field === "shipto") {
        this.processShipToTransactionTaxRuleResponse();
      }
      if (field === "ponum" || field === "suppliercontract") {
        const isValid =
          this.isValidatableFieldValid(this.ponum) ||
          this.isValidatableFieldValid(this.suppliercontract);
        this.$emit("isPoNumOrSupplierContractValid", isValid);
      }
      if (field === "supplier") {
        const isValid = this.isValidatableFieldValid(this.supplier);
        this.$emit("isSupplierValid", isValid);
      }
    }
  }

  processShipToTransactionTaxRuleResponse() {
    if (this.$data["shipto"].errorMessage == "") {
      this.$emit("updateInvoiceLines");
    }
  }

  processValidationResponse(
    validationResponse: RavenValidationResponse,
    fieldValidated: string
  ) {
    if (validationResponse.data.isValid === true) {
      this.$data[fieldValidated].isValid = true;
      this.$data[fieldValidated].hasError = false;
      if (validationResponse.data.message !== "") {
        this.$data[fieldValidated].errorMessage = "";
      }
    } else {
      this.$data[fieldValidated].hasError = true;
      this.$data[fieldValidated].isValid = false;
      if (validationResponse.data.message.length > 0) {
        this.$data[
          fieldValidated
        ].errorMessage = `Error: ${validationResponse.data.message}`;
      }
    }
    // trigger other fields
    if (
      validationResponse.data.fields &&
      validationResponse.data.fields.length > 0
    ) {
      validationResponse.data.fields.forEach(fieldToValidate =>
        this.handleOtherFieldChange(fieldToValidate)
      );
    }
    // show banner if document group was changed
    if (
      validationResponse.data.documentGroupAssignment &&
      validationResponse.data.documentGroupAssignment
        .calculatedDocumentGroupIsNew === true &&
      validationResponse.data.documentGroupAssignment.message.length > 0
    ) {
      this.$toasted.show(
        `<p><strong>${validationResponse.data.documentGroupAssignment.message}</strong></p>`,
        validationResponse.data.documentGroupAssignment
          .userHasAccessToCalculatedDocumentGroup
          ? options.INFO_OPTIONS
          : options.ERROR_OPTIONS
      );
    }
    // show banner for document rule if validation triggered rule availability
    if (validationResponse.data.documentRuleAvailable) {
      this.triggerDocumentRuleAvailableNotification(
        validationResponse.data.documentRuleAvailable
      );
    }
  }

  async handleOtherFieldChange(field: {
    name: string;
    triggerValidation: boolean;
    placeholder?: string;
    text?: string;
    value?: string;
  }) {
    const fieldName = field.name.toLowerCase();
    if (this.$data[fieldName]) {
      if (field.placeholder || field.placeholder == "") {
        this.$data[fieldName].placeholder = field.placeholder;
      }
      if (field.value || field.value === "") {
        const isNullOrEmpty = field.value == null || field.value == "";
        if (
          Object.prototype.hasOwnProperty.call(this.$data[fieldName], "search")
        ) {
          const fieldRef: any = this.$refs[fieldName];
          fieldRef.cachedItems = [];
          this.$data[fieldName].options?.push({
            id: field.value ?? null,
            description: field.text ?? field.value ?? "",
            displayValue: field.text ?? field.value ?? ""
          });
        }
        this.$data[fieldName].value = !isNullOrEmpty ? field.value : null;
      }
      if (field.triggerValidation === true) {
        await this.validateFieldValue(fieldName);
      }
    }
  }

  triggerDocumentRuleAvailableNotification(docRuleData: {
    invID: string;
    customRuleName: string;
  }) {
    this.$emit(
      "triggerDocumentRuleAvailableNotification",
      docRuleData.customRuleName
    );
  }

  // perform lookup with API call and update field's options property
  // generic method used for some lookups
  async lookupOptionsOnSearch(field: string) {
    if (!this.$data[field].search) {
      this.$data[field].options = [];
      this.resetLookupPageOptions(field);
    } else {
      this.$data[field].loading = true;
      const matchingOptions = await fieldLookupService.lookupFieldAsync(
        {
          field: field,
          value: this.$data[field].search
        },
        (this.$data[field].lookupResultsPageOptions?.page ?? 0) + 1
      );
      this.updateHeaderFieldLookupOptions(field, matchingOptions);
      this.$data[field].loading = false;
    }
  }

  async lookupOptionsWithInvIDOnSearch(field: string) {
    if (!this.$data[field].search) {
      this.$data[field].options = [];
      this.resetLookupPageOptions(field);
    } else {
      this.$data[field].loading = true;
      const matchingOptions = await fieldLookupService.lookupFieldWithInvIDAsync(
        {
          field: field,
          invID: parseInt(this.$route.params.id),
          value: this.$data[field].search
        },
        (this.$data[field].lookupResultsPageOptions?.page ?? 0) + 1
      );
      this.updateHeaderFieldLookupOptions(field, matchingOptions);
      this.$data[field].loading = false;
    }
  }

  updateHeaderFieldLookupOptions(field: string, apiResponseData: any) {
    const allOptions =
      (this.$data[field].lookupResultsPageOptions?.page ?? 0) > 0
        ? this.$data[field].options.concat(apiResponseData?.data)
        : apiResponseData?.data;
    this.$data[field].options = allOptions;
    (this.$refs[field] as any).cachedItems = allOptions;
    this.$data[field].lookupResultsPageOptions = {
      page: apiResponseData.metadata.page,
      totalPages: apiResponseData.metadata.totalPages
    };
  }

  async lookupPoNumber() {
    this.ponum.loading = true;
    const matchingOptions = await fieldLookupService.lookupPoNumberAsync(
      {
        invID: parseInt(this.$route.params.id),
        value: this.ponum.search as string,
        supplierID: this.supplier.value as string,
        companyID: this.company.value as string
      },
      (this.ponum.lookupResultsPageOptions?.page ?? 0) + 1
    );
    const allOptions =
      (this.ponum.lookupResultsPageOptions?.page ?? 0) > 0
        ? (this.ponum.options ?? []).concat(matchingOptions?.data)
        : matchingOptions?.data;
    this.ponum.options = allOptions;
    (this.$refs["ponum"] as any).cachedItems = allOptions;
    this.ponum.lookupResultsPageOptions = {
      page: matchingOptions.metadata.page,
      totalPages: matchingOptions.metadata.totalPages
    };
    this.ponum.loading = false;
  }

  // determines whether or not to perform lookup
  private mustPerformLookup(field: {
    options?: any[];
    search?: null | string;
  }): boolean {
    if (field.options) {
      return !field.options?.some(
        option => option.displayValue == field.search
      );
    }
    return false;
  }

  shoudLazyLoadMoreLookupOptions(field: string) {
    const currentPage = this.$data[field].lookupResultsPageOptions.page;
    const totalPages = this.$data[field].lookupResultsPageOptions.totalPages;
    if (totalPages > currentPage) {
      return true;
    }
  }

  // Intersect methods will be invoked on DOM mount and when the last item
  // in the corresponding v-autocomplete input's options list is intersected
  // optional arguments are defined by v-intersect directive (Intersection Observer API):
  // entries: IntersectionObserverEntry[], observer: IntersectionObserverEntry, isIntersecting: boolean
  private async endIntersect(field: string) {
    const moreOptionsAvailable = this.shoudLazyLoadMoreLookupOptions(field);
    if (moreOptionsAvailable) {
      switch (field) {
        case "company":
          await this.lookupOptionsOnSearch("company");
          break;
        case "ponum":
          await this.lookupPoNumber();
          break;
        case "supplier":
          await this.lookupOptionsWithInvIDOnSearch("supplier");
          break;
        case "remitto":
          await this.lookupOptionsWithInvIDOnSearch("remitto");
          break;
        case "currency":
          await this.lookupOptionsOnSearch("currency");
          break;
        case "paymentterms":
          await this.lookupOptionsOnSearch("paymentterms");
          break;
        case "paymenttype":
          await this.lookupOptionsWithInvIDOnSearch("paymenttype");
          break;
        case "shipto":
          await this.lookupOptionsWithInvIDOnSearch("shipto");
          break;
        case "suppliercontract":
          await this.lookupOptionsWithInvIDOnSearch("suppliercontract");
          break;
        case "requester":
          await this.lookupOptionsOnSearch("requester");
          break;
        case "referenceinvoice":
          await this.lookupOptionsWithInvIDOnSearch("referenceinvoice");
          break;
        case "approver":
          await this.lookupOptionsWithInvIDOnSearch("approver");
          break;
        case "handlingcode":
          await this.lookupOptionsOnSearch("handlingcode");
          break;
        case "invoicereferencetype":
          await this.lookupOptionsOnSearch("invoicereferencetype");
          break;
        case "referencetype":
          await this.lookupOptionsOnSearch("referencetype");
          break;
        case "taxcode":
          await this.lookupOptionsWithInvIDOnSearch("taxcode");
          break;
        case "frequencybehavior":
          await this.lookupOptionsWithInvIDOnSearch("frequencybehavior");
          break;
        default:
          break;
      }
    }
  }

  resetLookupPageOptions(field: string) {
    this.$data[field].lookupResultsPageOptions = this.defaultLookupPageOptions;
  }

  async getAdjustmentReasonOptions(field: string) {
    this.$data[field].loading = true;
    const matchingOptions = await fieldLookupService.lookupFieldAsync({
      field: field,
      value: this.$data[field].value
    });
    this.$data[field].options = matchingOptions?.data;
    this.$data[field].loading = false;
  }

  async onInstallmentsAmountValueChange(newValue: string, oldValue: string) {
    if (newValue !== null) {
      await this.validateFieldValue("prepaidinstallments");
    } else {
      this.$nextTick(() => (this.freightamount.value = oldValue));
    }
  }

  // methods for non-standard UI response to field validations
  async onFreightAmountValueChange(newValue: string, oldValue: string) {
    if (newValue !== null) {
      await this.validateFieldValue("freightamount", "ShipAmt");
    } else {
      this.$nextTick(() => (this.freightamount.value = oldValue));
    }
  }

  async onControlTotalValueChange(newValue: string, oldValue: string) {
    if (newValue !== null) {
      await this.validateFieldValue("controltotal", "InvoiceAmt");
    } else {
      this.$nextTick(() => (this.controltotal.value = oldValue));
    }
  }

  async onTaxAmountValueChange(newValue: string, oldValue: string) {
    const fieldName = "taxamount";
    if (newValue !== null) {
      this.formatDecimalsAfterTabOut(fieldName);
      await this.validateFieldValue(fieldName, "TaxAmt");
    } else {
      this.$nextTick(() => (this.taxamount.value = oldValue));
    }
  }

  async onAdjustmentReasonValueChange(newValue: string, oldValue: string) {
    if (newValue !== null) {
      await this.validateFieldValue("adjustmentreason");
    } else {
      this.$nextTick(() => (this.adjustmentreason.value = oldValue));
    }
  }

  async onReferenceInvoiceValueChange() {
    await this.validateFieldValue("referenceinvoice");
  }

  async onPrepaidTypeValueChange(newValue: string, oldValue: string) {
    this.setFieldOrder();
    if (newValue !== null) {
      await this.validateFieldValue("prepaidtype");
    } else {
      this.$nextTick(() => (this.prepaidtype.value = oldValue));
    }
  }

  async onOverridePOValueChange() {
    await this.validateFieldValue("overridepo");
  }

  private get shouldDisplayPrepaidDate() {
    return (
      this.prepaidtype &&
      this.prepaidtype.value &&
      ((this.prepaidtype.value as any)?.value === "MANUAL" ||
        this.prepaidtype.value === "MANUAL") &&
      this.lineHasPrepaidFlag &&
      this.documentType == "Invoice"
    );
  }

  private get shouldDisplayFrequency() {
    return (
      this.prepaidtype &&
      this.prepaidtype.value &&
      ((this.prepaidtype.value as any)?.value === "SCHEDULE" ||
        this.prepaidtype.value === "SCHEDULE") &&
      this.lineHasPrepaidFlag &&
      this.documentType == "Invoice"
    );
  }

  private get shouldDisplayInstallments() {
    return (
      this.prepaidtype &&
      this.prepaidtype.value &&
      ((this.prepaidtype.value as any)?.value === "SCHEDULE" ||
        this.prepaidtype.value === "SCHEDULE") &&
      this.lineHasPrepaidFlag &&
      this.documentType == "Invoice"
    );
  }

  private get shouldDisplayOverridePO() {
    return true;
  }

  async onTaxOptionValueChange() {
    // eslint-disable-next-line
    if (this.taxoption?.value!["value"] != null && this.isTaxButtonActive) {
      const loadTaxModalApiResponse = await updateTaxService.get(
        this.$route.params.id,
        this.taxoption.value["value"]
      );
      this.setTaxTable(loadTaxModalApiResponse["taxes"]);
      this.setWithholdingTaxTable(loadTaxModalApiResponse["withholdingTaxes"]);
    }
    // clear tax codes if Enter Tax Due to Supplier optoin is selected to prevent workday rejection
    if (
      this.taxoption.value &&
      this.taxoption.value["value"] === TAX_OPTION.ENTER_TAX_DUE_TO_SUPPLIER
    ) {
      this.$emit("clearLineTaxCodes");
    }
    await this.validateFieldValue("taxoption");
    this.setFieldOrder();
  }

  initializeDocumentEntryValuesInDocumentRulesStore() {
    this.documentRulesStore.setSupplierSelectedOnDocumentEntry(this.supplier);
    this.documentRulesStore.setCompanySelectedOnDocumentEntry(this.company);
    this.documentRulesStore.setCustomerAcctSelectedOnDocumentEntry(
      this.customeracct
    );
    // initialize document rule details
    this.documentRulesStore.setInitialDocumentRuleHeaderFields();
  }

  // watcher to emit updated invoice total to parent component
  @Watch("additionalChargesTotal")
  onAdditionalChargesTotalChange() {
    this.$emit("totalChanged", this.additionalChargesTotal);
  }

  // watchers for every field that has a look-up
  @Watch("company.search", { deep: true })
  async onCompanySearchChange() {
    if (this.company.search && this.mustPerformLookup(this.company)) {
      this.resetLookupPageOptions("company");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("company");
      }, 700);
    } else {
      this.company.options = [];
    }
  }

  @Watch("supplier.search", { deep: true })
  async onSupplierSearchChange() {
    if (this.supplier.search && this.mustPerformLookup(this.supplier)) {
      this.resetLookupPageOptions("supplier");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("supplier");
      }, 700);
    } else {
      this.supplier.options = [];
    }
  }

  @Watch("ponum.search", { deep: true })
  async onPoNumberSearchChange() {
    if (this.ponum.search && this.mustPerformLookup(this.ponum)) {
      this.resetLookupPageOptions("ponum");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupPoNumber();
      }, 700);
    } else {
      this.ponum.options = [];
    }
  }

  @Watch("remitto.search", { deep: true })
  async onRemitToSearchChange() {
    if (this.remitto.search && this.mustPerformLookup(this.remitto)) {
      this.resetLookupPageOptions("remitto");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("remitto");
      }, 700);
    } else {
      this.remitto.options = [];
    }
  }

  @Watch("currency.search", { deep: true })
  async onCurrencySearchChange() {
    if (this.currency.search && this.mustPerformLookup(this.currency)) {
      this.resetLookupPageOptions("currency");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("currency");
      }, 700);
    } else {
      this.currency.options = [];
    }
  }

  @Watch("approver.search", { deep: true })
  async onApproverSearchChange() {
    if (this.approver.search && this.mustPerformLookup(this.approver)) {
      this.resetLookupPageOptions("approver");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("approver");
      }, 700);
    } else {
      this.approver.options = [];
    }
  }

  @Watch("paymentterms.search", { deep: true })
  async onPaymentTermsSearchChange() {
    if (this.paymentterms.search && this.mustPerformLookup(this.paymentterms)) {
      this.resetLookupPageOptions("paymentterms");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("paymentterms");
      }, 700);
    } else {
      this.paymentterms.options = [];
    }
  }

  @Watch("frequencybehavior.search", { deep: true })
  async onFrequencyBehaviorSearchChange() {
    if (
      this.frequencybehavior.search &&
      this.mustPerformLookup(this.frequencybehavior)
    ) {
      this.resetLookupPageOptions("frequencybehavior");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("frequencybehavior");
      }, 700);
    } else {
      this.frequencybehavior.options = [];
    }
  }

  @Watch("paymenttype.search", { deep: true })
  async onPaymentTypeSearchChange() {
    if (this.paymenttype.search && this.mustPerformLookup(this.paymenttype)) {
      this.resetLookupPageOptions("paymenttype");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("paymenttype");
      }, 700);
    } else {
      this.paymenttype.options = [];
    }
  }

  @Watch("suppliercontract.search", { deep: true })
  async onSupplierContractSearchChange() {
    if (
      this.suppliercontract.search &&
      this.mustPerformLookup(this.suppliercontract)
    ) {
      this.resetLookupPageOptions("suppliercontract");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("suppliercontract");
      }, 700);
    } else {
      this.suppliercontract.options = [];
    }
  }

  @Watch("referenceinvoice.search", { deep: true })
  async onReferenceInvoiceSearchChange() {
    if (
      this.referenceinvoice.search &&
      this.mustPerformLookup(this.referenceinvoice)
    ) {
      this.resetLookupPageOptions("referenceinvoice");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("referenceinvoice");
      }, 700);
    } else {
      this.referenceinvoice.options = [];
    }
  }

  @Watch("requester.search", { deep: true })
  async onRequesterSearchChange() {
    if (this.requester.search && this.mustPerformLookup(this.requester)) {
      this.resetLookupPageOptions("requester");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("requester");
      }, 700);
    } else {
      this.requester.options = [];
    }
  }

  @Watch("shipto.search", { deep: true })
  async onShiptoSearchChange() {
    if (this.shipto.search && this.mustPerformLookup(this.shipto)) {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("shipto");
      }, 700);
    } else {
      this.shipto.options = [];
    }
  }

  @Watch("handlingcode.search", { deep: true })
  async onHandlingCodeSearchChange() {
    if (this.handlingcode.search && this.mustPerformLookup(this.handlingcode)) {
      this.resetLookupPageOptions("handlingcode");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("handlingcode");
      }, 700);
    } else {
      this.handlingcode.options = [];
    }
  }

  @Watch("referencetype.search", { deep: true })
  async onReferenceTypeChange() {
    if (
      this.referencetype.search &&
      this.mustPerformLookup(this.referencetype)
    ) {
      this.resetLookupPageOptions("referencetype");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("referencetype");
      }, 700);
    } else {
      this.referencetype.options = [];
    }
  }

  @Watch("invoicereferencetype.search", { deep: true })
  async onInvoiceReferenceTypeChange() {
    if (
      this.invoicereferencetype.search &&
      this.mustPerformLookup(this.invoicereferencetype)
    ) {
      this.resetLookupPageOptions("invoicereferencetype");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsOnSearch("invoicereferencetype");
      }, 700);
    } else {
      this.invoicereferencetype.options = [];
    }
  }

  @Watch("taxcode.search", { deep: true })
  async onTaxCodeChange() {
    if (this.taxcode.search && this.mustPerformLookup(this.taxcode)) {
      this.resetLookupPageOptions("taxcode");
      clearTimeout(this._timerId);
      this._timerId = setTimeout(async () => {
        await this.lookupOptionsWithInvIDOnSearch("taxcode");
      }, 700);
    } else {
      this.taxcode.options = [];
    }
  }

  // watch for prop initialFieldValues to be set
  @Watch("initialFieldValues", { deep: true })
  async onInitialFieldValuesChange(newValue: any[]) {
    newValue.forEach((field: DocumentField) => {
      // normalize string
      const fieldName = field.name.toLowerCase();
      const isNullOrEmpty = field.value == null || field.value == "";

      // find matching data property
      if (this.$data[fieldName]) {
        // don't replace hardcoded options for taxoption
        if (
          Object.prototype.hasOwnProperty.call(
            this.$data[fieldName],
            "options"
          ) &&
          fieldName !== "taxoption" &&
          fieldName !== "prepaidtype"
        ) {
          this.$data[fieldName].options = [
            {
              description: field.text,
              displayValue: field.text,
              id: field.value
            }
          ];
        }
        // set initial values for field
        this.$data[fieldName].required = field.isRequired;
        this.$data[fieldName].isValid = field.isValid;
        this.$data[fieldName].hasError = !field.isValid;
        this.$data[fieldName].isVisible = field.isVisible;
        if (
          [
            "freightamount",
            "otheramount",
            "controltotal",
            "taxamount"
          ].includes(fieldName)
        ) {
          this.$data[fieldName].value =
            field.value === ""
              ? null
              : new Decimal(field.value).toFixed(getDecimals(field.value));
        } else if (fieldName != "prepaidtype") {
          this.$data[fieldName].value = !isNullOrEmpty ? field.value : null;
        }
        if (fieldName == "prepaidtype") {
          this.$data[fieldName].value = isNullOrEmpty
            ? this.$data[fieldName].value
            : field.value;
          field.options?.forEach(el => {
            this.$data[fieldName].options?.push({
              text: el["prepayment_Release_Type_Name"],
              value: el["prepayment_Release_Type_ID"]
            });
          });
        }
        // weird workaround required to set value on select input
        if (fieldName == "taxoption") {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.taxoption.value = this.taxoption.options!.find(
            (item: InputObject) => item.value === parseInt(field.value)
          );
        }
        if (fieldName == "adjustmentreason") {
          this.getAdjustmentReasonOptions(fieldName);
          this.adjustmentreason.value = this.adjustmentreason.options?.find(
            (item: { [key: string]: string }) => item.id === field.value
          );
        }
        // only set placeholder if class property has placeholder key
        if (
          Object.prototype.hasOwnProperty.call(
            this.$data[fieldName],
            "placeholder"
          )
        ) {
          this.$data[fieldName].placeholder = field.placeholder;
        }
      }
    });

    this.initializeDocumentEntryValuesInDocumentRulesStore();
  }

  @Watch("taxoption.value", { deep: true })
  isTaxAmountReadOnly() {
    if (
      this.taxoption.value &&
      (this.taxoption.value["value"] ===
        TAX_OPTION.CALCULATE_SELF_ASSESSED_TAX ||
        this.taxoption.value["value"] ===
          TAX_OPTION.CALCULATE_TAX_DUE_TO_SUPPLIER ||
        this.taxoption.value["value"] === TAX_OPTION.NONE)
    ) {
      this.taxamount.isReadOnly = true;
    } else {
      this.taxamount.isReadOnly = false;
    }
  }

  @Watch("taxAmountValueFromLine", { deep: true })
  async ontaxAmountValueFromLineChange() {
    if (this.taxAmountValueFromLine) {
      this.taxamount.value = new Decimal(this.taxAmountValueFromLine)
        .toDecimalPlaces(2)
        .toString();
    }
  }

  @Watch("withholdingTaxValueFromLine", { deep: true })
  async onWithholdingTaxAmountValueFromLineChange() {
    this.withholdingtax.value = new Decimal(this.withholdingTaxValueFromLine)
      .toDecimalPlaces(2)
      .toString();
  }

  @Watch("taxoptionsTable", { deep: true })
  getTaxOptionsTotals() {
    let sum = new Decimal("0");
    this.taxoptionsTable.items.forEach(
      (taxElement: { id: number; taxAmount: number }) => {
        if ("taxAmount" in taxElement && taxElement["taxAmount"]) {
          const ifStringNumber =
            typeof taxElement["taxAmount"] === "string"
              ? new Decimal(taxElement["taxAmount"])
              : new Decimal(taxElement["taxAmount"].toString());
          sum = sum.plus(new Decimal(ifStringNumber.toString()));
        }
      }
    );
    this.transTaxTotal = sum;
    this.onTaxAmountValueChange(
      sum.toString(),
      this.taxamount.value?.toString() ?? ""
    );
  }

  @Watch("withholdingTaxoptionsTable", { deep: true })
  getWithholdingTaxOptionsTotals() {
    let sum = new Decimal("0");
    this.withholdingTaxoptionsTable.items.forEach(
      (taxElement: { id: number; taxAmount: number }) => {
        if ("taxAmount" in taxElement && taxElement["taxAmount"]) {
          const ifStringNumber =
            typeof taxElement["taxAmount"] === "string"
              ? new Decimal(taxElement["taxAmount"])
              : new Decimal(taxElement["taxAmount"].toString());
          sum = sum.plus(new Decimal(ifStringNumber.toString()));
        }
      }
    );
    this.withholdingTaxTotal = sum;
  }

  @Watch("controltotal.value", { deep: true })
  onControlTotalChange() {
    this.$emit(
      "controlTotalChanged",
      this.controltotal.value
        ? new Decimal(this.controltotal.value.toString())
        : new Decimal("0")
    );
  }

  @Watch("ponum.value", { deep: true })
  @Watch("suppliercontract.value", { deep: true })
  fireNotifyPoNumOrSupplierContractValidity() {
    const isValid =
      this.isValidatableFieldValid(this.ponum) ||
      this.isValidatableFieldValid(this.suppliercontract);
    this.$emit("isPoNumOrSupplierContractValid", isValid);
  }

  private isValidatableFieldValid(field: ValidatableField): boolean {
    return field.isValid && (field.value as string)?.length > 0;
  }

  @Watch("invoiceid.value", { deep: true })
  updateInvoiceId() {
    this.$emit("invoiceIdChanged", this.invoiceid.value);
  }

  @Watch("validateFieldRequest", { deep: true })
  handleExternaValidationFieldRequest() {
    if (this.validateFieldRequest) {
      this.$data[
        this.validateFieldRequest.field
      ].value = this.validateFieldRequest.value.selected.id;
      this.$data[this.validateFieldRequest.field].isValid = true;
      this.$data[this.validateFieldRequest.field].hasError = false;
      this.$data[this.validateFieldRequest.field].options = [
        {
          description: this.validateFieldRequest.value.selected.text,
          displayValue: this.validateFieldRequest.value.selected.text,
          id: this.validateFieldRequest.value.selected.id
        }
      ];
      // Force Field validation
      this.validateFieldValue(this.validateFieldRequest.field);
    }
  }

  @Watch("documentType", { deep: true })
  setFieldOrder() {
    this.$nextTick(() => {
      // extract array of inputs from Vue components in form
      this.fieldOrder = (this.$refs.form as Vue).$data.inputs
        .map((input: Vue) => input.$refs.input)
        // filter out fields that are not visible or not editable
        .filter(
          (field: HTMLInputElement) =>
            field.clientHeight > 0 && field.tabIndex >= 0
        );
      // if upadate tax button is visible, add it to the array of tabstops
      if (this.$refs.updatetax) {
        const indexOfTaxOption = this.fieldOrder.indexOf(
          (this.$refs.taxoption as Vue).$refs.input as HTMLInputElement
        );
        this.fieldOrder.splice(
          indexOfTaxOption + 1,
          0,
          (this.$refs.updatetax as Vue)?.$el as HTMLInputElement
        );
      }
    });
  }

  @Watch("fieldValidationRequestedFromInvLine")
  async validateFieldRequestedByLine() {
    if (
      this.fieldValidationRequestedFromInvLine &&
      this.$data[this.fieldValidationRequestedFromInvLine]
    ) {
      await this.validateFieldValue(this.fieldValidationRequestedFromInvLine);
    }
  }

  // the following 3 watchers are used for populating initial values in a new document rule
  // the supplier watcher also emits validity to determine supplier worktags button visibility
  @Watch("supplier.value", { deep: true })
  storeSupplierValueForDocumentRules() {
    this.documentRulesStore.setSupplierSelectedOnDocumentEntry(
      cloneDeep(this.supplier)
    );
    const isValid = this.isValidatableFieldValid(this.supplier);
    this.$emit("isSupplierValid", isValid);
  }

  @Watch("company.value", { deep: true })
  storeCompanyValueForDocumentRules() {
    this.documentRulesStore.setCompanySelectedOnDocumentEntry(
      cloneDeep(this.company)
    );
  }

  @Watch("customeracct.value", { deep: true })
  storeCustomerAccountValueForDocumentRules() {
    this.documentRulesStore.setCustomerAcctSelectedOnDocumentEntry(
      cloneDeep(this.customeracct)
    );
  }
}
