import { app } from "@/main";
import { MODAL_TYPE } from "@/shared/constants/on-demand-sync";

class OnDemandSyncService {
  private generateGETParameters(paramName: string, params: string) {
    const splitedParams = params.split(",");
    splitedParams[0] = `${paramName}=${splitedParams[0]}`;
    return splitedParams.reduce((a, b) => `${a}&${paramName}=${b}`);
  }

  private async syncPurchaseOrder(purchaseOrdersIDs: string) {
    return await app.$ravenapi.get(
      `api/sync/purchaseorder?${this.generateGETParameters(
        "purchaseOrderIds",
        purchaseOrdersIDs
      )}`
    );
  }

  private async syncSupplier(suppliersIDs: string) {
    return await app.$ravenapi.get(
      `api/sync/supplier?${this.generateGETParameters(
        "supplierIds",
        suppliersIDs
      )}`
    );
  }

  private async syncSupplierContract(suppliersContractsIDs: string) {
    return await app.$ravenapi.get(
      `api/sync/suppliercontract?${this.generateGETParameters(
        "supplierContractIds",
        suppliersContractsIDs
      )}`
    );
  }

  public async getOnDemandSyncData(modalType: string, data: string) {
    switch (modalType) {
      case MODAL_TYPE.PO_NUM:
        return await this.syncPurchaseOrder(data);
      case MODAL_TYPE.SUPPLIER:
        return await this.syncSupplier(data);
      case MODAL_TYPE.SUPPLIER_CONTRACT:
        return await this.syncSupplierContract(data);
      default:
        return null;
    }
  }
}

export const onDemandSyncService = new OnDemandSyncService(); // singleton
