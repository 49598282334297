export class RavenCreateCommentOnInvoiceRequest {
  public invID: number;
  public comment: string;
  public userFirstName: string;
  public userLastName: string;

  constructor(
    invoiceID: number,
    comment: string,
    userFirstName: string,
    userLastName: string
  ) {
    this.invID = invoiceID;
    this.comment = comment;
    this.userFirstName = userFirstName;
    this.userLastName = userLastName;
  }
}
