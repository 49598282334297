


































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import { focusField } from "@/helpers/input-helpers";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class TextInput extends Vue {
  @Prop() lineSequenceNumber!: number;
  @Prop() focusElementRefName!: string;
  @Prop() isReadOnly!: boolean;
  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() name!: string;
  @Prop() value!: string;
  @Prop() isRequired!: boolean;
  @Prop() isValid!: boolean;
  @Prop() isError!: boolean;
  @Prop() errorMessage?: string;
  @Prop() isVisible!: boolean;
  @Prop() isEditable!: boolean;
  private currentValue: string = this.value || "";

  handleTabKeypress(event: any) {
    if (event.shiftKey) {
      this.$emit("tabToPreviousField");
    } else {
      this.$emit("tabToNextField");
    }
  }

  @Watch("value")
  onValuePropChange() {
    this.currentValue = this.value;
  }

  @Watch("focusElementRefName")
  onFocusElementChange() {
    focusField(this);
  }

  mounted() {
    focusField(this);
  }
}
