












































































































































































































































// Custom Components
import IconTextButton from "@/components/design-system/buttons/IconTextButton.vue";
import IconTooltip from "@/components/design-system/icons/IconTooltip.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import NumericInput from "@/components/design-system/inputs/NumericInput.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";

// Helpers
import { addDecimalsToString } from "@/helpers/string-formatting";
import { randomNumberGenerator, SafeMath } from "@/helpers/numbers-helpers";

// Models
import {
  POLineDetail,
  POLinePairingDetailsResponse,
  UpdateInvoiceLinesRequest
} from "@/models/document-entry/po-line-pairing";

// Services
import { poLineDetailsService } from "@/services/po-line-details.service";

// Shared
import options from "@/shared/constants/toast-options";
import { PO_LINES_TYPE } from "@/shared/constants/po-line-details";

// Third party
import { cloneDeep, debounce } from "lodash";

import { Component, Prop, Watch } from "vue-property-decorator";
import Draggable from "vuedraggable";

@Component({
  components: {
    draggable: Draggable,
    "icon-tootlip": IconTooltip,
    "icon-text-button": IconTextButton,
    "icon-button": IconButton,
    "primary-button": PrimaryButton,
    "numeric-input": NumericInput
  }
})
export default class POLineDetailsTray extends UserRolesMixin {
  @Prop() visible?: boolean;
  @Prop() selectedDocumentInvoiceId!: string;

  private sharedInvoiceLineTableHeaders = [
    {
      text: "Supplier Item ID",
      value: "supplierItemID",
      align: "start",
      sortable: false
    },
    {
      text: "Business Document",
      value: "businessDocumentText",
      align: "start",
      sortable: false
    },
    {
      text: "Type",
      value: "lineType",
      align: "start",
      sortable: false
    },
    {
      text: "Item",
      value: "itemName",
      align: "start",
      sortable: false
    },
    {
      text: "Item Description",
      value: "itemDescription",
      align: "start",
      sortable: false
    },
    {
      text: "UOM",
      value: "unitOfMeasure",
      align: "start",
      sortable: false
    },
    {
      text: "Quantity",
      value: "quantity",
      align: "start",
      sortable: false,
      cellClass: "right-text"
    },
    {
      text: "Unit Cost",
      value: "unitCost",
      align: "start",
      sortable: false,
      cellClass: "right-text"
    },
    {
      text: "Extended Amount",
      value: "extendedAmount",
      align: "start",
      sortable: false,
      cellClass: "right-text"
    }
  ];
  private currentInvoiceLineTableHeaders = [
    {
      align: "center",
      value: "select",
      sortable: false
    },
    {
      text: "Invoice Line Order",
      align: "start",
      value: "priority",
      sortable: false
    },
    ...this.sharedInvoiceLineTableHeaders
  ];
  private availableLineTableHeaders = [
    {
      align: "center",
      value: "select",
      sortable: false
    },
    ...this.sharedInvoiceLineTableHeaders
  ];

  private poNumber = "";
  private supplierContractNumber = "";
  private lineDetailsHeader = "";
  private availableLinesHeader = "";
  private addedLines: POLineDetail[] = [];
  private addedLinesInitialState: POLineDetail[] = [];
  private availableLines: POLineDetail[] = [];
  private availableLinesInitialState: POLineDetail[] = [];
  private vanishedLinesFromTheUI: POLineDetail[] = [];

  private initialState = "";
  private forceAddedLinesTableReload = randomNumberGenerator();
  private forceAvailableLinesTableReload = randomNumberGenerator();
  private isUpdateLinesButtonDisabled = true;
  private isClosingDisabled = false;
  private isGoodsLine = PO_LINES_TYPE.GOODS;
  private isServiceLine = PO_LINES_TYPE.SERVICE;
  private inputKey = randomNumberGenerator();

  // Create a debounced version of the updateLines method
  debouncedUpdateLines = debounce(this.handleLineUpdates, 400); // Adjust the delay (in milliseconds) as needed

  createStringFromItems(items: POLineDetail[]): string {
    const clonedItems = cloneDeep(items);
    clonedItems.forEach((line: POLineDetail) => delete line.selected);
    return JSON.stringify(clonedItems);
  }

  formatAndGetLineType(item: any): string {
    if (!item.isPoLine) {
      return "";
    }
    if (item.lineType == this.isGoodsLine) {
      return "Goods";
    } else if (item.lineType == this.isServiceLine) {
      return "Service";
    } else {
      return "";
    }
  }

  formatExtendedAmountString(item: POLineDetail): string {
    return addDecimalsToString(item.extendedAmount.toString());
  }

  async updateField(event: any, item: POLineDetail, fieldName: string) {
    let newValue: string | number | null = null;
    const isUnitCostBeingModified = fieldName === "unitCost";
    const isQuantityBeingModified = fieldName === "quantity";
    if (
      isUnitCostBeingModified ||
      isQuantityBeingModified ||
      fieldName === "extendedAmount"
    ) {
      const decimals = isUnitCostBeingModified ? 6 : 2;
      newValue = addDecimalsToString(event.target.value, decimals);
    }

    if (newValue && newValue !== item[fieldName]) {
      item[fieldName] = newValue;

      if (isQuantityBeingModified || isUnitCostBeingModified) {
        const floatQty = item.quantity;
        const floatUnitCost = item.unitCost;
        const extendedAmount = SafeMath.safeMul(item.quantity, item.unitCost);
        item.extendedAmount = parseFloat(
          addDecimalsToString(extendedAmount.toString())
        );
      }
    }
    this.forceAddedLinesTableReload = randomNumberGenerator();
  }

  private updateLinesPriority() {
    this.addedLines.forEach((line, index) => (line.priority = index + 1));
  }

  removeAllLines() {
    const linesToRemoveFromAddedLines = this.addedLines;
    // Update the addedLines array with not selected lines
    this.addedLines = [];
    this.reformatLinesToRemove(linesToRemoveFromAddedLines);
  }

  removeSelectedLine(itemToRemove: POLineDetail) {
    // Line marked from added line is a line selected to be removed
    const linesToRemoveFromAddedLines = this.addedLines.filter(
      item => item.id == itemToRemove.id
    );
    // Update the addedLines array with not selected lines
    this.addedLines = this.addedLines.filter(
      item => item.id != itemToRemove.id
    );
    this.reformatLinesToRemove(linesToRemoveFromAddedLines);
  }

  reformatLinesToRemove(linesToRemoveFromAddedLines: POLineDetail[]) {
    linesToRemoveFromAddedLines.forEach(lineToRemove => {
      let originalLine: POLineDetail | undefined = undefined;

      if (lineToRemove.isPoLine && !lineToRemove.isExistingLine) {
        // get the original line (whitout modifications) from available lines
        originalLine = this.availableLinesInitialState.filter(
          line => line.id == lineToRemove.id
        )[0];
      } else if (lineToRemove.isPoLine && lineToRemove.isExistingLine) {
        // get the original line (whitout modifications) from added lines
        originalLine = this.addedLinesInitialState.filter(
          line => line.id == lineToRemove.id
        )[0];

        this.vanishedLinesFromTheUI.push(originalLine);
      } else if (!lineToRemove.isPoLine && lineToRemove.isExistingLine) {
        // get the original line (whitout modifications) from added lines
        const originalLineToBeRemoved = this.addedLinesInitialState.filter(
          line => line.id == lineToRemove.id
        )[0];
        this.vanishedLinesFromTheUI.push(originalLineToBeRemoved);
      }
      if (originalLine) this.availableLines.push(originalLine);
      // refresh inputs
      this.inputKey++;
    });

    this.updateLinesPriority();
  }

  addAllAvailableLines() {
    this.availableLines.forEach(selectedLine => {
      // try to get the original line from availableLinesInitialState
      let originalLine:
        | POLineDetail
        | undefined = this.availableLinesInitialState.filter(line => {
        return line.id == selectedLine.id;
      })[0];
      // if filtering line from availableLinesInitialState fails, then
      // the original lines belongs to addedLinesInitialState
      if (!originalLine) {
        originalLine = this.addedLinesInitialState.filter(line => {
          return line.id == selectedLine.id;
        })[0];
      }
      this.addedLines.push({
        ...originalLine
      });
      if (selectedLine.isPoLine && selectedLine.isExistingLine) {
        this.vanishedLinesFromTheUI = this.vanishedLinesFromTheUI.filter(
          line => line.id != selectedLine.id
        );
      }
    });
    this.updateLinesPriority();
    this.availableLines = [];
  }

  addLine(itemToAdd: POLineDetail) {
    let originalLine:
      | POLineDetail
      | undefined = this.availableLinesInitialState.filter(line => {
      return line.id == itemToAdd.id;
    })[0];

    if (!originalLine) {
      originalLine = this.addedLinesInitialState.filter(line => {
        return line.id == itemToAdd.id;
      })[0];
    }

    this.addedLines.push({
      ...originalLine
    });

    this.availableLines = this.availableLines.filter(
      line => line.id != itemToAdd.id
    );
    if (itemToAdd.isPoLine && itemToAdd.isExistingLine) {
      this.vanishedLinesFromTheUI = this.vanishedLinesFromTheUI.filter(
        line => line.id != itemToAdd.id
      );
    }

    this.updateLinesPriority();
  }

  async updateLines() {
    this.isUpdateLinesButtonDisabled = true;
    await this.debouncedUpdateLines();
  }

  async handleLineUpdates() {
    if (this.isUpdateLinesButtonDisabled) {
      this.isClosingDisabled = true;
      this.handleServiceLinesValues();
      const request: UpdateInvoiceLinesRequest = new UpdateInvoiceLinesRequest(
        this.$route.params.id,
        this.addedLines,
        this.vanishedLinesFromTheUI
      );
      const apiResponse: any = await poLineDetailsService.updateAllLines(
        request
      );
      if (apiResponse.lines) {
        this.handleResponseMessage();
        this.$emit("handleUpdateInvoiceLines", apiResponse.lines);
      } else {
        this.handleErrorResponseMessage();
      }
      this.isClosingDisabled = false;
      this.closeSheet();
    }
  }

  handleServiceLinesValues() {
    const resetQuantityAndCost = (line: POLineDetail) => {
      line.quantity = 0;
      line.unitCost = 0;
    };

    this.addedLines.forEach(line => {
      if (line.lineType == this.isServiceLine.toString()) {
        resetQuantityAndCost(line);
      }
    });

    this.vanishedLinesFromTheUI.forEach(line => {
      if (line.lineType == this.isServiceLine.toString()) {
        resetQuantityAndCost(line);
      }
    });
  }

  handleResponseMessage() {
    this.$toasted.show("<p>Lines updated</p>", options.SUCCESS_OPTIONS);
  }

  handleErrorResponseMessage() {
    this.$toasted.show("<p>Error updating lines</p>", options.ERROR_OPTIONS);
  }

  resetInitialValues() {
    this.poNumber = "";
    this.supplierContractNumber = "";
    this.addedLines = [];
    this.addedLinesInitialState = [];
    this.availableLines = [];
    this.availableLinesInitialState = [];
    this.vanishedLinesFromTheUI = [];
    this.initialState = "";
    this.updateAvailableLinesHeader();
  }

  closeSheet() {
    this.$emit("handle-po-line-details-tray");
    this.resetInitialValues();
  }

  // Watch methods
  @Watch("addedLines", { deep: true })
  updateUpdateLinesButton() {
    const currentState = this.createStringFromItems(this.addedLines);
    this.isUpdateLinesButtonDisabled = this.initialState == currentState;
  }
  private convertValuesToDecimal(lines) {
    lines.forEach(line => {
      if (line.lineType == this.isServiceLine) {
        line.quantity = null;
        line.unitCost = null;
      } else {
        const qty = line.quantity;
        const uCost = line.unitCost;
        line.quantity = addDecimalsToString(qty.toString());
        line.unitCost = addDecimalsToString(uCost.toString(), 6);
      }
      const exAm = line.extendedAmount;
      line.extendedAmount = addDecimalsToString(exAm.toString());
    });
    return lines;
  }

  private updateAvailableLinesHeader() {
    if (this.poNumber?.length > 0 && this.supplierContractNumber?.length > 0) {
      this.lineDetailsHeader = `Line Details for Purchase Order ${this.poNumber} and Supplier Contract ${this.supplierContractNumber}`;
      this.availableLinesHeader = `Available Lines from Purchase Order and Supplier Contract`;
    } else if (this.poNumber?.length > 0) {
      this.lineDetailsHeader = `Line Details for Purchase Order ${this.poNumber}`;
      this.availableLinesHeader = `Available Lines from the Purchase Order`;
    } else if (this.supplierContractNumber?.length > 0) {
      this.lineDetailsHeader = `Line Details for Supplier Contract ${this.supplierContractNumber}`;
      this.availableLinesHeader = `Available Lines from the Supplier Contract`;
    }
  }

  @Watch("visible")
  async onVisibilityChanged() {
    if (this.visible) {
      const apiResponse: POLinePairingDetailsResponse = await poLineDetailsService.get(
        this.$route.params.id
      );

      this.poNumber = apiResponse.poNumber;
      this.supplierContractNumber = apiResponse.supplierContractNumber;
      this.updateAvailableLinesHeader();
      //Added lines
      const processedAddedLines = this.convertValuesToDecimal(
        apiResponse.addedLines
      );
      this.addedLines = cloneDeep(processedAddedLines);
      this.addedLinesInitialState = cloneDeep(processedAddedLines);
      //Available lines
      const processedAvailableLines = this.convertValuesToDecimal(
        apiResponse.availableLines
      );
      this.availableLines = cloneDeep(processedAvailableLines);
      this.availableLinesInitialState = cloneDeep(processedAvailableLines);
      //
      this.initialState = this.createStringFromItems(processedAddedLines);
      this.vanishedLinesFromTheUI = [];
    }
  }
}
