import { app } from "@/main";

class InvoiceAutocodingService {
  public async extractPreviousLines(invID: number) {
    const apiResponse = await app.$ravenapi.put(
      `api/autocoding/previous?invID=${invID}`
    );
    return apiResponse;
  }

  public async applyDocumentRuleToInvoice(invID: number) {
    return await app.$ravenapi.put(`/api/autocoding/prototype?invID=${invID}`);
  }
}

export const invoiceAutocodingService = new InvoiceAutocodingService(); // singleton
