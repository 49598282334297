















import { Vue, Prop, Component } from "vue-property-decorator";

@Component
export default class ParagraphTextInput extends Vue {
  // props
  @Prop({ default: "" }) value!: string;
  @Prop({ default: 10 }) editRows!: number;
  @Prop({ default: 1000 }) maxLength!: number;
  @Prop() valueChangedParentHandler!: Function;
  @Prop({ default: false }) isReadOnly?: boolean;
  @Prop({ default: "" }) placeHolder?: string;

  // reactive class properties
  private editValue = "";
  private editValueRules = [
    v =>
      (v && v.length > 0 && v.length <= this.maxLength) ||
      `Comment must be non-empty and less than ${this.maxLength} characters`
  ];

  // lifecycle hooks
  mounted() {
    this.editValue = this.value;
  }

  // methods
  resetEditValue() {
    this.editValue = "";
  }

  editValueChanged(value: string) {
    this.valueChangedParentHandler(value);
  }

  setDefaultEditValue(value: string) {
    this.editValue = value;
  }
}
