





































import { Prop, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import ParagraphTextInput from "@/components/design-system/inputs/ParagraphTextInput.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import InputLabel from "@/components/forms/InputLabel.vue";
import { documentCommentsService } from "@/services/document-comments.service";
import DocumentCommentsStore from "@/store/document-comments.store";
import { comment } from "@/models/document-entry/comment";
import { RavenCreateCommentOnInvoiceRequest } from "@/models/external-services/raven-api-create-comment-on-invoice-request";
import options from "@/shared/constants/toast-options";

@Component({
  components: {
    InputLabel,
    "paragraph-text-input": ParagraphTextInput,
    "submit-button": PrimaryButton,
    "cancel-button": SecondaryButton
  }
})
export default class AddCommentCardForm extends UserRolesMixin {
  // props

  @Prop() isFormDisplayed!: boolean;
  @Prop() closeFormParentHandler!: Function;

  // readonly store module
  private readonly documentCommentsStore: DocumentCommentsStore = getModule(
    DocumentCommentsStore,
    this.$store
  );

  // reactive class properties

  private commentValue = "";
  private valid = false;

  // lifecycle hooks

  mounted() {
    this.commentValue = "";
    this.editorSetDefaultTextValue("");
  }

  // methods

  commentValueChangedHandler(value: string) {
    this.commentValue = value;
  }

  editorResetValue() {
    if (this.$refs.editor) {
      const editor: any = this.$refs.editor;
      const fn = editor?.resetEditValue;
      if (fn) {
        fn();
      }
    }
  }

  editorSetDefaultTextValue(commentValue) {
    if (this.$refs.editor) {
      const editor: any = this.$refs.editor;
      const fn = editor?.setDefaultEditValue;
      if (fn) {
        fn(commentValue);
      }
    }
  }

  async saveNewCommentHandler() {
    const documentComment = {
      commentID: 0,
      commentOwnerEmail: this.userEmailAddress,
      commentOwnerFirstName: this.userFirstName,
      commentOwnerLastName: this.userLastName,
      commentDate: new Date(),
      commentText: this.commentValue
    };

    this.commentValue = "";

    await this.saveNewCommentToDatabase(documentComment);
  }

  cancelNewCommentHandler() {
    this.closeFormParentHandler();
  }

  async saveNewCommentToDatabase(data: comment) {
    const request = new RavenCreateCommentOnInvoiceRequest(
      parseInt(this.$route.params.id),
      data.commentText,
      data.commentOwnerFirstName,
      data.commentOwnerLastName
    );

    const response = await documentCommentsService.createDocumentCommentAsync(
      request
    );

    if ((response as any).commentID) {
      this.$set(data, "commentID", (response as any).commentID ?? 0);
      this.documentCommentsStore.addDocumentComment(data);

      this.closeFormParentHandler();

      this.$toasted.show("<p>Invoice comment successfully saved</p>", {
        ...options.SUCCESS_OPTIONS
      });
    } else {
      this.$toasted.show("<p>Error occurred while saving new comment</p>", {
        ...options.ERROR_OPTIONS
      });
    }
  }

  // watchers

  @Watch("isFormDisplayed") onFormShowing() {
    if (this.isFormDisplayed) {
      this.valid = false;
      this.editorResetValue();
    }
  }
}
