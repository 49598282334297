







































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import DocumentRuleMixin from "@/mixins/DocumentRuleMixin.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import DocumentRuleDetailsStep from "./DocumentRuleDetailsStep.vue";
import DocumentRuleHeaderFieldsStep from "./DocumentRuleHeaderFieldsStep.vue";
import DocumentRuleLineFieldsStep from "./DocumentRuleLineFieldsStep.vue";
import { documentRulesService } from "@/services/document-rules.service";
import { DocumentRuleAutocompleteInput } from "@/models/document-entry/document-rule-inputs";
import {
  DocumentRuleModalLaunchMode,
  DocumentRuleModalMode
} from "@/models/document-entry/document-rules";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "secondary-button": SecondaryButton,
    "simple-confirmation-modal": SimpleConfirmationModal,
    "document-rule-details-step": DocumentRuleDetailsStep,
    "document-rule-header-fields": DocumentRuleHeaderFieldsStep,
    "document-rule-line-fields": DocumentRuleLineFieldsStep
  }
})
export default class DocumentRule extends DocumentRuleMixin {
  // Props, passed from DocumentEntry.vue or (in future) a DocumentRulesList view
  @Prop() isReadOnly!: boolean;
  @Prop() isDocumentRuleModalDisplayed!: boolean;
  @Prop() closeDocumentRuleModal!: Function;
  @Prop() modalLaunchMode!: DocumentRuleModalLaunchMode;

  // reactive properties
  private loadingInitialValues = false;
  private isDeleteRuleButtonDisabled = false;

  // computed properties
  private get supplierDisplayName(): string {
    const supplier: DocumentRuleAutocompleteInput = this.documentRulesStore
      .getDocumentRuleHeaderFields?.supplier;

    const supplierName =
      supplier?.options?.find(option => option.id === supplier?.value)
        ?.displayValue ?? "";
    const supplierid = ((supplier?.value as string) ?? "").includes("|")
      ? (supplier?.value as string)?.substring(
          0,
          (supplier?.value as string)?.indexOf("|")
        )
      : supplier?.value ?? "";
    return supplierName + " (" + supplierid + ")";
  }

  private get openedModalPanels(): number[] {
    return this.documentRulesStore.getOpenedModalPanels;
  }
  private set openedModalPanels(value) {
    this.documentRulesStore.openPanelInDocumentRuleModal(value);
  }

  private get isDocumentRuleComplete(): { [key: string]: boolean } {
    return this.documentRulesStore.getIsDocumentRuleComplete;
  }

  private get documentRuleModalTitle(): string {
    return "Custom Rule for " + this.supplierDisplayName;
  }

  // methods
  async handleSaveDocumentRule() {
    await this.saveDocumentRule();
    this.closeDocumentRuleModalAndResetRuleState();
  }

  async handleDeleteDocumentRule() {
    this.isDeleteRuleButtonDisabled = true;
    await this.deleteDocumentRule();
    this.changeStateOfDeleteDocumentRuleModal();
    this.closeDocumentRuleModalAndResetRuleState();
  }

  closeDocumentRuleModalAndResetRuleState() {
    this.closeDocumentRuleModal();
    this.documentRulesStore.resetEntireDocumentRuleStoredState();
  }

  async closeDocumentRuleModalWithoutSaving() {
    await this.deleteTempPrototypeDocumentRule();
    this.closeDocumentRuleModalAndResetRuleState();
  }

  // watchers

  @Watch("isDocumentRuleModalDisplayed")
  async onModalStateChange() {
    if (this.isDocumentRuleModalDisplayed) {
      this.loadingInitialValues = true;

      if (
        this.documentRulesStore.getDocumentRuleModalMode ===
        DocumentRuleModalMode.CREATE
      ) {
        // initialize document rule details for step 1
        this.documentRulesStore.setInitialDocumentRuleHeaderFields();
      } else if (
        this.documentRulesStore.getDocumentRuleModalMode ===
        DocumentRuleModalMode.UPDATE
      ) {
        // get and set rule settings and header fields for edit mode
        const documentRuleSettings: any = await documentRulesService.getDocumentRule(
          this.documentRulesStore.getDocumentRuleID ?? 0
        );
        this.documentRulesStore.setDocumentRuleID(documentRuleSettings.invID);
        this.documentRulesStore.setHeaderFieldsForEditing({
          fields: documentRuleSettings.fields ?? [],
          shouldSetInitialSettings: true
        });
      }

      this.loadingInitialValues = false;
      this.isDeleteRuleButtonDisabled = false;
    }
  }
}
