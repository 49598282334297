




































import { Vue, Component, Prop } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class InputWrapper extends Vue {
  @Prop() isReadOnly!: boolean;
  @Prop() inputLabelText!: string;
  @Prop() required!: boolean;
  @Prop() isValid!: boolean;
  @Prop() hasError!: boolean;
  @Prop() errorMessage?: string;
  @Prop({
    default: () => {
      return { icon: "" };
    }
  })
  appendIcon?: {
    icon: string;
    handleClick: Function;
    customClass: string;
    tooltip: string;
    userHasIconPermission: boolean;
  };
}
