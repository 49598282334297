

















































































// Custom components
import DocumentRuleMixin from "@/mixins/DocumentRuleMixin.vue";
import FormGenerator from "@/components/forms/document-entry/invoice-lines/FormGenerator.vue";
import IconTextButton from "@/components/design-system/buttons/IconTextButton.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";

// Helpers
import { addDecimalsToString } from "@/helpers/string-formatting";
import { randomNumberGenerator } from "@/helpers/numbers-helpers";

// Models
import { FieldValidationRequest } from "@/models/document-entry/field-validation";
import { lineSchemaObject, line } from "@/models/document-entry/line";
import { RavenCreateLineRequest } from "@/models/external-services/raven-api-create-line-request";
import { RavenDuplicateLineRequest } from "@/models/external-services/raven-api-duplicate-line-request";

// Shared
import options from "@/shared/constants/toast-options";

// Services
import { documentDetailsService } from "@/services/document-details.service";
import { invoiceLineService } from "@/services/invoice-line.service";
import { lineLookupService } from "@/services/invoice-line-lookup.service";
import { lineValidationService } from "@/services/invoice-line-validation.service";

// Third Party
import { Component, Watch } from "vue-property-decorator";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "form-generator": FormGenerator, // this is the InvoiceLines form generator to reuse same API endpoints
    "icon-text-button": IconTextButton,
    "secondary-button": SecondaryButton
  }
})
export default class DocumentRuleLineFieldsStep extends DocumentRuleMixin {
  // reactive class properties
  private loadingLines = false;
  private lines: line[] = [];
  private _timerId!: NodeJS.Timeout;
  private currentLineItemInFocus = "";
  private refreshLinesKey = randomNumberGenerator();

  // computed properties
  private get documentRuleID(): number | null {
    return this.documentRulesStore.getDocumentRuleID;
  }

  private get isDocumentRuleComplete() {
    return this.documentRulesStore.getIsDocumentRuleComplete;
  }

  // lifecycle methods
  async mounted() {
    await this.initializeLineValues();
  }

  // methods

  nextStep() {
    this.documentRulesStore.setDocumentRuleStepAsCompleted({
      stepNumber: "three",
      isComplete: true
    });
    // open next panel in document rules modal
    this.documentRulesStore.openPanelInDocumentRuleModal(3);
  }

  async addLine() {
    if (this.documentRuleID) {
      // make API call to create new invoice line for the prototype invoice record
      const createLineResponse: any = await invoiceLineService.addNewLineAsync(
        new RavenCreateLineRequest(this.documentRuleID)
      );
      // API returns all lines for document rule prototype invoice after creating new line
      this.lines = createLineResponse.lines;
    }
  }

  async removeLine(docRuleLine: line) {
    if (this.documentRuleID) {
      const deleteLineResponse: any = await invoiceLineService.deleteLineAsync(
        this.documentRuleID,
        [docRuleLine.seqNum]
      );
      if (!deleteLineResponse.data?.errorCode) {
        // API returns all lines for invoice after deleting new line
        this.lines = deleteLineResponse.lines;
      } else {
        const errorMessage =
          deleteLineResponse.data?.errorMessage ?? "Error occurred";
        this.$toasted.show(
          `<p><strong>Error:</strong> ${errorMessage}</p>`,
          options.ERROR_OPTIONS
        );
      }
    }
  }

  async duplicateLine(docRuleLine: line) {
    if (this.documentRuleID) {
      // make API call to duplicate selected invoice line
      // API returns all lines for invoice after creating duplicate
      const duplicateLineResponse: any = await invoiceLineService.duplicateLineAsync(
        new RavenDuplicateLineRequest(this.documentRuleID, docRuleLine.seqNum)
      );
      this.lines = duplicateLineResponse.lines;
    }
  }

  async moveUpLine(docRuleLine: line) {
    if (this.documentRuleID) {
      this.loadingLines = true;
      const moveUpResponse: any = await invoiceLineService.moveUpLineAsync({
        invID: this.documentRuleID,
        seqNum: docRuleLine.seqNum
      });
      this.lines = moveUpResponse?.lines ?? this.lines;
      this.loadingLines = false;
    }
  }

  async performNewValLookup(
    schema: lineSchemaObject[],
    lookupRequest: {
      field: string;
      seqNumber: number;
      value: string | any;
    }
  ) {
    // do lookup to get options
    clearTimeout(this._timerId);
    this._timerId = setTimeout(async () => {
      const lookupResponse = await lineLookupService.loadLineLookupOptionsAsync(
        lookupRequest.field,
        this.documentRuleID ?? 0,
        lookupRequest.seqNumber,
        lookupRequest.value
      );

      if (lookupResponse) {
        const fieldSchema: lineSchemaObject | undefined = schema.find(
          (field: lineSchemaObject) => field.name === lookupRequest.field
        );

        if (fieldSchema) {
          fieldSchema.lookupResultsPageOptions = lookupResponse.metadata;
          if (lookupRequest.field === "additionalWorktags") {
            fieldSchema.options = [
              ...fieldSchema.options.filter((option: any) => {
                return (fieldSchema.value as any[]).some(selectedValue => {
                  return selectedValue.id === option.id;
                });
              }),
              ...lookupResponse.data
            ];
          } else {
            fieldSchema.options = [
              ...fieldSchema.options.filter((option: any) => {
                return option.id === fieldSchema.value;
              }),
              ...lookupResponse.data
            ];
          }
        }
      }
    }, 700);
  }

  async continueLoadingLookupOptions(
    schema: lineSchemaObject[],
    lookupRequest: {
      field: string;
      seqNumber: number;
      value: string | any;
      page: number;
    }
  ) {
    const fieldSchema: lineSchemaObject | undefined = schema.find(
      (field: lineSchemaObject) => field.name === lookupRequest.field
    );
    const currentPage = fieldSchema?.lookupResultsPageOptions?.page ?? 1;
    const totalPages = fieldSchema?.lookupResultsPageOptions?.totalPages ?? 1;

    if (totalPages > currentPage) {
      // do lookup to get options
      const lookupResponse = await lineLookupService.loadLineLookupOptionsAsync(
        lookupRequest.field,
        this.documentRuleID ?? 0,
        lookupRequest.seqNumber,
        lookupRequest.value,
        lookupRequest.page
      );

      if (lookupResponse && fieldSchema) {
        fieldSchema.lookupResultsPageOptions = lookupResponse.metadata;
        fieldSchema.options = [...fieldSchema.options, ...lookupResponse.data];
      }
    }
  }

  async performValidation(input: FieldValidationRequest) {
    this.liveFormatAmountInput(input.request);
    const validationResponse = await lineValidationService.loadLineFieldValidationAsync(
      input.request.field,
      this.documentRuleID ?? 0,
      input.request.seqNum,
      input.request.value
    );
    this.processValidationResponse(input, validationResponse);
  }

  processValidationResponse(
    input: FieldValidationRequest,
    validationResponse: any
  ) {
    if (validationResponse) {
      const fieldSchema: lineSchemaObject | undefined = input.schema.find(
        (field: lineSchemaObject) => field.name === input.request.field
      );

      if (fieldSchema) {
        fieldSchema.isValid = validationResponse.isValid;
        fieldSchema.isError = !validationResponse.isValid;
        if (
          !validationResponse.isValid &&
          validationResponse.message?.length > 0
        ) {
          fieldSchema.errorMessage = `Error: ${validationResponse.message}`;
        }
      }
      if (validationResponse.fields) {
        this.updateAssociatedFieldsAfterValidation(
          input,
          validationResponse.fields
        );
      }
      if (validationResponse.worktags) {
        this.updateWorktagsAfterValidation(input, validationResponse.worktags);
      }
    }
  }

  updateAssociatedFieldsAfterValidation(
    input: FieldValidationRequest,
    fields: any[]
  ) {
    fields.forEach(async (field: any) => {
      const indexOfDataField = input.schema.findIndex(
        (matchingField: lineSchemaObject) =>
          matchingField.name.toLowerCase() === field.name.toLowerCase()
      );
      if (indexOfDataField >= 0) {
        // update schema
        input.schema[indexOfDataField].options?.push({
          id: field.value ?? null,
          description: field.text ?? field.value ?? "",
          displayValue: field.text ?? field.value ?? ""
        });
        // Only update the value if its not null
        if (field.value != undefined) {
          input.schema[indexOfDataField].value = field.value;
        }
        // update value/data
        const dataObjField = this.lines.find(
          (obj: line) => obj.seqNum === input.request.seqNum
        );
        // Only update the value if its not null
        if (dataObjField && field.value != undefined) {
          switch (field.name) {
            /** Intended Fallthrough */
            case "documentRuleLineTotal":
              dataObjField.data[field.name] = addDecimalsToString(field.value);
              break;

            default:
              dataObjField.data[field.name] = field.value ?? null;
              break;
          }
        }
      }
      if (indexOfDataField >= 0 && field.triggerValidation) {
        return await this.performValidation({
          schema: input.schema,
          request: {
            field: input.schema[indexOfDataField].name,
            seqNum: input.request.seqNum,
            value: input.schema[indexOfDataField].value
          }
        });
      }
    });
  }

  updateWorktagsAfterValidation(
    input: FieldValidationRequest,
    worktags: any[]
  ) {
    const indexOfDataField = input.schema.findIndex(
      (matchingField: lineSchemaObject) =>
        matchingField.name.toLowerCase() === "additionalworktags"
    );
    // update schema
    input.schema[indexOfDataField].options = worktags;
    input.schema[indexOfDataField].value = worktags;
    // update data object
    const dataObjField = this.lines.find(
      (obj: line) => obj.seqNum === input.request.seqNum
    );
    if (dataObjField) {
      dataObjField.data["additionalWorktags"] = worktags;
    }
  }

  liveFormatAmountInput(validationRequest: {
    field: string;
    seqNum: number;
    value: string | any;
  }) {
    if (validationRequest.field == "documentRuleLineTotal") {
      const modifiedLine = this.lines.filter(
        invoiceLine => invoiceLine.seqNum == validationRequest.seqNum
      );
      const value = addDecimalsToString(validationRequest.value);
      modifiedLine[0].data[validationRequest.field] = value;
    }
  }

  async removeWorktag(
    schema: lineSchemaObject[],
    validationRequest: {
      field: string;
      seqNum: number;
      value: string;
    }
  ) {
    const removeWorktagResponse = await lineValidationService.removeLineWorktagAsync(
      this.documentRuleID ?? 0,
      validationRequest.seqNum,
      validationRequest.value.substring(0, validationRequest.value.indexOf("|"))
    );
    const fieldSchema: lineSchemaObject | undefined = schema.find(
      (field: lineSchemaObject) => field.name === validationRequest.field
    );
    if (fieldSchema && removeWorktagResponse.data.isValid) {
      fieldSchema.value = removeWorktagResponse.data.id ?? [];
    } else if (fieldSchema) {
      fieldSchema.errorMessage = `Error: ${removeWorktagResponse.data.description}`;
    }
  }

  async performWorktagValidation(input: FieldValidationRequest) {
    if (!input.request.value) {
      return await this.removeWorktag(input.schema, input.request);
    }
    const validationResponse = await lineValidationService.loadLineFieldValidationAsync(
      input.request.field,
      this.documentRuleID ?? 0,
      input.request.seqNum,
      input.request.value
    );

    if (validationResponse) {
      if (
        !validationResponse.isValid &&
        validationResponse.message?.length > 0
      ) {
        this.$toasted.show(
          `<p><strong>Validation Error for ${input.request.field}:</strong> ${validationResponse.message}</p>`,
          options.ERROR_OPTIONS
        );
      }
      const fieldSchema: lineSchemaObject | undefined = input.schema.find(
        (field: lineSchemaObject) => field.name === input.request.field
      );
      const dataObj = this.lines.find(x => x.seqNum === input.request.seqNum)
        ?.data;

      if (fieldSchema) {
        fieldSchema.isValid = validationResponse.isValid;
        fieldSchema.isError = !validationResponse.isValid;
        fieldSchema.options = validationResponse.id ?? fieldSchema.options;
        fieldSchema.value = validationResponse.id ?? fieldSchema.value;

        if (
          !validationResponse.isValid &&
          validationResponse.message?.length > 0
        ) {
          fieldSchema.errorMessage = `Error: ${validationResponse.message}`;
        }
      }
      if (dataObj) {
        dataObj.additionalWorktags =
          [...validationResponse.id] ?? dataObj.additionalWorktags;
      }
      this.processValidationResponse(input, validationResponse);
    }
  }

  handleFocusBetweenLines(index: number, offset: number) {
    Object.keys(this.$refs).forEach(el => {
      if (el == `addline-${index - offset}`) {
        this.currentLineItemInFocus = el;
        /* @ts-expect-error */
        this.$refs[el][0].$el.focus();
      }
    });
  }

  handleFocusChangeOnSameLine(refName: string) {
    this.currentLineItemInFocus = refName;
  }

  handleClickFocus(refName: string) {
    this.currentLineItemInFocus = refName;
  }

  async initializeLineValues() {
    this.loadingLines = true;
    const linesResponse = await documentDetailsService.loadDocumentLinesAsync(
      this.documentRulesStore.getDocumentRuleID ?? 0
    );
    this.lines = linesResponse ?? [];
    this.loadingLines = false;
    this.documentRulesStore.setDocumentRuleLines(linesResponse ?? []);
  }

  // watchers
  @Watch("lines", { deep: true })
  onLineValuesChange() {
    // whenever line quantity or line values change, store in vuex
    this.documentRulesStore.setDocumentRuleLines(this.lines);
  }

  @Watch("documentRuleID")
  async onDocumentRuleIDChange() {
    // reload lines
    if (this.documentRuleID) {
      await this.initializeLineValues();
      this.refreshLinesKey = randomNumberGenerator();
    }
  }
}
