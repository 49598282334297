































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import InputLabel from "@/components/forms/InputLabel.vue";
import { focusField } from "@/helpers/input-helpers";

@Component({
  components: {
    "input-label": InputLabel
  }
})
export default class AutocompleteChipsInput extends Vue {
  @Prop() lineSequenceNumber!: number;
  @Prop() focusElementRefName!: string;
  @Prop() isReadOnly!: boolean;
  @Prop() label!: string;
  @Prop() placeholder!: string;
  @Prop() name!: string;
  @Prop() value!: any[];
  @Prop() isRequired!: boolean;
  @Prop() isValid!: boolean;
  @Prop() isError!: boolean;
  @Prop() errorMessage?: string;
  @Prop() isVisible!: boolean;
  @Prop() isEditable!: boolean;
  @Prop() options!: any[];
  @Prop() height?: number;
  @Prop() lookupResultsPageOptions!: { [key: string]: number };

  private search: string | null = null;
  private selectedValues: string[] = this.value || [];

  private menuProps = {
    auto: true,
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxWidth: "50%",
    maxHeight: "none",
    offsetY: true,
    offsetOverflow: true,
    transition: false
  };

  handleTabKeypress(event: any) {
    if (event.shiftKey) {
      this.$emit("tabToPreviousField");
    } else {
      this.$emit("tabToNextField");
    }
  }

  remove(item: any) {
    const index = this.selectedValues.findIndex(
      (selectedItem: any) => selectedItem.id === item.id
    );
    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    }
    this.$emit("removeautocompletechip", item);
  }

  // Intersect method will be invoked on DOM mount and when the last item
  // in the v-autocomplete input's options list is intersected
  // arguments are defined by v-intersect directive (Intersection Observer API)
  endIntersect(
    _entries: IntersectionObserverEntry[],
    _observer: IntersectionObserverEntry,
    _isIntersecting: boolean
  ) {
    this.$emit("loadMoreLookupOptions", {
      searchValue: this.search,
      currentPage: this.lookupResultsPageOptions.page ?? 1
    });
  }

  @Watch("search")
  searchValueChanged() {
    this.$emit("lookup", this.search);
  }

  // necessary to update cachedItems to include all options, otherwise
  // they won't appear in the list
  @Watch("options", { deep: true })
  onOptionsChange() {
    const refName = this.lineSequenceNumber + "-" + this.name;
    const fieldRef: any = this.$refs[refName];
    fieldRef.cachedItems = this.options;
  }

  @Watch("selectedValues")
  selectedValuesChanged(newValue: any[], oldValue: any[]) {
    // alernate scenario where oldValue > newValue is covered in remove() method
    if (newValue.length > oldValue.length) {
      // find new addition to selectedValues and send validation emit event
      const newItem = newValue.filter(
        (chip: any) =>
          !oldValue.some((otherChip: any) => chip.id === otherChip.id)
      )[0];
      this.$emit("addautocompletechip", newItem);
    }
  }

  @Watch("value", { deep: true })
  onValuePropChange() {
    this.selectedValues = this.value;
  }

  @Watch("focusElementRefName")
  onFocusElementChange() {
    focusField(this);
  }

  mounted() {
    focusField(this);
  }
}
