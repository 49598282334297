import { app } from "@/main";
import { RavenLookupRequest } from "@/models/external-services/raven-api-lookup-request";
import { RavenLookupResponse } from "@/models/external-services/raven-api-lookup-response";
import { RavenLookupWithInvIDRequest } from "@/models/external-services/raven-api-lookup-with-invid-request";

class FieldLookupService {
  public async lookupFieldAsync(obj: RavenLookupRequest, page = 1) {
    const apiResponse: RavenLookupResponse = await app.$ravenapi.get(
      `api/lookup/${obj.field}?value=${obj.value}&page=${page}`
    );

    return apiResponse.data;
  }

  public async lookupFieldWithInvIDAsync(
    obj: RavenLookupWithInvIDRequest,
    page = 1
  ) {
    const queryString = `value=${encodeURIComponent(obj.value)}&invID=${
      obj.invID
    }&page=${page}`;

    const apiResponse: RavenLookupResponse = await app.$ravenapi.get(
      `api/lookup/${obj.field}?${queryString}`
    );

    return apiResponse.data;
  }

  public async lookupPoNumberAsync(
    obj: {
      invID: number;
      value: string;
      supplierID: string;
      companyID: string;
    },
    page = 1
  ) {
    const queryString = `value=${encodeURIComponent(obj.value)}&invID=${
      obj.invID
    }&supplierID=${obj.supplierID}&companyID=${obj.companyID}&page=${page}`;

    const apiResponse: RavenLookupResponse = await app.$ravenapi.get(
      `api/lookup/ponum?${queryString}`
    );

    return apiResponse.data;
  }
}

export const fieldLookupService = new FieldLookupService(); // singleton
