

























import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class DocumentRuleAvailableBanner extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop() message!: string;
  @Prop() clickableText!: string;
  @Prop({ default: () => {} }) handleClickOnClickableText!: Function;
  @Prop({ default: () => {} }) handleClose!: Function;
}
