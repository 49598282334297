import { app } from "@/main";
import { comment } from "@/models/document-entry/comment";
import { RavenCreateCommentOnInvoiceRequest } from "@/models/external-services/raven-api-create-comment-on-invoice-request";
import { RavenDeleteCommentFromInvoiceRequest } from "@/models/external-services/raven-api-delete-comment-request";

class DocumentCommentsService {
  public loadCommentsOnInvoiceAsync = async (
    invID: number | string
  ): Promise<comment[]> => {
    return await app.$ravenapi.get(`api/comments/${invID}`);
  };

  public async createDocumentCommentAsync(
    newCommentRequest: RavenCreateCommentOnInvoiceRequest
  ) {
    return await app.$ravenapi.post("api/comments/", newCommentRequest);
  }

  public async deleteDocumentCommentAsync(
    request: RavenDeleteCommentFromInvoiceRequest
  ) {
    return await app.$ravenapi.delete(
      `api/comments/${request.invID}/${request.commentID}`
    );
  }
}

export const documentCommentsService = new DocumentCommentsService(); // singleton
