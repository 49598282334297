































































































import { Component, Prop, Watch } from "vue-property-decorator";
import DocumentRuleMixin from "@/mixins/DocumentRuleMixin.vue";
import PrimaryButton from "@/components/design-system/buttons/PrimaryButton.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import { documentRulesService } from "@/services/document-rules.service";
import { ValidatableField } from "@/models/document-entry/validatable-field";
import { InputObject } from "@/models/document-entry/input-object";
import {
  DocumentRuleModalMode,
  DocumentRuleType
} from "@/models/document-entry/document-rules";
import { RavenApiDocumentRuleDataResponse } from "@/models/external-services/raven-api-document-rule-type-response";
import { UpdateSupplierAutomationTypeRequest } from "@/models/external-services/raven-api-update-supplier-automation-request";
import options from "@/shared/constants/toast-options";

@Component({
  components: {
    "primary-button": PrimaryButton,
    "secondary-button": SecondaryButton
  }
})
export default class DocumentRuleTypeSelector extends DocumentRuleMixin {
  // props
  @Prop({ default: false }) isDocumentRuleTypeModalDisplayed!: boolean;
  @Prop() closeDocumentRuleTypeModal!: Function;

  // reactive properties
  private loadingRuleTypeDetails = false;

  private readonly ruleTypes = DocumentRuleType;
  private readonly documentRuleTypeOptions: InputObject[] = [
    new InputObject("Custom Rules", this.ruleTypes.CUSTOM_RULE),
    new InputObject("Code From Last Invoice", this.ruleTypes.PREVIOUS_INVOICE),
    new InputObject("Extract Lines", this.ruleTypes.LINE_EXTRACTION),
    new InputObject("No Rules", this.ruleTypes.NO_AUTOMATION)
  ];
  private readonly defaultRuleType: InputObject = this.documentRuleTypeOptions.find(
    option => option.value === this.ruleTypes.NO_AUTOMATION
  ) as InputObject;

  // computed properties
  private get supplierDisplayName(): string {
    const supplier: ValidatableField = this.documentRulesStore
      .getSupplierSelectedOnDocumentEntry;

    if (Object.prototype.hasOwnProperty.call(supplier, "options")) {
      const supplierName =
        supplier?.options?.find(option => option.id === supplier?.value)
          ?.displayValue ?? "";
      const supplierid = ((supplier?.value as string) ?? "").includes("|")
        ? (supplier?.value as string)?.substring(
            0,
            (supplier?.value as string)?.indexOf("|")
          )
        : supplier?.value ?? "";
      return supplierName + " (" + supplierid + ")";
    } else {
      return "";
    }
  }

  private get supplierSelectedOnDocumentEntry(): string {
    const supplierValue = Object.prototype.hasOwnProperty.call(
      this.documentRulesStore.getSupplierSelectedOnDocumentEntry,
      "value"
    )
      ? this.documentRulesStore.getSupplierSelectedOnDocumentEntry?.value ?? ""
      : "";

    // remove pipe from supplier value
    const pipeIndex: number = supplierValue.indexOf("|");
    if (pipeIndex > 0) {
      return supplierValue.substring(0, pipeIndex);
    }
    return supplierValue;
  }

  private get documentRuleModalTitle(): string {
    return "Custom Rule for " + this.supplierDisplayName;
  }

  private get documentRuleType(): InputObject {
    return this.documentRulesStore.getDocumentRuleType;
  }
  // we only update the document rule type in the vuex store when it
  // has been saved in the database, so that we can compare local changes
  // in radio input to db value and determine whether save button is disabled
  private set documentRuleType(value: InputObject) {
    this.documentRulesStore.setDocumentRuleType(value);
  }
  private get documentRulesListForSupplier(): RavenApiDocumentRuleDataResponse[] {
    return this.documentRulesStore.getDocumentRulesListForSupplier;
  }
  private set documentRulesListForSupplier(
    value: RavenApiDocumentRuleDataResponse[]
  ) {
    this.documentRulesStore.setDocumentRulesListForSupplier(value);
  }

  private cloneRuleType: InputObject = this.defaultRuleType;

  // if current selected rule type is same as value saved in database,
  // disable the save button for the document rule type
  private get isDocumentRuleTypeSaveButtonDisabled(): boolean {
    const storedRuleType = JSON.stringify(this.documentRuleType);
    const currentRuleTypeSelected = JSON.stringify(this.cloneRuleType);
    return storedRuleType == currentRuleTypeSelected;
  }

  // methods

  launchEditForSelectedRule(documentRuleId: number) {
    this.documentRulesStore.setDocumentRuleID(documentRuleId);
    this.documentRulesStore.setPrototypeDocumentRuleID(documentRuleId);
    this.documentRulesStore.openDocumentRuleCreateEditModal(
      DocumentRuleModalMode.UPDATE
    );
    this.closeDocumentRuleTypeModal();
  }

  launchCreateNewCustomRule() {
    this.documentRulesStore.openDocumentRuleCreateEditModal(
      DocumentRuleModalMode.CREATE
    );
    this.closeDocumentRuleTypeModal();
  }

  async updateDocumentRuleType() {
    const request: UpdateSupplierAutomationTypeRequest = new UpdateSupplierAutomationTypeRequest(
      this.supplierSelectedOnDocumentEntry,
      this.cloneRuleType.value as string
    );
    const response = await documentRulesService.updateSupplierAutomationType(
      request
    );

    if (response.status !== 200 || response.data.statusCode !== 200) {
      // error occurred
      this.$toasted.show(
        "<p>Error occurred while updating supplier automation type.</p>",
        options.ERROR_OPTIONS
      );
    } else {
      // success, store new rule type value in vuex
      this.documentRuleType = this.cloneRuleType;
    }
  }

  // watcher methods

  @Watch("isDocumentRuleTypeModalDisplayed")
  async onModalVisibilityChange() {
    if (this.isDocumentRuleTypeModalDisplayed === true) {
      // modal was opened, load rule type and custom rules configured for the selected supplier
      this.loadingRuleTypeDetails = true;
      const supplierRuleTypeResponse = await documentRulesService.getCustomRulesConfiguredForSupplier(
        this.supplierSelectedOnDocumentEntry
      );

      // if response has data property, it contains error since response.data.data
      // was not returned from raven-api axios plugin
      if (
        !Object.prototype.hasOwnProperty.call(supplierRuleTypeResponse, "data")
      ) {
        // store list of custom rules for selected supplier
        this.documentRulesListForSupplier =
          supplierRuleTypeResponse.documentRulesData;
        // store supplier's autolinecoding value
        this.documentRuleType =
          this.documentRuleTypeOptions.find(
            option => option.value === supplierRuleTypeResponse.automationType
          ) ?? this.defaultRuleType;
      }
      this.cloneRuleType = this.documentRuleType;
      this.loadingRuleTypeDetails = false;
    }
  }
}
