






























































































































































































































































































































































































































































































// Custom components
import CommentsCardsPanelModal from "@/components/forms/document-entry/comment-panel/CommentCardsModalPanel.vue";
import DocEntrySkeleton from "@/components/skeletons/DocEntrySkeleton.vue";
import DocumentRule from "@/components/forms/document-rules/DocumentRule.vue";
import DocumentRuleBanner from "@/components/design-system/banners/DocumentRuleBanner.vue";
import DocumentRuleAvailableBanner from "@/components/design-system/banners/DocumentRuleAvailableBanner.vue";
import DocumentRuleTypeSelector from "@/components/forms/document-rules/DocumentRuleTypeSelector.vue";
import HoldBanner from "@/components/design-system/banners/HoldBanner.vue";
import IconButton from "@/components/design-system/buttons/IconButton.vue";
import InvoiceFieldsForm from "@/components/forms/document-entry/invoice-fields/InvoiceFields.vue";
import InvoiceLines from "@/components/forms/document-entry/invoice-lines/InvoiceLines.vue";
import OnDemandSync from "@/components/forms/document-entry/on-demand-sync/OnDemandSync.vue";
import PDFWebViewer from "@/components/doc-viewer/PDFWebViewer.vue";
import PreprocessingBanner from "@/components/design-system/banners/PreprocessingBanner.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import UserRolesMixin from "@/mixins/UserRoles.vue";

// Helpers
import { arrayBufferToBlob } from "@/helpers/pdf-file-helper";
import { checkCurrentRouteAndRedirect } from "@/helpers/router-helpers";
import {
  randomNumberGenerator,
  stringToDecimal
} from "@/helpers/numbers-helpers";
import { reportErrorFullwidth } from "@/helpers/error-captured";

// Models
import { DocumentField } from "@/models/document-entry/document-field";
import { DocumentOrderRequest } from "@/models/external-services/document-order-request-body";
import { FieldCoordinates } from "@/models/document-entry/pdf-coordinates";
import {
  UpdateSyncFieldI,
  SelectedFieldSyncI
} from "@/models/document-entry/on-demand-sync";

// Services
import { documentDetailsService } from "@/services/document-details.service";
import { invoiceService } from "@/services/invoice.service";
import { fieldValidationService } from "@/services/invoice-field-validation.service";

// Shared
import Catch from "@/shared/decorators/catch-errors";
import { InvoicesType } from "@/shared/constants/invoices-endpoints";
import options from "@/shared/constants/toast-options";

// Store
import DocumentCommentsStore from "@/store/document-comments.store";
import DocumentRulesStore from "@/store/document-rules.store";
import DocViewerStore from "@/store/doc-viewer.store";
import InvoiceListStore from "@/store/invoice-list.store";

// Third party
import { Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Decimal } from "decimal.js";
import { invoiceAutocodingService } from "@/services/invoice-autocoding.service";

@Component({
  components: {
    "invoice-fields-form": InvoiceFieldsForm,
    "icon-button": IconButton,
    "simple-confirmation-modal": SimpleConfirmationModal,
    "comments-panel-modal": CommentsCardsPanelModal,
    "invoice-lines": InvoiceLines,
    "pdf-web-viewer": PDFWebViewer,
    skeleton: DocEntrySkeleton,
    "document-rule": DocumentRule,
    "on-demand-sync-modal": OnDemandSync,
    "hold-banner": HoldBanner,
    "preprocessing-banner": PreprocessingBanner,
    "document-rule-banner": DocumentRuleBanner,
    "document-rule-available-banner": DocumentRuleAvailableBanner,
    "document-rule-type-selector": DocumentRuleTypeSelector
  }
})
export default class DocumentEntry extends UserRolesMixin {
  // reactive class properties
  private isFetching = false;
  private isFetchingImage = false;
  private isActionInProgress = false;

  // feature flag evaluations
  private isImageHighlightingEnabled: boolean = this.$launchDarkly.variation(
    "show-document-entry-image-highlighting",
    false
  );

  // document rules
  private shouldShowDocumentRulesButton: boolean = this.$launchDarkly.variation(
    "show-document-rules-create-rule",
    false
  );
  private showDocumentRuleTypeModal = false;

  // will change based on API response
  private isDocumentReadOnly = true;
  private shouldDisplayInUseMsg = false;
  private inUseName = "";
  private inUseInitials = "";

  private selectedDocumentId: number = parseInt(this.$route.params.id);
  private selectedDocumentInvoiceId = "";
  private documentTypes = [
    { text: "Invoice", value: "INVOICE" },
    { text: "Credit", value: "CREDIT" },
    { text: "Debit", value: "DEBIT" },
    { text: "Supplier Invoice Request", value: "SIR" }
  ]; // document types the user can select from
  private selectedDocumentType = this.documentTypes[0]; // default selection is Invoice

  // whether supplier worktags button is displayed, passed as props to invoice lines
  private isPOFromHeaderValid = false;
  private isSupplierFromHeaderValid = false;

  private showDeleteModal = false; // whether delete confirmation modal is displayed
  private showCancelModal = false; // whether cancel confirmation modal is displayed
  private showHoldModal = false; // whether hold confirmation modal is displayed
  private holdReason: any = {
    value: "",
    options: []
  }; // reason for placing document on hold, options come from WD_Settings
  private showResumeModal = false; // whether resume confirmation modal is displayed

  private invoiceLinesKey = randomNumberGenerator();
  private invoiceOtherChargesTotal: Decimal = new Decimal("0"); // total freight, tax, and other amount (updated automatically by InvoiceFieldsForm component)
  private invoiceLineTotal: Decimal = new Decimal("0"); // total extended amount of all lines (updated automatically by InvoiceLines component)
  private controlTotal: Decimal = new Decimal("0"); // control total on invoice
  private taxAmountTotal = "0"; // control tax amount of the invoice header, updated from the InvoiceLines component
  private withholdingTaxTotal = "0"; // withholding tax of the invoice header, updated from the InvoiceLines component

  // property to define if the invoice has prepaid lines in order to show / hide some fields
  private lineHasPrepaidFlag = false;

  // below 4 properties are updated by API response; based on document status and whether submitted to WD
  private isDocumentInStateToBeDeleted = false;
  private isDocumentInStateToBePutOnHold = false;
  private isDocumentInStateToBeCancelled = false;
  private isDocumentInStateToBeResumed = false;

  private canDocumentBeSubmitted = false;

  //on demand sync modal
  private showOnDemandSyncModal = false;
  private onDemandSyncModalType = "";
  private onDemandSyncModalKey = randomNumberGenerator();
  private updateSyncField: UpdateSyncFieldI = {
    field: "",
    value: { selected: { id: "", text: "" }, options: [] }
  };

  // initial values to pass to InvoiceFields and InvoiceLines forms
  private initialFieldValues: DocumentField[] = [];
  private invoiceFieldsKey = randomNumberGenerator();

  // document status - updated by API response
  private documentStatus = "";

  // ponum validity - used to change po line detail button status(enabled or disabled)
  private isLineWorkbenchButtonDisabled = true;

  //invFieldToBeValidated
  private invFieldToBeValidated = "";

  // readonly copy of store module
  private readonly invoiceListStore: InvoiceListStore = getModule(
    InvoiceListStore,
    this.$store
  );
  private readonly docViewerStore: DocViewerStore = getModule(
    DocViewerStore,
    this.$store
  );
  private readonly documentRulesStore: DocumentRulesStore = getModule(
    DocumentRulesStore,
    this.$store
  );
  private readonly documentCommentsStore: DocumentCommentsStore = getModule(
    DocumentCommentsStore,
    this.$store
  );

  //hold reason banner
  private showHoldReasonBanner = false;
  private holdReasonBannerMessage = "";

  //preprocessing messagers banner
  private showPreprocessingBanner = false;
  private preprocessingMessagesList: string[] = [];

  // for comments item in hamburger menu
  private shouldShowDocumentComments: boolean = this.$launchDarkly.variation(
    "show-document-comments",
    false
  );

  // document rule banner
  private showDocumentRuleBanner = false;

  // document rule available to apply
  private shouldDisplayDocumentRuleAvailableBanner = false;
  private documentRuleAvailableBannerText = "";
  // determines if document rule available notification banner is displayed
  private showDocumentRuleAvailableNotification: boolean = this.$launchDarkly.variation(
    "should-show-document-rule-notification-banner",
    false
  );

  // computed properties

  private get pdfWebViewerKey(): number {
    return this.docViewerStore.getPdfWebViewerKey;
  }
  private get selectedDocumentImage(): string | undefined {
    return this.docViewerStore.getPdfDocument;
  }
  private set selectedDocumentImage(blob: string | undefined) {
    this.docViewerStore.setPdfDocument(blob);
  }

  private get documentRuleID(): number | null {
    return this.documentRulesStore.getDocumentRuleID;
  }
  // determines whether document rule exists in prototype status, to edit or create new rule
  private get supplierSelectedOnDocumentEntry() {
    return this.documentRulesStore.getSupplierSelectedOnDocumentEntry;
  }
  private get isDocumentRuleCreateEditModalDisplayed(): boolean {
    return this.documentRulesStore.getIsDocumentRuleCreateEditModalDisplayed;
  }

  // for document comments
  private get totalComments(): number {
    return this.documentCommentsStore.getDocumentComments.length;
  }
  private get isDocumentCommentsPanelDisplayed(): boolean {
    return this.documentCommentsStore.getIsDocumentCommentsPanelDisplayed;
  }

  private get showMenuOfDocumentRelatedOptions(): boolean {
    // eventually this bool will include OR clauses for other menu options
    return (
      (this.shouldShowDocumentRulesButton ?? false) ||
      (this.shouldShowDocumentComments ?? false)
    );
  }

  private get currentInvoicesType() {
    return this.invoiceListStore.getStoredInvoicesListPathObject
      .invoicesGroupString;
  }

  //for previous or next invoice
  private get storedInvIDsCandidate() {
    return this.invoiceListStore.getStoredInvIDsCandidates;
  }

  private get invoiceTotal(): string {
    const sum = this.invoiceOtherChargesTotal.plus(this.invoiceLineTotal);
    const result = sum.toFixed(2);
    return result;
  }

  private get remainingBalance(): string {
    const remainingBalance =
      parseFloat(this.controlTotal.toString()) - parseFloat(this.invoiceTotal);
    const result = remainingBalance.toFixed(2);
    return result;
  }

  private get isInvoiceInBalance(): boolean {
    return (
      parseFloat(this.controlTotal.toString()) ===
      stringToDecimal(this.invoiceTotal)
    );
  }

  // below property is based on whether to show the release button for VS team members
  private get showValidationServicesReleaseButton(): boolean {
    return (
      this.documentStatus === "ValidationServices" &&
      this.isValidationServicesUser
    );
  }

  private get isCompletedDocument() {
    return this.currentInvoicesType == InvoicesType.COMPLETTED;
  }

  // determine margin necessary to prevent banner from hiding form
  private get bannerHeight(): number {
    return (
      (this.showHoldReasonBanner ? 48 : 0) +
      (this.showPreprocessingBanner ? 48 : 0) +
      (this.showDocumentRuleBanner ? 48 : 0)
    );
  }

  // lifecycle methods
  async created() {
    this.isFetching = true;
    this.docViewerStore.setPdfDocument(undefined);
    //load a worker to detect if tab or window is being closed
    window.addEventListener("beforeunload", event => {
      invoiceService.unclaimInvoice(this.selectedDocumentId);
    });

    // set initial doc rule values to blank
    this.documentRulesStore.setDocumentRuleID(null);
    this.documentRulesStore.setSupplierSelectedOnDocumentEntry({});
    this.documentRulesStore.setCompanySelectedOnDocumentEntry({});
    this.documentRulesStore.setCustomerAcctSelectedOnDocumentEntry({});

    // make API call for document details
    const documentDetails = await documentDetailsService.loadDocumentDetails(
      this.selectedDocumentId
    );
    this.isFetching = false;

    if (!documentDetails) return;

    // update doc-viewer to display document image
    this.isFetchingImage = true;
    await this.setInvoiceImage();
    this.isFetchingImage = false;

    if (
      this.canUserEditDocument === true &&
      documentDetails.permissions.isReadOnly === false
    ) {
      // set document status
      this.documentStatus = documentDetails.invStatus;
      // only put page in editable state if user has edit permission and document status allows
      this.isDocumentReadOnly = false;
    }

    // determine whether or not to display in use message and what name to set
    if (documentDetails.permissions.isDocumentInUse) {
      if (documentDetails.permissions.inUseBy == "Ascend AP") {
        this.inUseName = "Ascend AP";
        this.inUseInitials = "AA";
      } else if (documentDetails.permissions.inUseBy.includes(" ")) {
        this.inUseName = documentDetails.permissions.inUseBy;
        const userName = documentDetails.permissions.inUseBy.split(" ");
        this.inUseInitials = userName[0][0] + userName[1][0];
      } else if (documentDetails.permissions.inUseBy != "") {
        this.inUseName = documentDetails.permissions.inUseBy;
        this.inUseInitials = "UU";
      } else {
        this.inUseName = "Another user";
        this.inUseInitials = "UU";
      }
      this.shouldDisplayInUseMsg = true;
    } else {
      this.shouldDisplayInUseMsg = false;
      this.inUseName = "";
      this.inUseInitials = "";
    }

    // determine which action buttons to display
    this.isDocumentInStateToBeDeleted =
      documentDetails.permissions.canBeDeleted;
    this.isDocumentInStateToBeCancelled =
      documentDetails.permissions.canBeCancelled;
    this.isDocumentInStateToBePutOnHold =
      documentDetails.permissions.canBePutOnHold;
    this.isDocumentInStateToBeResumed =
      documentDetails.permissions.canBeResumed;

    this.canDocumentBeSubmitted = documentDetails.permissions.canBeSubmitted;
    // set possible hold reasons
    this.holdReason = Object.assign(
      {},
      {
        value: "",
        options: documentDetails.permissions.holdReasons
      }
    );
    // set hold banner
    this.showHoldReasonBanner = documentDetails.onHold;
    this.holdReasonBannerMessage = documentDetails.holdReason;

    // set preprocessing messages banner
    try {
      const preprocessingMessages = JSON.parse(documentDetails.invLastError);
      preprocessingMessages.forEach(message => {
        this.preprocessingMessagesList.push(
          message.Origin + " " + message.Type + ": " + message.Message
        );
      });
      if (preprocessingMessages.length > 0) {
        this.showPreprocessingBanner = true;
      }
    } catch (e) {
      this.preprocessingMessagesList = [];
    }

    // store whether document is an EDI document
    // this determines whether a regenerate image button is displayed
    this.docViewerStore.setIsEDIDocument(documentDetails.isEDIDocument);

    // store whether document has email body
    // this determines whether the Split Documents button is displayed
    this.docViewerStore.setHasEmailBody(documentDetails.hasEmailBody);

    // set initial field and line values for props
    const selectedDocumentType = documentDetails.fields.filter(
      (obj: DocumentField) => obj.name === "DocType"
    )[0].value;
    this.selectedDocumentType = this.documentTypes.filter(
      (val: { [key: string]: string }) => val.value === selectedDocumentType
    )[0];
    this.initialFieldValues = documentDetails.fields;
    if (documentDetails.withholdingTax) {
      this.withholdingTaxTotal = parseFloat(
        documentDetails.withholdingTax
      ).toFixed(2);
    }

    // set initial document rule details
    if (documentDetails.documentRuleUsages?.length > 0) {
      this.documentRulesStore.setDocumentRuleUsages(
        documentDetails.documentRuleUsages
      );
      if (this.shouldShowDocumentRulesButton) {
        this.showDocumentRuleBanner = true;
      }
    }

    // set initial tax grid information
    (this.$refs.invoicefields as InvoiceFieldsForm)?.setTaxTable(
      documentDetails["taxes"]
    );
    (this.$refs.invoicefields as InvoiceFieldsForm)?.setWithholdingTaxTable(
      documentDetails["withholdingTaxes"]
    );
  }

  // methods

  // below property notifies invoice fields component
  // about requested validations from invoice line for an invoice field
  triggerValidateInvoiceField(fieldName: string) {
    this.invFieldToBeValidated = ""; // Reset to force re-triggering
    this.$nextTick(() => {
      this.invFieldToBeValidated = fieldName;
    });
  }

  closePreprocessingBanner() {
    this.showPreprocessingBanner = !this.showPreprocessingBanner;
  }
  changeStateOfDocRuleTypeModal() {
    this.showDocumentRuleTypeModal = !this.showDocumentRuleTypeModal;
  }
  closeDocRuleCreateEditModal() {
    this.documentRulesStore.resetEntireDocumentRuleStoredState();
    this.documentRulesStore.changeStateOfCreateEditModal(false);
  }
  closeHoldBanner() {
    this.showHoldReasonBanner = !this.showHoldReasonBanner;
  }

  handleCommentsModal() {
    this.documentCommentsStore.changeStateOfDocumentCommentsPanel();
  }
  closeDocumentRuleBanner() {
    this.showDocumentRuleBanner = !this.showDocumentRuleBanner;
  }

  @Catch((error: any, context: any) => {
    context.selectedDocumentImage = undefined; // hides doc-viewer
    return;
  })
  async setInvoiceImage() {
    // make API call for invoice image
    const documentImage = await documentDetailsService.loadDocumentImage(
      this.selectedDocumentId
    );
    this.selectedDocumentImage = arrayBufferToBlob(
      documentImage,
      "application/pdf"
    );
    this.setImageCoordinates();
    // increment key associated with doc-viewer to reload component
    this.docViewerStore.incrementPdfWebViewerKey();
  }

  async setImageCoordinates() {
    // make API call for image coordinates
    if (this.isImageHighlightingEnabled) {
      const imageCoordinates: FieldCoordinates[] = await documentDetailsService.loadImageCoordinates(
        this.selectedDocumentId
      );
      this.docViewerStore.setPdfCoordinates(imageCoordinates);
    }
  }

  // delete invoice modal
  openDeleteModal() {
    this.showDeleteModal = true;
  }

  closeDeleteModal() {
    this.showDeleteModal = false;
  }

  // cancel invoice modal
  openCancelModal() {
    this.showCancelModal = true;
  }
  closeCancelModal() {
    this.showCancelModal = false;
  }

  // resume invoice modal
  openResumeModal() {
    this.showResumeModal = true;
  }
  closeResumeModal() {
    this.showResumeModal = false;
  }

  // hold invoice modal
  openHoldModal() {
    this.showHoldModal = true;
  }
  closeHoldModal() {
    this.holdReason.value = "";
    this.showHoldModal = false;
  }

  // document rule available banner
  handleDocumentRuleAvailableNotification(value: string) {
    if (value?.length > 0) {
      this.documentRuleAvailableBannerText =
        "Do you want to apply this document rule: " +
        value +
        "? It will replace your current information.";
      this.shouldDisplayDocumentRuleAvailableBanner = true;
    } else {
      this.documentRuleAvailableBannerText = "";
      this.shouldDisplayDocumentRuleAvailableBanner = false;
    }
  }

  async applyDocumentRuleAvailable() {
    this.isDocumentReadOnly = true; // make doc read-only while doc rule is applied
    await invoiceAutocodingService.applyDocumentRuleToInvoice(
      this.selectedDocumentId
    );
    this.$router.go(0); // refresh current page to load new header and line field values
  }

  //end of actions modals

  @Catch((context, error) => {})
  resetRefsInInvoiceFieldsForm() {
    const invoiceFieldsRefs = this.$refs.invoicefields as any;
    invoiceFieldsRefs.requester.required = true;
    invoiceFieldsRefs.requester.hasError = true;
    invoiceFieldsRefs.requester.isValid = false;
    invoiceFieldsRefs.requester.search = "";
    invoiceFieldsRefs.requester.value = "";
    invoiceFieldsRefs.$refs.requester.cachedItems = [];
    invoiceFieldsRefs.$refs.suppliercontract.cachedItems = [];
  }

  handleOtherChargesTotalChange(value: Decimal) {
    this.invoiceOtherChargesTotal = new Decimal(value.toString());
  }

  handleControlTotalChange(value: Decimal) {
    this.controlTotal = new Decimal(value.toString());
  }

  handlelineHasPrepaidFlag(value: boolean) {
    this.lineHasPrepaidFlag = value;
  }

  handleLineTotalChange(value: Decimal) {
    this.invoiceLineTotal = new Decimal(value.toString());
  }

  handleLineUpdateTaxAmountChange(value: string) {
    this.taxAmountTotal = value;
  }

  handleUpdateWithholdingTaxChange(value: string) {
    this.withholdingTaxTotal = value;
  }

  handleOnDemandSyncModal(value: any) {
    this.onDemandSyncModalType = value;
    this.showOnDemandSyncModal = !this.showOnDemandSyncModal;
    this.onDemandSyncModalKey = randomNumberGenerator();
  }

  handleMoveFocusToInvoiceFields() {
    (this.$refs.invoicefields as InvoiceFieldsForm).moveFocusToLastField();
  }
  handleMoveFocusToInvoiceLines() {
    (this.$refs.invoicelines as InvoiceLines).handleMoveFocusToInvoiceLines();
  }

  handleLineWorkbenchButtonVisibility(isValid: boolean) {
    this.isLineWorkbenchButtonDisabled = !isValid;
    this.isPOFromHeaderValid = isValid;
  }

  handleSupplierWorktagsButtonVisibility(isValid: boolean) {
    this.isSupplierFromHeaderValid = isValid;
  }

  handleInvoiceIdChanged(invoiceId: string) {
    this.selectedDocumentInvoiceId = invoiceId;
  }

  private async clearImageCoordinates(fieldName: string) {
    const fieldCoordinates = this.docViewerStore.getPdfCoordinates.filter(
      coord => coord.fieldName === fieldName + "Location"
    );
    // if field has associated coordinates, send request to delete coordinates from the database
    if (fieldCoordinates.length > 0) {
      await documentDetailsService.deleteImageCoordinates(
        this.selectedDocumentId,
        fieldName
      );
      // set coordinates again so that removed coordinates will no longer be included
      this.setImageCoordinates();
    }
  }

  private clearLineTaxCodes() {
    (this.$refs.invoicelines as InvoiceLines).clearTaxCodes();
  }

  private async handleUpdateInvoiceLines() {
    await (this.$refs
      .invoicelines as InvoiceLines).handleUpdatingInvoiceLines();
    this.invoiceLinesKey += 1;
  }

  async deleteDocument() {
    const serviceResponse = await invoiceService.updateDocumentStatus(
      "delete",
      [this.selectedDocumentId]
    );
    this.showDeleteModal = false; // close modal
    if (serviceResponse === "") {
      this.$toasted.show("<p>Document successfully deleted</p>", {
        ...options.SUCCESS_OPTIONS
      });
      await this.navigateToDocumentList();
    }
  }

  async cancelDocument() {
    const serviceResponse = await invoiceService.updateDocumentStatus(
      "cancel",
      [this.selectedDocumentId]
    );
    this.showCancelModal = false; // close modal
    if (serviceResponse === "") {
      this.$toasted.show("<p>Document successfully cancelled</p>", {
        ...options.SUCCESS_OPTIONS
      });
      await this.navigateToDocumentList();
    }
  }

  async resumeDocument() {
    const serviceResponse = await invoiceService.updateDocumentStatus(
      "resume",
      [this.selectedDocumentId]
    );
    this.showResumeModal = false; // close modal
    location.reload(); // reload page to get new permissions for resumed doc
    if (serviceResponse === "") {
      this.isDocumentReadOnly = false;
    }
  }

  private applySync(value: { selected: SelectedFieldSyncI; options: any[] }) {
    this.updateSyncField = {
      field: this.onDemandSyncModalType,
      value: value
    };
    this.handleOnDemandSyncModal("");
  }

  @Catch(reportErrorFullwidth)
  async putDocumentOnHold() {
    if (this.holdReason.value.length === 0) {
      throw new Error("Please select a hold reason");
    }
    const serviceResponse = await invoiceService.updateDocumentStatus(
      "hold",
      [this.selectedDocumentId],
      this.holdReason.value
    );
    this.showHoldModal = false; // close modal
    if (serviceResponse === "") {
      this.$toasted.show("<p>Document successfully placed on hold</p>", {
        ...options.SUCCESS_OPTIONS
      });
      this.showHoldModal = false;
      await this.navigateToPreviousOrNextDoc(true);
    }
  }

  async navigateToPreviousOrNextDoc(forward: boolean, unclaim = true) {
    // unclaim the current invoice before changing invoices, if required
    if (unclaim) {
      await invoiceService.unclaimInvoice(this.selectedDocumentId);
    }
    // request previous/next documentid from API
    const requestBody = new DocumentOrderRequest(
      this.selectedDocumentId,
      this.storedInvIDsCandidate,
      forward,
      ""
    );
    const incomingDocumentObj = await invoiceService.getPreviousNextAsync(
      requestBody
    );
    // push router to doc entry page for next document
    this.$router
      .push(`/document/${incomingDocumentObj.invID}`)
      .catch(async () => {
        this.$toasted.show(
          `<p>No more documents found for currently applied invoice list filters</p>`,
          options.INFO_OPTIONS
        );
        // return to invoice list if no more documents in queue
        await this.navigateToDocumentList();
      });
  }

  async navigateToDocumentList() {
    // unclaim document ownership
    await invoiceService.unclaimInvoice(this.selectedDocumentId);
    if (this.showValidationServicesReleaseButton) {
      // if Validation Services is processing the document, go back to VS invoice list for selected customer
      checkCurrentRouteAndRedirect(
        this.$router,
        "/validation-services/" + this.userStore.getSelectedCustomer.id
      );
    } else {
      // otherwise go back to customers' invoice list
      const path =
        this.invoiceListStore.getStoredInvoicesListPathObject
          .invoicesGroupString === InvoicesType.COMPLETTED
          ? "/complete-documents"
          : "/documents";
      checkCurrentRouteAndRedirect(this.$router, path);
    }
  }

  @Catch((error: any, context: any) => {
    context.$toasted.show(
      `<p>${error?.message ?? "Error Occurred"}</p>`,
      options.ERROR_OPTIONS
    );
  })
  async submitDocument() {
    this.isActionInProgress = true;
    const addInvoiceResponse: any = await invoiceService.addInvoiceToWorkdayAsync(
      this.selectedDocumentId
    );
    if (addInvoiceResponse?.success && !addInvoiceResponse?.description) {
      this.$toasted.show(
        "<p>Invoice successfully added to Workday.</p>",
        options.SUCCESS_OPTIONS
      );
      await this.navigateToPreviousOrNextDoc(true);
    } else if (addInvoiceResponse?.success && addInvoiceResponse?.description) {
      this.$toasted.show(
        `<p>Invoice added to Workday with a Warning - ${addInvoiceResponse?.description}</p>`,
        options.INFO_OPTIONS
      );
      await this.navigateToPreviousOrNextDoc(true);
    } else {
      const message =
        addInvoiceResponse?.description ??
        addInvoiceResponse.data?.errorMessage ??
        "Error occurred";
      this.$toasted.show(`<p>${message}</p>`, options.ERROR_OPTIONS);
    }
    this.isActionInProgress = false;
  }

  @Catch((error: any, context: any) => {
    context.$toasted.show(`<p>${error.message}</p>`, options.ERROR_OPTIONS);
  })
  async releaseVSInvoice() {
    this.isActionInProgress = true;
    const releaseVSInvoiceResponse: any = await invoiceService.releaseValidationServicesInvoice(
      this.selectedDocumentId
    );
    if (
      releaseVSInvoiceResponse === true ||
      releaseVSInvoiceResponse.data?.statusCode === 200
    ) {
      this.$toasted.show("<p>Invoice Released</p>", options.SUCCESS_OPTIONS);
      await this.navigateToPreviousOrNextDoc(true, false); // no need to unclaim here, it's handled by release API request
    } else {
      this.$toasted.show(
        "<p>Unable to release invoice</p>",
        options.ERROR_OPTIONS
      );
    }
  }

  async onDocTypeChange(newValue: { text: string; value: string }) {
    this.resetRefsInInvoiceFieldsForm();
    const validationResponse = await fieldValidationService.validateFieldAsync({
      field: "doctype",
      invoiceid: this.selectedDocumentId,
      value: newValue.value
    });
    if (!validationResponse.data.isValid) {
      // display error message
      this.$toasted.show(
        `<p><strong>Error:</strong> ${validationResponse.data.message}
        <strong>Value specified:</strong> ${newValue.text}</p>`,
        options.ERROR_OPTIONS
      );
    }
    if (
      validationResponse.data.fields &&
      validationResponse.data.fields.length > 0
    ) {
      validationResponse.data.fields.forEach(fieldToValidate => {
        (this.$refs.invoicefields as InvoiceFieldsForm)?.validateFieldValue(
          fieldToValidate.name
        );
      });
    }
  }
}
