export class DocumentOrderRequest {
  public invID: number;
  public invIDsCandidates: number[];
  public forward: boolean;
  public requestFrom: string;

  constructor(
    invID: number,
    invIDsCandidates: number[],
    forward: boolean,
    requestFrom: string
  ) {
    this.invID = invID;
    this.invIDsCandidates = invIDsCandidates;
    this.forward = forward;
    this.requestFrom = requestFrom;
  }
}
