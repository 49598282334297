











































































import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import StandardInput from "@/components/design-system/inputs/StandardInput.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { onDemandSyncService } from "@/services/on-demand-sync.service";
import {
  OnDemandSyncModalI,
  SelectedFieldSyncI
} from "@/models/document-entry/on-demand-sync";
import { MODAL_TYPE } from "@/shared/constants/on-demand-sync";

@Component({
  components: {
    "secondary-button": SecondaryButton,
    "simple-confirmation-modal": SimpleConfirmationModal,
    "standard-input": StandardInput
  }
})
export default class OnDemandSyncModal extends Vue {
  @Prop() modalType!: string;
  @Prop() isDisplayed!: boolean;
  @Prop() syncButtonHandler!: Function;
  @Prop() modalHandler!: Function;

  // Handle modal behaviours
  private isFetching = false;
  private emptyApiData = true;
  private firstLoad = true;
  private searchInputQuery = "";
  private searchInputChange(value) {
    this.searchQuery = value;
  }

  private apiData: any = [];
  private selectedItem: any = "";

  poNumModal: OnDemandSyncModalI = {
    title: "Sync a Purchase Order from Workday",
    inputLabel: "PO Number",
    firstLoadMessage: "Use the PO Number field to synchronize a PO number.",
    emptyDataMessage: "There are no results for this PO number.",
    table: {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "rowSelector"
        },
        {
          text: "PO Number",
          align: "start",
          sortable: false,
          value: "purchaseOrderNumber"
        },
        {
          text: "Company",
          value: "company",
          sortable: false,
          align: "start"
        },
        {
          text: "Supplier",
          value: "supplier",
          sortable: false,
          align: "start"
        },
        {
          text: "PO Date",
          value: "purchaseOrderDate",
          sortable: false,
          align: "start"
        },
        {
          text: "Currency",
          value: "currency",
          sortable: false,
          align: "start"
        },
        {
          text: "Status",
          value: "documentStatus",
          sortable: false,
          align: "start"
        },
        {
          text: "Sync Date",
          value: "syncDate",
          sortable: false,
          align: "start"
        }
      ],
      items: []
    }
  };

  supplierModal: OnDemandSyncModalI = {
    title: "Sync a Supplier from Workday",
    inputLabel: "Supplier ID",
    firstLoadMessage: "Use the Supplier field to synchronize a supplier.",
    emptyDataMessage: "There are no results for this supplier.",
    table: {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "rowSelector"
        },
        {
          text: "Supplier",
          align: "start",
          sortable: false,
          value: "supplierName"
        },
        {
          text: "Supplier ID",
          value: "supplierID",
          sortable: false,
          align: "start"
        },
        {
          text: "Remit To",
          value: "remitTo",
          sortable: false,
          align: "start"
        },
        {
          text: "Currency",
          value: "currency",
          sortable: false,
          align: "start"
        },
        {
          text: "Sync Date",
          value: "syncDate",
          sortable: false,
          align: "start"
        }
      ],
      items: []
    }
  };
  supplierContract: OnDemandSyncModalI = {
    title: "Sync a Supplier Contract from Workday",
    inputLabel: "Supplier Contract ID",
    firstLoadMessage:
      "Use the Supplier Contract ID field to synchronize a supplier contract.",
    emptyDataMessage: "There are no results for this supplier contract.",
    table: {
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "rowSelector"
        },
        {
          text: "Contract Number",
          align: "start",
          sortable: false,
          value: "contractNumber"
        },
        {
          text: "Company",
          value: "company",
          sortable: false,
          align: "start"
        },
        {
          text: "Supplier",
          value: "supplier",
          sortable: false,
          align: "start"
        },
        {
          text: "Start Date",
          value: "startDate",
          sortable: false,
          align: "start"
        },
        {
          text: "End Date",
          value: "endDate",
          sortable: false,
          align: "start"
        },
        {
          text: "Currency",
          value: "currency",
          sortable: false,
          align: "start"
        },
        {
          text: "Sync Date",
          value: "syncDate",
          sortable: false,
          align: "start"
        }
      ],
      items: []
    }
  };

  private getInitialModalConfig = (
    modalType: string
  ): OnDemandSyncModalI | null => {
    switch (modalType) {
      case MODAL_TYPE.PO_NUM:
        return this.poNumModal;
      case MODAL_TYPE.SUPPLIER:
        return this.supplierModal;
      case MODAL_TYPE.SUPPLIER_CONTRACT:
        return this.supplierContract;
      default:
        return null;
    }
  };

  // Setup modal content when first loaded
  private modalConfig: OnDemandSyncModalI | null = this.getInitialModalConfig(
    this.modalType
  );

  // Fetch data to populate modal body
  private async fetchTableData() {
    this.firstLoad = false;
    this.isFetching = true;
    if (this.modalType && this.modalConfig) {
      this.apiData = await onDemandSyncService.getOnDemandSyncData(
        this.modalType,
        this.searchQuery
      );
      // Select first response item as default
      this.selectedItem = this.apiData[0];
      this.modalConfig.table.items = this.apiData;
      this.emptyApiData = this.apiData.length === 0;
    }
    this.isFetching = false;
  }

  private formatData = (
    modalType: string,
    itemData: any
  ): SelectedFieldSyncI | null => {
    switch (modalType) {
      case MODAL_TYPE.PO_NUM:
        return {
          id: itemData.purchaseOrderNumber,
          text: itemData.purchaseOrderNumber
        };
      case MODAL_TYPE.SUPPLIER:
        return { id: itemData.supplierID, text: itemData.supplierName };
      case MODAL_TYPE.SUPPLIER_CONTRACT:
        return {
          id: itemData.contractNumber,
          text: itemData.contractNumber
        };
      default:
        return null;
    }
  };

  // Notify to parent component the selected value by the user
  private emitSelectedValue() {
    this.$emit("applySync", {
      selected: this.formatData(this.modalType, this.selectedItem),
      options: this.apiData
    });
  }

  //COMPUTED PROPERTIES
  private get searchQuery() {
    return this.searchInputQuery;
  }

  private set searchQuery(value: string) {
    this.searchInputQuery = value;
  }

  @Watch("modalType")
  onContentChange() {
    this.apiData = [];
    this.firstLoad = true;
  }
}
