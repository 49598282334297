














































































import { Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserRolesMixin from "@/mixins/UserRoles.vue";
import SecondaryButton from "@/components/design-system/buttons/SecondaryButton.vue";
import CommentCard from "@/components/forms/document-entry/comment-panel/CommentCard.vue";
import AddCommentCardForm from "@/components/forms/document-entry/comment-panel/AddCommentCardForm.vue";
import SimpleConfirmationModal from "@/components/design-system/modals/SimpleConfirmationModal.vue";
import DocumentCommentsStore from "@/store/document-comments.store";
import { documentCommentsService } from "@/services/document-comments.service";
import { RavenDeleteCommentFromInvoiceRequest } from "@/models/external-services/raven-api-delete-comment-request";
import { comment } from "@/models/document-entry/comment";
import options from "@/shared/constants/toast-options";

@Component({
  components: {
    "add-comment-card-form": AddCommentCardForm,
    "secondary-button": SecondaryButton,
    "comment-card": CommentCard,
    "simple-confirmation-modal": SimpleConfirmationModal
  }
})
export default class CommentsCardsPanelModal extends UserRolesMixin {
  // reactive class properties

  private isAddCommentFormShown = false;
  private isFetching = false;
  private skeletonCards: number[] = [0, 1, 2, 3, 5, 6, 7, 8, 9];

  // readonly store modules

  private readonly documentCommentsStore: DocumentCommentsStore = getModule(
    DocumentCommentsStore,
    this.$store
  );

  // computed properties

  private shouldShowDocumentComments: boolean = this.$launchDarkly.variation(
    "show-document-comments",
    false
  );
  private get isDocumentCommentsPanelDisplayed(): boolean {
    return this.documentCommentsStore.getIsDocumentCommentsPanelDisplayed;
  }
  private set isDocumentCommentsPanelDisplayed(_value: boolean) {
    this.documentCommentsStore.changeStateOfDocumentCommentsPanel();
  }
  private get isDeleteCommentConfirmationModalDisplayed(): boolean {
    return this.documentCommentsStore
      .getIsDeleteCommentConfirmationModalDisplayed;
  }
  private get documentCommentSelectedForDelete(): comment | null {
    return this.documentCommentsStore.getDocumentCommentSelectedForDelete;
  }
  private get documentComments(): comment[] {
    return this.documentCommentsStore.getDocumentComments;
  }

  // lifecycle hooks
  async created() {
    if (this.shouldShowDocumentComments) {
      await this.getDocumentComments();
    }
  }

  // methods

  changeStateOfNewCommentForm() {
    this.isAddCommentFormShown = !this.isAddCommentFormShown;
  }

  async getDocumentComments() {
    this.isFetching = true;
    const documentComments: comment[] = await documentCommentsService.loadCommentsOnInvoiceAsync(
      parseInt(this.$route.params.id)
    );
    this.documentCommentsStore.setDocumentComments(documentComments);
    this.isFetching = false;
  }

  async deleteDocumentComment() {
    // delete from database
    const request = new RavenDeleteCommentFromInvoiceRequest(
      parseInt(this.$route.params.id),
      (this.documentCommentSelectedForDelete as comment).commentID
    );
    const deleteResponse = await documentCommentsService.deleteDocumentCommentAsync(
      request
    );

    // then remove from displayed list
    if (deleteResponse.data?.statusCode === 200) {
      this.documentCommentsStore.deleteDocumentComment(
        this.documentCommentSelectedForDelete as comment
      );
      this.documentCommentsStore.changeStateOfDeleteConfirmationModal();
      this.$toasted.show("<p>Invoice comment successfully deleted</p>", {
        ...options.SUCCESS_OPTIONS
      });
    } else {
      this.$toasted.show(
        "<p>Error occurred while deleting invoice comment</p>",
        { ...options.ERROR_OPTIONS }
      );
    }
  }

  scrollToFirstCommentCard() {
    setTimeout(() => {
      const firstComment = document.getElementById("comment-0");
      if (firstComment) {
        firstComment.scrollIntoView({ behavior: "smooth" });
      }
    }, 300);
  }

  // watchers

  @Watch("isDocumentCommentsPanelDisplayed")
  async onPanelShowing() {
    if (this.isDocumentCommentsPanelDisplayed) {
      await this.getDocumentComments();
    }
  }

  @Watch("documentComments", { deep: true })
  onCommentsUpdate() {
    this.scrollToFirstCommentCard();
  }
}
