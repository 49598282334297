import { app } from "@/main";
import { DocumentRuleRequest } from "@/models/document-entry/document-rules";
import { UpdateSupplierAutomationTypeRequest } from "@/models/external-services/raven-api-update-supplier-automation-request";
import { AxiosResponse } from "axios";
import { RavenApiDocumentRuleTypeResponse } from "@/models/external-services/raven-api-document-rule-type-response";

class DocumentRulesService {
  public async createDocumentRuleAsync(
    documentRuleRequest: DocumentRuleRequest
  ) {
    const response = await app.$ravenapi.post(
      `/api/documentrule/create`,
      documentRuleRequest
    );
    return response.data;
  }

  public async getDocumentRule(invoiceId: number | string) {
    return await app.$ravenapi.get(`api/documentrule/get/${invoiceId}`);
  }

  public async getDocumentRuleLines(invoiceId: number | string) {
    const apiResponse = await app.$ravenapi.get(
      `api/documentrule/get/lines/${invoiceId}`
    );
    return apiResponse.data;
  }

  public async saveDocumentRule(invoiceId: number | string) {
    return await app.$ravenapi.get(`api/documentrule/confirm/${invoiceId}`);
  }

  public async deleteDocumentRule(invoiceId: number | string) {
    return await app.$ravenapi.delete(`api/documentrule/delete/${invoiceId}`);
  }

  public async getCustomRulesConfiguredForSupplier(
    supplierID: string
  ): Promise<RavenApiDocumentRuleTypeResponse> {
    return await app.$ravenapi.get(
      `api/documentrule/getall/${encodeURIComponent(supplierID)}`
    );
  }

  public async updateSupplierAutomationType(
    request: UpdateSupplierAutomationTypeRequest
  ) {
    return await app.$ravenapi.put(`api/documentrule/automationtype`, request);
  }
}

export const documentRulesService = new DocumentRulesService(); // singleton
